import React, { useState } from 'react';
import { Segment, Button } from 'semantic-ui-react';

interface ExpandableSegmentProps {
  title: string;
  content: React.ReactNode;
  initialExpand?: boolean;
}

const ExpandableSegment = ({ title, content,initialExpand }: ExpandableSegmentProps) => {
  const [isExpanded, setIsExpanded] = useState(initialExpand);

  const handleToggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Segment  style={{cursor:"pointer"}}>
      <h5 onClick={handleToggleExpand}>{title}</h5>

      <Button
        icon={isExpanded ? 'chevron up' : 'chevron down'}
        floated='right'
        onClick={handleToggleExpand}
        color={isExpanded ? 'blue' : 'grey'}
        compact
        style={{marginTop:"-35px"}}
      />
      {isExpanded && <div onClick={handleToggleExpand}>{content}</div>}
    </Segment>
  );
};

export default ExpandableSegment;
