import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Icon, Input, Select, Table } from 'semantic-ui-react';
import { DocumentTitle } from '../../app/components/document_title';
import GridFooter from '../../app/components/grid_footer';
import { useDebounce } from '../../app/components/use_debounce';
import { useAuth } from '../../app/context/auth_provider';
import { PageParams, PagedResult } from '../../models/common/paged_result';
import { UserListOutDTO, UserRole } from '../../models/user/user';
import { api } from '../../shared/axios_wrapper';
import { addDays, formatDate, getEnumText, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';
import AccountLocationSelectionPanel from '../common/account_location_sel';
import DownloadCSV from '../../app/components/download_report';
import ConfirmButton from '../../app/components/confirm_button';
import DatePicker from "react-datepicker";

function UserListPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<UserListOutDTO[]>([] as UserListOutDTO[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const accountId = user?.accountId ?? 0;
  const [locationId, setLocationId] = useState<number>(user?.locationId ?? 0);
  const [userName, setUserName] = useState<string>('');
  const debounceUserName = useDebounce(userName, 500);
  const [expDate, setExpDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  enum UserActiveOptions {
    All = -1,
    Active = 0,
    Inactive = 1
  }
  const [active, setActive] = useState(UserActiveOptions.Active);

  enum UserExpireOptions {
    All = -1,
    NotExpired = 0,
    ExpiringSoon = 1,
    Expired = 2,
  }
  const [expire, setExpire] = useState(UserExpireOptions.NotExpired);
  const columnCount = 11;

  const handleLocationSelect = useCallback((locationId: number) => {
    setLocationId(locationId);
  }, []);



  interface UserListDto extends PageParams {
    accountId: number,
    locationId?: number;
    userName?: string;
    active?: UserActiveOptions;
    expired?: UserExpireOptions;
    download?: boolean;
  }

  interface UserExpireDto extends UserListDto {
    toBeExpiredDate: Date;
  }

  const initialState: UserListDto = {
    accountId: 0,
    sortIndex: 'locationName',
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: parseInt(process.env.REACT_APP_API_PAGE_SIZE ?? "25"),
    active: UserActiveOptions.Active,
    expired: UserExpireOptions.NotExpired,
    download: false
  };

  const [params, setParams] = useState(initialState);

  const downloadParams = {
    accountId: user?.accountId,
    locationId: params.locationId,
    sortIndex: initialState.sortIndex,
    sortOrder: initialState.sortOrder,
    expired: expire,
    active: active,
    userName: debounceUserName,
    pageIndex: 0,
    pageSize: 10000,
    download: true
  };

  useEffect(() => {
    if ((user?.accountId ?? 0) > 0) {
      params.accountId = accountId;
      params.locationId = locationId;
      params.userName = debounceUserName;
      params.active = active;
      params.expired = expire;
      params.download = false;

      setParams(params);
      setLoading(true);

      //console.log(params);
      const url = `user/user-list`;
      api.getWithInput<PagedResult<UserListOutDTO[]>>(url, params)
        .then((r) => {
          if (r?.data?.result) {
            setData(r.data.result);
            setTotalRecords(r.data.total);
          }
          //console.log(data);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [params, accountId, locationId, debounceUserName, active, expire, user?.accountId]);

  const handleSort = (clickedColumn: string) => {
    if (data.length === 0) return;
    setParams(handleGridSort(params, clickedColumn));
  }

  const gridChange = (updatedParams: PageParams) => {
    setParams(handleGridNavigation(params, updatedParams));
  }

  const isSorted = (params: PageParams, columnName: string, data: any) => {
    return params.sortIndex === columnName && data.length > 0 ? params.sortOrder : undefined
  }

  const handleSubmit = () => { handleSort("accountName"); };

  const showOrHide = (item: UserListOutDTO) => {
    if (user?.accountId === 7 && user?.role === UserRole.LocationAdmin && user.locationId !== item.locationId) {
      return false;
    }
    return true;
  }

  const getUserActiveButtonText = () => {
    if (active === UserActiveOptions.Active) {
      return "Inactivate Selected Users (" + data.length + ")";
    }
    else if (active === UserActiveOptions.Inactive) {
      return "Activate Selected Users (" + data.length + ")";
    }
  }

  const getUserActiveConfirmationText = () => {
    return `Do you want to ` + getUserActiveButtonText() + '?';
  }

  const getUserExpButtonText = () => {
    if (expire === UserExpireOptions.Expired) {
      return "Unexpire Selected Users (" + data.length + ")";
    }
    else if (expire === UserExpireOptions.NotExpired) {
      return "Expire Selected Users (" + data.length + ")";
    }
  }

  const getUserExpConfirmationText = () => {
    return `Do you want to ` + getUserExpButtonText() + '?';
  }

  const handleDateChange = (e: any) => {
    setIsOpen(!isOpen);
    setExpDate(e);
  };

  const saveUserBulkStatusUpdate = async () => {

    if ((user?.role ?? 1) < UserRole.LocationAdmin) {
      toast.error("You are not allowed to this action.", { theme: "colored" });
      return;
    }

    //console.log(params);

    setSubmitting(true);
    const url = 'user/user-filter-bulk-status-update'
    api.post(url, params ?? {})
      .then((r) => {
        toast.success("Selected Users Status Updated successfully.", { theme: "colored" });
        handleSubmit();
      })
      .catch((e) => {
        console.log(e);
        //toast.error(e.message, { theme: "colored" })
      })
      .finally(() => setSubmitting(false));
  }

  const saveUserBulkExpUpdate = async () => {

    if ((user?.role ?? 1) < UserRole.LocationAdmin) {
      toast.error("You are not allowed to this action.", { theme: "colored" });
      return;
    }

    if (expDate == null) {
      return;
    }

    const expParams: UserExpireDto = {
      accountId: user?.accountId || 0,
      locationId: params.locationId,
      expired: expire,
      active: active,
      userName: debounceUserName,
      toBeExpiredDate: expDate || new Date(),
      sortIndex: 'locationName',
      sortOrder: 'ascending',
      pageIndex: 0,
      pageSize: 10000,
      download: false
    }

    //console.log(params);

    setSubmitting(true);
    const url = 'user/user-filter-bulk-exp-update'
    api.post(url, expParams ?? {})
      .then((r) => {
        toast.success("Selected Users Expiry Date updated successfully.", { theme: "colored" });
        handleSubmit();
      })
      .catch((e) => {
        console.log(e);
        //toast.error(e.message, { theme: "colored" })
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <>
      <DocumentTitle title="Users" />
      <h2>Users
        <Button floated='right' icon="add" content="Add User" onClick={() => navigate("/location/users/edit/0")} />
        {user?.role === UserRole.SuperAdmin &&
          <Button floated='right' icon="users" content="Bulk User Action"
          onClick={() => navigate("/admin/account/user-bulk-action")} />
        }
      </h2>
      <br /><br /><br />
      <Form className="ui form" onSubmit={handleSubmit}>
        <Grid stackable container>
          <Grid.Row>
            <Grid.Column width={6} verticalAlign='middle'>
              <AccountLocationSelectionPanel locationId={locationId} onLocationSelect={handleLocationSelect} showAll={true} />
            </Grid.Column>
            <Grid.Column width={6}>
              <label className="txt-content" style={{ lineHeight: "2" }}>User Name or Login:</label> <br />
              <Input name="userName"
                placeholder='User First or Last Name or Login'
                fluid
                maxLength={50}
                size='huge'
                onChange={(e, data) => { setParams({ ...params, pageIndex: 0 }); setUserName(data.value); }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6} verticalAlign='middle'>
              {(user?.accountId !== 7 || (user?.accountId === 7 && user?.role !== UserRole.LocationAdmin)) &&
                <>
                  <label className="txt-content" style={{ lineHeight: "2" }}>Active?:</label> <br />
                  <Select name="active"
                    size="large"
                    className="txt-content"
                    defaultValue={0}
                    options={[
                      { key: 'yes', text: 'Active', value: 0, selected: true },
                      { key: 'no', text: 'Inactive', value: 1 },
                      { key: '', text: 'Active and Inactive', value: -1 }
                    ]}
                    placeholder="Active?"
                    onChange={(e, data) => { setParams({ ...params, pageIndex: 0 }); setActive(data?.value as number); }} />
                </>
              }
            </Grid.Column>
            <Grid.Column width={5} verticalAlign='middle'>
              {(user?.accountId !== 7 || (user?.accountId === 7 && user?.role !== UserRole.LocationAdmin)) &&
                <>
                  <label className="txt-content" style={{ lineHeight: "2" }}>Expired?:</label> <br />
                  <Select name="expired" size="large"
                    className="txt-content"
                    defaultValue={0}
                    options={[
                      { key: '0', text: 'Not Expired', value: 0, selected: true },
                      { key: '1', text: 'Expiring Soon', value: 1 },
                      { key: '2', text: 'Expired', value: 2, },
                      { key: '-1', text: 'Expired and Not Expired', value: -1 }
                    ]}
                    onChange={(e, data) => { setParams({ ...params, pageIndex: 0 }); setExpire(data?.value as number); }} />
                </>
              }
            </Grid.Column>
            <Grid.Column textAlign='center' width={3} verticalAlign='middle'>
              <br />
              <Button type="submit" primary
                disabled={loading || submitting}
                size='large'>
                Search
                {loading && <label> <Icon name='spinner' /></label>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <div style={{ textAlign: "right" }}>
        <DownloadCSV
          url='user/user-list'
          fileName='user_list.csv'
          params={downloadParams}
        />
      </div>
      <Table stackable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell style={{ width: "50px" }}></Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => handleSort("locationName")} sorted={isSorted(params, "locationName", data)}>Location</Table.HeaderCell>
            <Table.HeaderCell>Zip</Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => handleSort("userName")} sorted={isSorted(params, "userName", data)}>User</Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: "pointer" }} onClick={() => handleSort("login")} sorted={isSorted(params, "login", data)}>Login</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: "pointer" }} onClick={() => handleSort("active")} sorted={isSorted(params, "active", data)}>Active</Table.HeaderCell>
            <Table.HeaderCell>Affiliation</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: "pointer" }} onClick={() => handleSort("userRole")} sorted={isSorted(params, "userRole", data)}>Role</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: "pointer" }} onClick={() => handleSort("lastLoginDate")} sorted={isSorted(params, "lastLoginDate", data)}>Logged On</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: "pointer" }} onClick={() => handleSort("expDate")} sorted={isSorted(params, "expDate", data)}>Exp. Date</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data && data.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell className='row_num'>{(params.pageIndex * params.pageSize) + index + 1}</Table.Cell>
              <Table.Cell>{item.locationName}</Table.Cell>
              <Table.Cell textAlign='center'>{item.postalCode}</Table.Cell>
              <Table.Cell><a href={'/location/users/' + item.userId}>{item.userName}</a></Table.Cell>
              <Table.Cell>{item.login}</Table.Cell>
              <Table.Cell textAlign='center'>
                {showOrHide(item) === false && ""}
                {showOrHide(item) === true && item.active === true && "YES"}
                {showOrHide(item) === true && item.active === false && "NO"}
              </Table.Cell>
              <Table.Cell>{showOrHide(item) && item.affiliation}</Table.Cell>
              <Table.Cell textAlign='center'>{showOrHide(item) && getEnumText(UserRole, item.userRole)}</Table.Cell>
              <Table.Cell textAlign='center'>{showOrHide(item) && formatDate(item.lastLoginDate, true)}</Table.Cell>
              <Table.Cell textAlign='center'>{showOrHide(item) && formatDate(item.expDate)}</Table.Cell>
              <Table.Cell textAlign='center'>
                {showOrHide(item) && ((user?.role ?? 1) >= item.userRole) &&
                  <a href={'/location/users/edit/' + item.userId}><Icon name="edit" /></a>}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {!loading && data.length > 0 &&
          <GridFooter colSpan={showFullSize ? columnCount : 1}
            params={params}
            totalRecords={totalRecords}
            onChange={gridChange}
            downloadApiUrl='user/user-list'
            downloadFileName='user_list.csv'
            downloadParams={downloadParams}
          />
        }
      </Table>

      {!loading && (params?.locationId || 0) > 0 &&
        data && data.length > 0 &&
        expire !== UserExpireOptions.All &&
        <>
           Exp. Date: &nbsp;<DatePicker selected={expDate}
            minDate={addDays(new Date(), 0)}
            maxDate={addDays(new Date(), 365)}
            todayButton="today"
            disabledKeyboardNavigation
            showMonthYearDropdown
            showWeekNumbers
            onChange={handleDateChange}
            className='date-picker'/>
            <br/>
        <ConfirmButton id={0}
          disabled={(submitting || expDate === null || expDate < new Date())}
          value={getUserExpButtonText() || ''}
          confirmButtonText={getUserExpButtonText()}
          color={"grey"}
          content={getUserExpConfirmationText() || ''}
          iconName="calendar alternate outline" floated="left"
          handleConfirm={saveUserBulkExpUpdate} />

        </>
      }

      {!loading && (params?.locationId || 0) > 0 &&
        data && data.length > 0 &&
        active !== UserActiveOptions.All &&
          <ConfirmButton id={0}
            disabled={submitting}
            value={getUserActiveButtonText() || ''}
            confirmButtonText={getUserActiveButtonText()}
            color={"grey"}
            content={getUserActiveConfirmationText() || ''}
            iconName="edit outline" floated="right"
            handleConfirm={saveUserBulkStatusUpdate} />
      }

    </>
  );

}
export default UserListPage;
