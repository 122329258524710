import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Icon, Image, Step, List, Divider, Accordion, Label } from "semantic-ui-react";
import { Result } from "../../models/common/paged_result";
import { CourseType, UserCourseTimelineOutputDto, UserModuleStatus } from "../../models/course/course";
import { LearningModule } from "../../models/course/learning_module";
import { api } from "../../shared/axios_wrapper";
import { getDistinctByProperty } from "../../shared/utils";
import { promo_list } from "../common/promo_items";
import { DocumentTitle } from "../../app/components/document_title";
import CertificateModal from "./components/certificate_modal";
import React from "react";
import { CertificateData } from "../../models/report/report";
import { useAuth } from "../../app/context/auth_provider";
import { UserRole } from "../../models/user/user";
import ExpandableSegment from "../../app/components/expandable_segment";
import { useMediaQuery } from "react-responsive";

interface ModuleDto {
  module: LearningModule;
  lastAccessedDate: Date;
  status: UserModuleStatus;
}

function LessonCourseTimelineView() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState<UserCourseTimelineOutputDto>();
  const [modules, setModules] = useState<ModuleDto[]>();
  const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateData>();
  const [showCert, setShowCert] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | string | null>(null);
  const [allExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/course/${courseId}/timeline`;
    api.get<Result<UserCourseTimelineOutputDto>>(url)
      .then((r) => {
        if (r.data?.result) {
          setCourse(r.data.result);
          setModules(r.data.result?.modules);

          if (user?.role === UserRole.Student){
            if (r.data.result?.courseCompletedDate){
              setSelectedCertificateData({
                accountName: user?.accountName ?? "",
                locationName: user?.locationName ?? "",
                courseName: r.data.result?.courseName ?? "",
                courseDescription: r.data.result?.shortDescription ?? "",
                courseIcon:  r.data.result?.courseIcon ?? "",
                studentName: user?.displayName ?? "",
                certifiedOn:  r.data.result?.courseCompletedDate
              });
              setShowCert(true);
            }
          }
        }
      })
      .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [courseId, user]);

  interface moduleProps {
    modules: ModuleDto[] | undefined;
  }

  const handleAccordionClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
};

const handleToggleAll = () => {
  setAllExpanded((prevAllExpanded) => !prevAllExpanded);
};

/*
  const ModuleTimeTableAsCards = ({ modules }: moduleProps) => {
    return (
        <List>
        {getDistinctByProperty(course?.assessmentTimeline ?? [], "timelineGroup")?.map((timeline, index) => (
          <List.Item key={`timeline_${index}`}>
              <List.Content>
                <List.Header as="h2" className="txt-primary">
                <List.Icon name='calendar alternate' size="huge" />
                Timeline: {timeline.timelineGroup}</List.Header>
                <List.Description></List.Description>
                <center>
        <br />
        <Card.Group size="large" centered stackable>
          {course?.assessmentTimeline.filter(x => x.timelineGroup === timeline.timelineGroup)?.map((t, index) => (

            <Card key={`timeline_task_${index}`} raised>
              {t.latestAns === true && <Label color='green' corner='right' icon='check' />}
              <Card.Content>
                <Card.Header><a href={"/lessons/courses/" + t.moduleCode}>{t.taskName}</a></Card.Header>
                <Card.Meta style={{textAlign:'left', paddingTop: "6px", paddingBottom: "6px"}}>
                Timeline: {t.timelineGroup} | Module: {t.moduleCode} | Difficulty: <u>{t.difficultyLevel}</u>
                </Card.Meta>
                <Card.Description>
                  <span className="txt-primary txt-large">{t.question}</span>
                </Card.Description>
              </Card.Content>
              <Card.Content extra textAlign="center">
                <Grid columns={1}>
                  <Grid.Column>
                    <Button primary icon='list' content=' View Task'
                      onClick={(e, data) => navigate(`/lessons/courses/${courseId}/modules/tasks/${t.moduleCode}`)} />
                    <br/> <br/>
                      <span style={{paddingTop:"6px"}}>Last Accessed Date: </span>
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </center>
      <br/>
      <Divider clearing />
              </List.Content>
          </List.Item>))}
        </List>
      );
  }
  */
  const ModuleTimeTableAsAccordion = ({ modules }: moduleProps) => {
    return (
      <Accordion fluid styled>
        {getDistinctByProperty(course?.assessmentTimeline ?? [], "timelineGroup")?.map((timeline, index) => (
          <React.Fragment key={`timeline_${index}`}>
            <Divider clearing />
            <Accordion.Title
              as="h3"
              index={index}
              active={allExpanded || activeIndex === index}
              onClick={() => handleAccordionClick(index)}
              style={{ marginBottom: 10 }}
            >
              <Icon name="dropdown" />
              <Icon name='calendar alternate' size="large" />
              <b className="txt-primary" style={{fontSize:"18px", paddingBottom: "10px"}}>Timeline: {timeline.timelineGroup}</b>
            </Accordion.Title>
            <Accordion.Content active={allExpanded || activeIndex === index}>
              <List divided relaxed selection>
                {course?.assessmentTimeline.filter(x => x.timelineGroup === timeline.timelineGroup)?.map((t, index) => (
                  <List.Item key={`timeline_task_${index}`} style={{padding:"10px"}}>
                     <Label color='grey' ribbon>
                      {t.difficultyLevel} Task
                    </Label>
                    {t.latestAns === true && <Icon color='green'  name='check'  size='big'/>}
                    {t.latestAns !== true && <Icon color='red'  name='clock'  size='big'/>}
                    <List.Content>
                      <List.Header as="a" href={`/lessons/courses/${courseId}/modules/tasks/${t.moduleCode}`}
                      style={{fontSize:"16px", paddingBottom: "6px"}}>
                      <Icon name='tasks'/> &nbsp;&nbsp; {t.taskName} - {t.question}
                      </List.Header>
                      <List.Description>
                        <span style={{ fontSize: 'small', color: 'grey' }}>
                          Timeline: {t.timelineGroup} | Module: {t.moduleName}
                        </span>
                       </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Accordion.Content>
          </React.Fragment>
        ))}
      </Accordion>
    );
  }


  interface promoAdProps {
    promoJson?: string;
  }
  const PromoAd = ({ promoJson }: promoAdProps) => {
    if (!promoJson || promoJson.trim() === '') return (<></>);
    const promoItems = promo_list.filter(x => x ? x?.title === promoJson : []);
    if (!promoItems || promoItems.length === 0) return (<></>);
    const promoItem = promoItems[0];
    return (
      <Step.Group fluid>
        <Step>
          <Image src={'/assets/promo/' + promoItem.img} wrapped ui={false} />
          <Step.Content style={{ paddingLeft: "10px" }}>
            <span className="primary" style={{padding:"5px"}}>Helpful Resource</span>
            <br/><br/>
            <Step.Title>{promoItem.title}</Step.Title>
            <Step.Description className="txt-content">
            <br/>
              <div
                dangerouslySetInnerHTML={{
                  __html: promoItem.text.replace('{link}',
                    `the following website.`)
                }} />
                <br/>
              <a target="_promo" href={promoItem.link}>
                <Icon name='globe' />
                {promoItem.link}
              </a>
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  return (
    <>
      <DocumentTitle title={"Lesson Timeline View"} />
      {!loading && <Button basic onClick={(e, data) => { navigate(`/lessons/courses/${courseId}`) }}>
        <Icon name="arrow left" size="big" /> Back to Lesson View</Button>}

      {!loading && <h2>Course Timeline: {course?.courseName}</h2>}


      {!loading && <p className="txt-content">{course?.description}</p>}

      {!loading && course?.courseType !== CourseType.e_Learning &&
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button color='blue' icon='pencil alternate' content='Assessment'
        onClick={(e, data) => { navigate(`/lessons/courses/${courseId?.trim()}/modules/assessment`) }} />
        </>
      }
    {!loading  && course?.courseType !== CourseType.e_Learning &&
        <Button primary
        icon='align justify'
        content='Lesson View' floated="right"
        onClick={(e, data) => { navigate(`/lessons/courses/${courseId}`) }} />}

      {!loading && isMobile && <><br/><br/></>}

      {showCert &&
      <CertificateModal
        certificateData={selectedCertificateData}
        showModal={showCert}
        setShowModal={setShowCert} />}

      <br/>

      {!loading && <ExpandableSegment initialExpand={true} title="Usage Instructions" content={
         <>
          <ul style={{fontSize: "20px", lineHeight:"1.2"}}>
            <li>Click on each timeline section to expand or collapse</li>
            <li>Click on any task name to go work on it</li>
            <li>Click 'Expand All' to print (if needed)</li>
            <li>The clock icons below will turn to green check marks when tasks are complete.</li>
          </ul>
         </>
        } />
      }
        <br/>
        {!loading && (
                <Button basic onClick={handleToggleAll} floated="right">
                    {allExpanded ? "Collapse All" : "Expand All"}
                </Button>
            )}

            <br /><br/>
      {!loading && <ModuleTimeTableAsAccordion modules={modules} />}

      {!loading && <PromoAd promoJson={course?.promoJson} />}

      {!loading && <Button basic onClick={(e, data) => { navigate(`/lessons/courses/${courseId}`) }}>
        <Icon name="arrow left" size="big" /> Back to Lesson View</Button>}
    </>
  );
}
export default LessonCourseTimelineView;