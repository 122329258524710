import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Icon, Input, Select, Table } from 'semantic-ui-react';
import { DocumentTitle } from '../../app/components/document_title';
import GridFooter from '../../app/components/grid_footer';
import { useDebounce } from '../../app/components/use_debounce';
import { PageParams, PagedResult } from '../../models/common/paged_result';
import { CourseListInputDto, CourseListOutputDto, CourseType } from '../../models/course/course';
import { api } from '../../shared/axios_wrapper';
import { enumToKeyValueList, getEnumText, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';

function CourseListPage() {
  const navigate = useNavigate();
  const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CourseListOutputDto[]>([] as CourseListOutputDto[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [courseType, setCourseType] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const debounceName = useDebounce(name, 500);
  const columnCount = 6;

  const initialState: CourseListInputDto = {
    sortIndex: 'name',
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: parseInt(process.env.REACT_APP_API_PAGE_SIZE ?? "10")
  };

  const [params, setParams] = useState(initialState);

  useEffect(() => {
    params.name = debounceName;
    params.courseType = courseType;

    setParams(params);
    setLoading(true);
    const url = `course/list`;
    api.getWithInput<PagedResult<CourseListOutputDto[]>>(url, params)
      .then((r) => {
        setData(r.data.result);
        setTotalRecords(r.data.total);
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));

  }, [params, courseType, debounceName]);

  const handleSort = (clickedColumn: string) => {
    if (data.length === 0) return;
    setParams(handleGridSort(params, clickedColumn));
  }

  const gridChange = (updatedParams: PageParams) => {
    setParams(handleGridNavigation(params, updatedParams));
  }

  const isSorted = (params: PageParams, columnName: string, data: any) => {
    return params.sortIndex === columnName && data.length > 0 ? params.sortOrder : undefined
  }

  const handleSubmit = () => { handleSort("name"); };

  return (
    <>
      <DocumentTitle title="All Courses"/>
      <h2>All Courses
      <Button floated='right' icon="add" content="Add Course" onClick={() => navigate("/admin/courses/edit/0")}/>
      </h2>
      <Form onSubmit={handleSubmit}>
        <Grid stackable columns={3}>
          <Grid.Row>
            <Grid.Column>
              <label style={{lineHeight:"2"}}>Course Type: </label> <br/>
              <Select fluid
                defaultValue="All"
                options={enumToKeyValueList(CourseType)}
                size="huge"
                style={{fontSize: "18px"}}
                closeOnChange
                onChange={(e, data) => {  setParams({ ...params, pageIndex: 0 }); setCourseType(data?.value as number) }} />
            </Grid.Column>
            <Grid.Column>
            <label style={{lineHeight:"2"}}>Course Name: </label> <br/>
              <Input name="name"
                placeholder='Name '
                fluid
                maxLength={50}
                size='huge'
                onChange={(e, data) => {  setParams({ ...params, pageIndex: 0 }); setName(data.value); }} />
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
              <br/>
              <Button type="submit" primary
                disabled={loading}
                size='large'>
                <Icon name='search'/>Search
                {loading && <label> <Icon name='spinner' /></label>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Table stackable selectable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell style={{ width: "50px" }}></Table.HeaderCell>
            <Table.HeaderCell textAlign='left' style={{ cursor: 'pointer' }}
              onClick={() => handleSort("courseName")}
              sorted={isSorted(params, "courseName", data)}>Course Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Active</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Module Count</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell className='row_num'>{(params.pageIndex * params.pageSize) + index + 1}</Table.Cell>
              <Table.Cell><a href={'/admin/courses/'+ item.id}>{item.courseName}</a></Table.Cell>
              <Table.Cell>{getEnumText(CourseType, item.courseType)}</Table.Cell>
              <Table.Cell textAlign='center'>{item.active ? "Yes" : "No"}</Table.Cell>
              <Table.Cell textAlign='center'>{item.moduleCount}</Table.Cell>
              <Table.Cell textAlign='center'><a href={'/admin/courses/edit/' + item.id}><Icon name="edit"/></a></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {!loading && data.length > 0 &&
          <GridFooter colSpan={showFullSize ? columnCount : 1}
            params={params}
            totalRecords={totalRecords}
            onChange={gridChange}
          />
        }
      </Table>
    </>
  );

}
export default CourseListPage;
