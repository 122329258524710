import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIME_OUT_IN_SEC ?? '240') * 1000; // in milliseconds

axios.interceptors.request.use((config: any) => {
  const existingToken = localStorage.getItem('token');
  if (existingToken) {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${existingToken}`,
      'Content-Type': 'application/json'
    };
  }
  return config;
});

axios.interceptors.response.use(async response => { return response; },
  async error => {
    if (error.response) {
      try {
        //const { data, status } = error.response!;
        console.log(error?.response?.data);
        switch (error.response!.status) {
          case 400:
            if (error?.response?.data?.errors) {
              const modalStateErrors = [];
              for (const key in error?.response?.data?.errors) {
                modalStateErrors.push(error?.response?.data?.errors[key]);
              }
              //throw modalStateErrors.flat();
              toast.error('Error: ' + modalStateErrors.flat(), { theme: "colored" });
            }
            else if (error?.response?.data){
              toast.error('Error: ' + error?.response?.data, { theme: "colored" });
            }
            else{
              toast.error('Error', { theme: "colored" });
            }
            break;
          case 401:
            toast.error('Unauthorized', { theme: "colored" });
            window.location.href = `/login?redirect=${window.location.pathname}`;
            break;
          case 404:
            toast.error('Not found', { theme: "colored" });
            break;
          case 500:
            toast.error('Internal Server Error', { theme: "colored" });
            break;
        }
      } catch (err) {
        console.error('Error during async operation:', err);
      }
    }
    return Promise.reject(error);
  })

//const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const responseBody = <T>(response: AxiosResponse<T>) => response;

export const api = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  getWithInput: <T>(url: string, params: any) => axios.get<T>(url, { params: params }).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url:string) => axios.delete<T>(url).then(responseBody),
}