import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { api } from '../../shared/axios_wrapper';

interface DownloadCSVProps {
  url: string;
  params?: Record<string, any>;
  fileName?: string;
}

function DownloadCSV({ url, params, fileName }: DownloadCSVProps) {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);

    try {
      const response = await api.getWithInput<any>(url, params);
      //console.log(response);
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      const csvUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = csvUrl;
      a.download = fileName || 'data.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(csvUrl);
    } catch (error) {
      toast.error("Error downloading CSV", { theme: "colored" });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Button
     primary
     icon='download'
      size="large"
      onClick={handleDownload}
      disabled={downloading}
    />
  );
}

export default DownloadCSV;
