import { Icon, Segment } from 'semantic-ui-react'

function HelpPage() {
  return (
    <div style={{padding:"20px"}}>
    <h3><Icon name='help circle' size='large' /> Acceleron Learning Resources</h3>
    <Segment vertical size='huge'><Icon name='home'/>&nbsp;&nbsp;<a href='/'>Back to Home</a></Segment>
    <Segment vertical size='huge'><Icon name='hand point right' />&nbsp;&nbsp;<a href='/terms-of-use-plain/'>Terms of Use</a></Segment>
    <Segment vertical size='huge'>
      support@acceleronlearning.com
    </Segment>
    <Segment size='huge'>
      Copyright © 2011 - {new Date().getFullYear()} The Acceleron Group, Inc. All rights reserved.
    </Segment>
    </div>
  )
}

export default HelpPage