import { UserRole } from "../user/user";

export enum UserCourseStatus {
    NotStarted = 0,
    InProgress = 1,
    Completed = 2
}

export interface CourseAllStudentProgressReport
{
    courseId: number;
    courseName: string;
    courseShortDescription: string;
    courseIcon: string;
    accountId: number;
    accountName: string;
    locationId: number;
    locationName: string;
    studentId: number;
    studentName: string;
    roleId: UserRole;
    status: UserCourseStatus;
    courseProgress: string;
    avgQuizAttempts: number;
    certifiedOn : Date;
    lastAccessedDate: Date,
    avgQuizMarks: number;
    moduleCount?: number;
    completedModuleCount?: number;
    completedFlag?: boolean;
    avgVideo1ViewTimeInMin: number;
    avgVideo2ViewTimeInMin: number;
    courseDisplayOrder: number;
    moduleDisplayOrder: number;
}

export interface CertificateData
{
    accountName: string;
    locationName: string;
    courseName: string;
    courseIcon: string;
    courseDescription: string;
    studentName: string;
    certifiedOn?: Date;
}

export interface StudentCourseModuleProgressReportInput{
    accountId: number;
    accountName: string;
    locationId: number;
    locationName: string;
    userId: number;
    userFirstName: string;
    userLastName: string;
    courseId: number;
    courseName: string;
}

export interface StudentCourseModuleProgressReport{
    accountId: number;
    accountName: string;
    locationId: number;
    locationName: string;
    userId: number;
    firstName: string;
    lastName: string;
    courseId: number;
    courseName: string;
    moduleId: number;
    moduleName: string;
    moduleCode: string;
    firstModuleAccessedDate: Date;
    lastModuleAccessedDate: Date;
    lastQuizAttemptedDate: Date;
    lastQuizMarks: number;
    lastQuizGrade: number;
    maxQuizMarks: number;
    maxQuizGrade: number;
    totalQuizAttempts: number;
    completedFlag: boolean;
    completedDate: Date;
    video1ViewTimeInMin: number;
    video2ViewTimeInMin: number;
}

export interface CourseAllModuleData
{
    locationId: number;
    locationName: string;
    userId: number;
    userName: string;
    courseId: number;
    courseName: string;
}