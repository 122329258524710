import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button,  Icon, Form} from "semantic-ui-react";
import { api } from "../../shared/axios_wrapper";
import { Result } from "../../models/common/paged_result";
import { toast } from "react-toastify";
import LabelOutput from "../../app/components/label_output";
import { UserInfo, UserRole } from "../../models/user/user";
import { formatDate, getEnumText } from "../../shared/utils";
import { useAuth } from "../../app/context/auth_provider";
import { DocumentTitle } from "../../app/components/document_title";

function UserViewPage() {
    const navigate = useNavigate();
    const {user} = useAuth();
    const { userId } = useParams();
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo>(new UserInfo());
    const userIdInt = parseInt(userId ?? '0') === 0 ? user?.id ?? 0: parseInt(userId ?? '0');

    useEffect(() => {
      if (userIdInt > 0){
        setLoading(true);
        const url = `account/${user?.accountId}/user/get/${userIdInt}`;
        api.get<Result<UserInfo>>(url)
          .then((r) => {
            if (r?.data?.result){
              //console.log(r.data.result);
              setUserInfo(r.data.result);
            }
          })
          .catch((e) => {console.log(e); toast.error("Error", { theme: "colored" }) })
          .finally(() => setLoading(false));
      }
    },[userId, userIdInt, user?.accountId]);

    const isUserNotExpired = () => {
      if (!userInfo || !userInfo.expDate){
        return false;
      }

      if (!userInfo.expDate){
        return true;
      }
      const currentDate = new Date();
      const expDate = new Date(userInfo.expDate);
      return expDate >= currentDate;
    }

    const UserView = () => {
        return(
          <Form id="userForm" className="ui form" size="big">
            <Form.Group widths="equal">
             <LabelOutput placeholder="Created Date" content={formatDate(userInfo?.createdDate, true)} />
             <LabelOutput placeholder="Updated Date" content={formatDate(userInfo?.updatedDate, true)} />
            </Form.Group>
              <Form.Group widths="equal">
              <Form.Checkbox  label="Active?" style={{fontSize:"18px"}} checked={userInfo.active}
             disabled/>
             <Form.Checkbox  label="LockedOut?" style={{fontSize:"18px"}} checked={userInfo.lockedOut}
             disabled/>
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Account Id" content={user?.accountId} />
              <LabelOutput placeholder="Account Name" content={user?.accountName} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Location Id" content={user?.locationId} />
              <LabelOutput placeholder="Location Name" content={user?.locationName} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Id" content={userInfo.id} />
              <LabelOutput placeholder="Name" content={userInfo.firstName + ' ' + userInfo.lastName} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Role" content={getEnumText(UserRole, userInfo.roleId)} />
              <LabelOutput placeholder="Login" content={userInfo.login} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Affiliation" content={userInfo.affiliation} />
              <LabelOutput placeholder="Exp Date" content={userInfo.expDate} />
              <LabelOutput placeholder="Last Login Date" content={formatDate(userInfo.lastLoginDate, true)} />
            </Form.Group>
            {user?.role !== UserRole.Student &&
            <Form.Group widths="equal">
              <LabelOutput placeholder="Pin" content={userInfo.pin} />
            </Form.Group>}
            {user?.role !== UserRole.Student &&
            <Form.Group widths="equal">
              <LabelOutput placeholder="Notes" content={userInfo.notes} />
            </Form.Group>}

            <Button floated="right" type="button" content="Close" size="large"
            onClick={() => {navigate('/location/users')}}/>
            {userInfo?.active && isUserNotExpired() &&
            <Button floated="right" type="button" content="Change Pwd" size="large" secondary
              onClick={() => { navigate(`/user/reset-pwd/${userInfo.login}/${userInfo.lastName}/${userInfo.pin ?? ''}`) }} />
            }
          </Form>
          )}

return(<>
      <DocumentTitle title={"User View"} />
      {user?.role === UserRole.SuperAdmin &&
      <Button basic icon="arrow left" size="big" content="Back to Users" onClick={() => {navigate('/location/users')}}/>
      }
      {!loading && <h2><Icon name='user outline'/> User </h2>}
      {!loading && <UserView/>}

    </>);
}

export default UserViewPage