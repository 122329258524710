import { Link } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

function NotFound() {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='search' />
        Not Found! The page you’re looking for doesn’t exist.
      </Header>
      <Segment.Inline>
        <Button as={Link} to='/' primary>
          Back Home
        </Button>
      </Segment.Inline>
    </Segment>
  )
}

export default NotFound