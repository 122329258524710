import { Form, Button, Header, Icon, Segment, Image, Grid, Input } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../shared/axios_wrapper";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from 'yup';
import { DorpDownData } from "../../models/common/key_value";
import { Location } from "../../models/account/location";
import TextInput from "../../app/components/text_input";
import SelectInput from "../../app/components/selected_input";
import LabelOutput from "../../app/components/label_output";
import { Result } from "../../models/common/paged_result";
import { UserRole } from "../../models/user/user";
import { getEnumText } from "../../shared/utils";

interface RegisterUserDto  {
    accountId?: number;
    locationId?: number;
    login: string;
    firstName: string;
    lastName: string;
    password: string;
    retypePassword: string;
    pin?: string;
    userRole?: UserRole;
    registrationCode?: string;
}

interface AccountAffiliationCode {
    id: number,
    name: string;
    enablePinToResetPwd: boolean;
    userRole: UserRole;
    registrationCode: string;
    locationAdminRegistrationCode: string;
}

function SelfRegisterPage() {
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [regCode, setRegCode] = useState("");
    const [accountId, setAccountId] = useState(0);
    const [accountName, setAccountName] = useState("");
    const [userRole, setUserRole] = useState(UserRole.Student);
    const [pinNeeded, setPinNeeded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [locationJson, setLocationJson] = useState<DorpDownData[]>([]);
    const [welcomeVideoUrl, setWelcomeVideoUrl] = useState("");

    useEffect(() => {
        if (accountId > 0) {
            const locationUrl = `register/account/${accountId}/location/drop-down-list`;
            api.get<Result<Location[]>>(locationUrl)
                .then((r) => {
                    const locJson: DorpDownData[] = r.data.result.map((loc) => {
                        return {
                            key: loc.id.toString(),
                            value: loc.id,
                            text: loc.name,
                        };
                    });
                    setLocationJson(locJson);
                })
                .catch((e) => { toast.error("Error", { theme: "colored" }) })
                .finally(() => setLoading(false));
        }
    }, [accountId]);

    useEffect(() => {
        if (accountId > 0) {
            const welcomeVideoUrl = `register/welcome-video`;
            api.get<string>(welcomeVideoUrl)
                .then((r) => {
                    setWelcomeVideoUrl(r.data);
                })
                .catch((e) => { toast.error("Error", { theme: "colored" }) })
                .finally(() => setLoading(false));
        }
    }, [accountId]);

    const validateStudentRegCode = async () => {
        if (!regCode || regCode.trim() === '') return;
        setSubmitting(true);
        const url = `account/get-by-reg-code/${regCode}`;
        api.post<Result<AccountAffiliationCode>>(url, {}).then((r) => {
            if (r.data?.result){
                setAccountId(r.data.result?.id);
                setAccountName(r.data.result?.name);
                setPinNeeded(r.data.result?.enablePinToResetPwd);
                setUserRole(r.data.result?.userRole);
            }
        })
        .catch((e) => {setShowError(true);})
        .finally(() => setSubmitting(false));
    }

    const saveRegisteredUser = async (input: RegisterUserDto) => {
        if (accountId <= 0 || (input.locationId ?? 0) <= 0) {
            return;
        }

        input.accountId = accountId;
        if (input.pin?.toString().trim() === "" || input.pin?.toString().trim() === "0"){
            input.pin = undefined;
        }

        if (pinNeeded === true && input.pin === undefined){
            toast.error("please enter valid PIN to proceed.", { theme: "colored" });
            return;
        }

        input.registrationCode = regCode;
        input.userRole = userRole;

        setSubmitting(true);
        const url = 'user/register'
        api.post(url, input ?? {})
            .then((r) => {
                toast.success("User Registered successfully.", { theme: "colored" });
                navigate("/login");
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setSubmitting(false));
    }

    const getRegisterUserSchema = () => {
        return Yup.object().shape({
                locationId: Yup.number().min(1).required(),

                firstName: Yup.string().min(2).max(64).required()
                .test('first-name-no-special-chars', 'First Name cannot contain spaces, special characters or numbers', (value) => {
                return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
                }),
                lastName: Yup.string().min(2).max(64).required()
                .test('last-name-no-special-chars', 'Last Name cannot contain spaces, special characters or numbers', (value) => {
                return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
                }),
                login: Yup.string().min(6).max(64).required()
                .test('login-no-special-chars', 'Login may ONLY contain upper/lower case, @, hyphen, underscore, or period', (value) => {
                return /^[A-Za-z0-9@_.-]+$/.test(value || '');
                }),
                password: Yup.string()
                .min(4, 'Password must be at least 4 characters')
                .required('New Password is required'),
                retypePassword: Yup.string()
                .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                .required('Confirm Password is required'),
                /*
                pin: Yup.number().when(['pinNeeded'], {
                    is: (pinNeeded: boolean) => pinNeeded,
                    then: Yup.number().min(1000).max(9999).integer().required(),
                    otherwise: Yup.number().min(1000).max(9999).integer().optional().nullable().default(null),
                  }),
                  */
            });
    }

    if (loading) return(<></>);

    return (<>
        <DocumentTitle title="Self-Register" />
        <center>
            <Grid verticalAlign='middle' columns={1} centered
                style={{ maxWidth: "600px", align: "center", padding: "30px" }}>
                <Grid.Row>
                    <Grid.Column>
                        <br /><br /><br />
                        <Image src='/assets/logo_orange.png' centered alt="Logo" className="auth-logo"/>
                        <Header as="h1" color="black" textAlign="center">Acceleron Learning</Header>
                        <Header as="h2" color="black" textAlign="center">User Self-Registration</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <label style={{ lineHeight: "2" }}>
                        Already registered? Use the login page instead.
                        </label> <br />
                        <Button as='a' icon='angle left' content='Log In' onClick={(e, data) => { navigate("/login") }} />
                    </Grid.Column>
                </Grid.Row>
                {accountId === 0 && <Grid.Row>
                    <Grid.Column>
                        <Segment textAlign="center">
                            <Header icon>
                                <Icon name='barcode' />
                                Enter your registration code below <br /> to create an account
                            </Header>
                            <br /><br />
                            <label>
                                <Input id='txtRegCode' name='txtRegCode'
                                    type="text" maxLength={15}
                                    size='huge'
                                    label={{ icon: 'asterisk' }}
                                    placeholder='Registration Code'
                                    labelPosition='right corner'
                                    value={regCode}
                                    onChange={(e) => { setRegCode(e.target.value); }}
                                /> </label> <br /><br />

                            <Button primary size='huge'
                                onClick={(e, data) => { validateStudentRegCode() }}>
                                Let's Go! <Icon name='angle right' />
                                {submitting && <label> <Icon name='spinner' /></label>}
                            </Button>
                            <br /><br />
                            {showError && <label style={{ color: "red" }}>Invalid Registration Code</label>}
                            <br />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>}
                {accountId > 0 &&
                <>
                <Grid.Row>
                    <Grid.Column>
                        <center>
                        <div>Registration Code: {regCode} is valid for {getEnumText(UserRole, userRole)}</div>
                        </center>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <center>
                        <h4>Getting Started Tutorial</h4>
                        {welcomeVideoUrl && welcomeVideoUrl !== "" &&
                            <div>
                                <video width="320" controls controlsList="nodownload" >
                                    <source src={welcomeVideoUrl} type="video/mp4"/>
                                </video>
                            </div>
                        }
                        </center>
                        <Formik
                            initialValues={{
                                accountId: 0,
                                locationId: 0,
                                login: '',
                                firstName: '',
                                lastName: '',
                                password: '',
                                retypePassword: '',
                                pin: '',
                                regCode: '',
                                userRole: UserRole.Student
                            }}
                            validationSchema={getRegisterUserSchema()}
                            onSubmit={(values) => { saveRegisteredUser(values) }}>
                            {({ dirty, isValid, handleSubmit }) => (
                                <Form id="courseForm" className="ui form" onSubmit={handleSubmit} size="big">
                                    <TextInput type="hidden" placeholder="" name="accountId" readOnly />
                                    <Form.Group widths="equal">
                                        <LabelOutput placeholder="Account Name (read only)" content={accountName} />
                                        <SelectInput
                                            options={locationJson.filter(x => x.key !== "0")}
                                            placeholder="Location" name="locationId" showRequired />
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <TextInput placeholder="First Name" name="firstName" showRequired maxLength={50} />
                                        <TextInput placeholder="Last Name" name="lastName" showRequired maxLength={50}/>
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <TextInput placeholder="Login" name="login" showRequired maxLength={64} autoComplete="nope"/>
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <TextInput type='password' placeholder="Password" name="password" showRequired maxLength={15} autoComplete="nope"/>
                                        <TextInput  type='password' placeholder="Confirm Password" name="retypePassword" showRequired maxLength={15} autoComplete="nope"/>
                                    </Form.Group>
                                    {pinNeeded && <Form.Group widths="equal">
                                        <TextInput placeholder="Pin (4 digit number)" name="pin" maxLength={4} showRequired/> <br/>
                                        <label>You will need the PIN to reset your password later, please set the PIN to be something that you can easily remember</label>
                                        <br/><br/>
                                    </Form.Group> }
                                    <center>
                                        <Button primary type="submit" size="huge"
                                            loading={submitting}
                                              icon='user'
                                            content="Register" />
                                    </center>
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                </Grid.Row>
                </>}
            </Grid>
        </center>
    </>);
}

export default SelfRegisterPage