import ReactPlayer from 'react-player';

function AcceleronVideoPlayer(props) {
  const isMobile = window.innerWidth <= 768;
  return (

      <div className='player-wrapper2'
      style={{
        textAlign: "left",
        width: isMobile ? "480px" : "680px"
      }}>
        <ReactPlayer
          className='react-player2'
          key="vp_vimeo"
          playing={false}
          controls={true}
          url={props.url}
          style={{
            textAlign: "left",
            width: isMobile ? "480px" : "680px"
          }}
        />
      </div>

    );
  }

  export default AcceleronVideoPlayer;