import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Select } from "semantic-ui-react";
import { useAuth } from "../../app/context/auth_provider";
import { Location } from "../../models/account/location";
import { DorpDownData } from "../../models/common/key_value";
import { Result } from "../../models/common/paged_result";
import { UserRole } from "../../models/user/user";
import { api } from "../../shared/axios_wrapper";
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from "../../shared/utils";

interface AccountLocationSelectionProps {
    locationId: number;
    onLocationSelect: (locationId: number, locationName?: string) => void;
    showAll?: boolean;
}

const AccountLocationSelectionPanel = ({ locationId, onLocationSelect, showAll }: AccountLocationSelectionProps) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [locationJson, setLocationJson] = useState<DorpDownData[]>([]);
    const cacheKey = `locations_for_${user?.id}_${user?.accountId}_${user?.locationId}`;
    useEffect(() => {
        //localStorage.removeItem(cacheKey);
        setLocationJson([]);
        if (user?.role !== UserRole.Student && (user?.accountId ?? 0) > 0) {
            const cachedData = getLocalStorageWithExpiry<DorpDownData[]>(cacheKey);
            if (cachedData) {
                setLocationJson(cachedData);
                setLoading(false);
            } else {
                setLoading(true);
                const locationListUrl = `account/${user?.accountId}/location/drop-down-list`;
                api.get<Result<Location[]>>(locationListUrl)
                    .then((r) => {
                         const locJson: DorpDownData[] = r.data.result.map((loc) => {
                            return {
                                key: loc.id.toString(),
                                value: loc.id,
                                text: loc.name,
                            };
                        });

                        let locJsonWithAll: DorpDownData[] = locJson;

                        if (((user?.role ?? 1) > UserRole.LocationAdmin) || ((showAll ?? false) === true)){
                            locJsonWithAll = [
                                { key: 'All', value: 0, text: 'All' },
                                ...locJson,
                            ];
                        }
                        setLocationJson(locJsonWithAll);

                         // Cache the data in localStorage - 15 min
                         setLocalStorageWithExpiry(cacheKey, locJsonWithAll, 60 * 15);

                        //if (locJson.length === 1) {
                         //   onLocationSelect(locJson[0].value as number, locJson[0].text);
                        //}
                    })
                    .catch((e) => { toast.error("Error", { theme: "colored" }) })
                    .finally(() => {setLoading(false);});
            }
        }
    }, [user, cacheKey, showAll]);

    const memoizedLocationJson = useMemo(() => locationJson, [locationJson]);

    return (
        <>
            <label className="txt-content" style={{ lineHeight: "2" }}>Location (select or type to filter):</label> <br />
            {!loading && <Select
                options={memoizedLocationJson}
                fluid
                search
                size="large"
                className="txt-content"
                closeOnChange
                value={locationId}
                onChange={(e, data) => {
                    var locName = memoizedLocationJson.filter(x => x.value === data?.value)[0]?.text;
                    onLocationSelect(data?.value as number, locName);
                }}
            />}
        </>

    );
};

export default AccountLocationSelectionPanel;
