import React, { useState } from 'react';
import { Accordion, Icon, Button, Card } from 'semantic-ui-react';
import { ModulePart, ScheduleCourseDto, WeekDay } from '../../models/interlift/interlift_schedule';
import { getEnumText } from '../../shared/utils';

type ScheduleTableProps = {
  days: WeekDay[];
  schedule: Map<WeekDay, Map<string, ScheduleCourseDto>>;  // Map of the schedule for each day
  handleEditLinkClick: (day: WeekDay, slot: string) => void;  // Handles editing of slots
  handleDeleteLinkClick: (day: WeekDay, slot: string) => void;  // Handles deletion of slots
  handleAddSlot: (day: WeekDay) => void;  // Handles adding of new slots
};

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  days,
  schedule,
  handleEditLinkClick,
  handleDeleteLinkClick,
  handleAddSlot,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(-1);

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const getFilledSlotsCount = (daySchedule: Map<string, ScheduleCourseDto> | undefined): number => {
    if (!daySchedule) return 0;
    return Array.from(daySchedule.values()).filter(course => course && course.courseName).length;
  };

  // Function to extract the numeric part of the "Education Hour" slot
  const extractSlotNumber = (slot: string): number => {
    const match = slot.match(/Education Hour (\d+)/);  // Extract the number from "Education Hour X"
    return match ? parseInt(match[1], 10) : 0;
  };

  return (
    <Accordion styled fluid exclusive={false}>
      {days.map((day, index) => {
        const dayName = WeekDay[day];
        const daySchedule = schedule.get(day);  // Get the schedule for the specific day
        const filledSlots = getFilledSlotsCount(daySchedule);

        return (
          <React.Fragment key={dayName}>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={() => handleAccordionClick(index)}
            >
              <Icon name="dropdown" />
              {`${dayName} (${filledSlots}/6)`} {/* Display the number of filled slots */}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <Card.Group itemsPerRow={3}>
                {/* Sort the slots based on the Education Hour number */}
                {Array.from(daySchedule?.entries() || [])
                  .sort((a, b) => extractSlotNumber(a[0]) - extractSlotNumber(b[0]))  // Sort based on the slot number
                  .map(([slot, course], slotIndex) => (
                    <Card fluid key={slot}>
                      <Card.Content>
                        {/* Display the actual slot value (e.g., "Education Hour 2") */}
                        <Card.Header>{course.scheduledCode} - {course.moduleName}</Card.Header>
                        <Card.Description>
                          <strong>Slot:</strong> {slot} <br/>
                          <strong>Course:</strong> {course.courseName} <br />
                          <strong>Module:</strong> {course.scheduledCode} - {course.moduleName} - {getEnumText(ModulePart, course.modulePart || '')} <br />
                          <strong>Quiz:</strong> {(course.includeQuiz === true) ? "YES" : "No"} &nbsp;&nbsp;
                          <strong>NR:</strong> {(course.includeNRMaterial === true) ? "YES" : "No"} &nbsp;&nbsp;
                          <strong>GR:</strong> {(course.includeGRMaterial === true) ? "YES" : "No"}
                          <br/><br/>
                          <Button onClick={() => handleEditLinkClick(day, slot)}>Edit</Button>
                          <Button onClick={() => handleDeleteLinkClick(day, slot)} color="red">Delete</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                ))}
              </Card.Group>
                <br/>
              <Button onClick={() => handleAddSlot(day)}>Add Slot</Button>
            </Accordion.Content>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
};

export default ScheduleTable;
