import { useField } from "formik";
import { Form, Input, Label } from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    maxLength?: number;
    readOnly?: boolean;
    width?: number;
    icon?: any;
    iconPosition?: "left" | undefined;
    action?: any;
    autoFocus?: boolean;
    showRequired?: boolean,
    mask?: any;
    value?: any | null;
    hideTitle?: boolean;
    className?: any;
    fluid? : boolean;
    autoComplete?: string;
    style?: any;
    size?: "mini" | "small" | "large" | "big" | "huge" | undefined
    hideError?: boolean;
    onChange?: (e:any, d:any) => void;
    onBlur?: (e:any, d:any) => void;
}

function TextInput(props: Props) {
    const [field, meta] = useField(props.name);
    const {showRequired, hideTitle, hideError,...rest} = props;
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {!hideTitle && props.placeholder && <label htmlFor={props.name} style={{paddingBottom: "10px"}}>
                {props.placeholder} {showRequired ? '*' : '' }
                </label>
            }
            <Input id={props.name} {...field} {...rest}/>
            {!hideError && meta.touched && meta.error ? (
                <Label basic color='red' pointing='above'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
}
export default TextInput;