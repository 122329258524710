import { UserRole } from '../../../models/user/user';

export const MenuItems = [
  {
    key: '/home',
    title: 'Courses',
    path: '/home',
    icon: 'home',
    role: UserRole.Student
  },
  {
    key: '/location/data-files',
    title: 'Resources',
    path: '/location/data-files',
    icon: 'archive',
    role: UserRole.LocationAdmin
  },
  {
    key: '/locations',
    title: 'Locations',
    path: '/locations',
    icon: 'map pin',
    role: UserRole.AccountAdmin
  },
  {
    key: '/location/users',
    title: 'Users',
    path: '/location/users',
    icon: 'users',
    role: UserRole.LocationAdmin
  },
  {
    key: '/location/reports',
    title: 'Reporting',
    path: '/location/reports/course-progress',
    icon: 'chart bar outline',
    role: UserRole.LocationAdmin
  },
  {
    key: '/profile',
    title: 'Profile',
    path: '/profile',
    icon: 'user outline',
    role: UserRole.Student
  },
  {
    key: '/admin',
    title: 'Admin',
    path: '/admin',
    icon: 'cog',
    role: UserRole.SuperAdmin
  }
];
