import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import LabelOutput from "../../app/components/label_output";
import TextArea from "../../app/components/text_area";
import TextInput from "../../app/components/text_input";
import { useAuth } from "../../app/context/auth_provider";
import { Location, LocationSchema } from "../../models/account/location";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { DocumentTitle } from "../../app/components/document_title";
import SelectInput from "../../app/components/selected_input";
import { formatDate } from "../../shared/utils";

function LocationEditPage() {
    const navigate = useNavigate();
    const {user} = useAuth();
    const { locationId } = useParams();
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState<Location>(new Location(user?.accountId));
    //const [users, setUsers] = useState<UserInfo[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const locationIdInt = parseInt(locationId ?? '0');

    useEffect(() => {
      if (locationIdInt > 0){
        setLoading(true);
        const url = `account/${user?.accountId}/location/get/${locationIdInt}`;
        api.get<Result<any>>(url)
          .then((r) => {
            var location = r.data.result.location as Location;
            if (location){
              location.maxUserCount = location.maxUserCount ?? 0;
              location.postalCode = location.postalCode ?? "";
              location.title = location.title ?? "";
              location.shortDescription = location.shortDescription ?? "";
              location.description = location.description ?? "";
            }
            setLocation(location);
            //setUsers(r.data.result.users);
          })
          .catch((e) => { toast.error("Error", { theme: "colored" }) })
          .finally(() => setLoading(false));
      }
    },[locationId, locationIdInt, user?.accountId]);

    const saveLocation = async (input: Location) => {
      setSubmitting(true);
      const url = `account/${user?.accountId}/location/save`;
      api.post(url, input ?? {})
          .then((r) => {
              toast.success("Location saved successfully.", { theme: "colored" });
              navigate("/locations");
          })
          .catch((e) => { toast.error("Error", { theme: "colored" }) })
          .finally(() => setSubmitting(false));
  }

    const LocationView = () => {
        return(
          <Formik
          enableReinitialize
          initialValues={location}
          validationSchema={LocationSchema}
          onSubmit={(values) => {saveLocation(values) }}>
          {({ dirty, isValid, handleSubmit, errors }) => (
          <Form id="locationForm" className="ui form"  onSubmit={handleSubmit} size="big">
               <Form.Group widths="equal">
                <LabelOutput placeholder="Created Date" content={formatDate(location?.createdDate, true)} />
                <LabelOutput placeholder="Updated Date" content={formatDate(location?.updatedDate, true)} />
              </Form.Group>
              <Form.Group widths="equal">
              <SelectInput
                            options={[
                            { key: 'yes', text: 'Yes', value: true },
                            { key: 'no', text: 'No', value: false }
                            ]}
                            placeholder="Active?" name="active" />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Account ID (read only)" content={user?.accountId} />
              <LabelOutput placeholder="Account Name (read only)" content={user?.accountName} />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Id (read only)" name="id" readOnly showRequired />
              <TextInput placeholder="Name" name="name" />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Title" name="title" />
              <TextInput placeholder="Postal Code" name="postalCode" />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Max User Count" name="maxUserCount" />
            </Form.Group>
            <Form.Group widths="equal">
                <TextArea placeholder="Desc" name="description" rows={3} />
            </Form.Group>
            <Button primary floated="right" type="submit" size="large"
                            loading={submitting}
                            icon='save outline'
                            content="Save Location" />
            <Button floated="right" type="button" content="Close" size="large"
            onClick={() => {navigate('/locations')}}/>
          </Form>
          )}
          </Formik>);
  }

return(<>
      <DocumentTitle title={"Location Edit"} />
      <Button basic icon="arrow left" size="big" content="Back to Locations" onClick={() => {navigate('/locations')}}/>
      {!loading && <h2><Icon name='map pin'/> Location </h2>}
      {!loading && <LocationView/>}
    </>);
}

export default LocationEditPage