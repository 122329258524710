import React, { useCallback, useState } from "react";
import { Button, Icon, Label, Accordion, Table } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../app/context/auth_provider";
import { useEffect } from "react";
import { api } from "../../shared/axios_wrapper";
import { Result } from "../../models/common/paged_result";
import { toast } from "react-toastify";
import { ModuleTaskAssessment } from "../../models/user/user_lesson_action";
import ProgressBar from "../../app/components/progress_bar";
import { UserRole } from "../../models/user/user";
import ExpandableSegment from "../../app/components/expandable_segment";

function LessonModuleAssessment() {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [assessmentJson, setAssessmentJson] = useState<Record<string, ModuleTaskAssessment[]>>({});
    const [courseName, setCourseName] = useState("");
    const [userResponses, setUserResponses] = useState<{
        taskName: string;
        ans: boolean | undefined;
        latestAns?: boolean | undefined;
    }[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const [allExpanded, setAllExpanded] = useState(false);

    const totalQuestions = Object.values(assessmentJson).reduce(
        (total, assessments) => total + assessments.length,
        0
      );

    useBlocker(({ currentLocation, nextLocation }) => {
        if ((user?.role ?? 1) !== UserRole.Student) return false;
        const shouldBlock = hasChanges && currentLocation.pathname !== nextLocation.pathname;
        if (shouldBlock) {
            console.log('Navigation blocked:', currentLocation.pathname, '->', nextLocation.pathname);
            saveUserResponses();
            return false;
        }
        return shouldBlock;
    });

    useEffect(() => {
        setLoading(true);
        const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/course/${courseId?.trim()}/assessments`;
        api
            .get<Result<any>>(url)
            .then((r) => {
                if (r.data?.result?.courseName) {
                    setCourseName(r.data?.result?.courseName);
                }
                if (r.data?.result?.assessmentData) {
                    const assessmentData: ModuleTaskAssessment[] = r.data.result.assessmentData;

                    const groupedAssessments = assessmentData.reduce(
                        (acc, assessment) => {
                            if (!acc[assessment.moduleCode]) {
                                acc[assessment.moduleCode] = [];
                            }
                            acc[assessment.moduleCode].push(assessment);
                            return acc;
                        },
                        {} as Record<string, ModuleTaskAssessment[]>
                    );

                    for (const group in groupedAssessments) {
                        if (groupedAssessments.hasOwnProperty(group)) {
                            groupedAssessments[group].sort((a, b) => a.order - b.order);
                        }
                    }

                    setAssessmentJson(groupedAssessments);

                    const initialUserResponses = assessmentData.map((assessment) => ({
                        taskName: assessment.taskName,
                        ans: assessment.ans,
                        latestAns: assessment.latestAns,
                    }));
                    setUserResponses(initialUserResponses);
                }
            })
            .catch((e) => {
                toast.error("Error", { theme: "colored" });
            })
            .finally(() => setLoading(false));
    }, [user?.accountId, user?.locationId, courseId]);

    const handleAccordionClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    const handleToggleAll = () => {
        setAllExpanded((prevAllExpanded) => !prevAllExpanded);
        setActiveIndex((prevActiveIndex) => (-1));
    };

    const handleRadioChange = (
        taskName: string,
        value: boolean,
        isLatest: boolean
    ) => {

        setUserResponses((prevResponses) => {
            try {
                let updatedResponses = [...prevResponses];
                let found = false;

                for (let i = 0; i < updatedResponses.length; i++) {
                    if (updatedResponses[i].taskName === taskName) {
                        updatedResponses[i] = { ...updatedResponses[i], ans: value };
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    updatedResponses.push({ taskName: taskName, ans: value });
                }
                setHasChanges(true);

                return updatedResponses;
            } catch (error) {
                console.error('Error in setUserResponses:', error);
                return prevResponses;
            }
        });

       setHasChanges(true);
    };

    const saveUserResponses = useCallback(async () => {
        if (!hasChanges) return;
        if ((user?.role ?? 1) !== UserRole.Student) return;

        setHasChanges(false);
        try {
            const url = "lesson/save-user-assessment-results";
            await api.post<Result<number>>(url, {
                accountId: user?.accountId,
                locationId: user?.locationId,
                userId: user?.id,
                courseId: courseId ?? 0,
                assessmentJson: userResponses,
            });

            toast.success("User course assessment data has been saved", {
                theme: "colored",
            });
        } catch (error) {
            console.error("Save error:", error);
            toast.error("Error saving assessment data", {
                theme: "colored",
            });
        }
    },[hasChanges, courseId, user, userResponses]);

    const handleSubmit = async () => {
        if (!hasChanges) return;
        if ((user?.role ?? 1) !== UserRole.Student) return;

        setSubmitting(true);
        setHasChanges(false);

        await saveUserResponses();

        setSubmitting(false);
        navigate(`/lessons/courses/${courseId}`);
    };

    useEffect(() => {
        if ((user?.role ?? 1) === UserRole.Student) {
            const autoSaveInMin = 2;
            const saveUserResponsesTimeout = setTimeout(saveUserResponses, 1000 * 60 * autoSaveInMin);

            return () => clearTimeout(saveUserResponsesTimeout);
        }
    }, [userResponses, hasChanges, user, courseId, saveUserResponses]);

    return (
        <div>
            <DocumentTitle title={"Lesson Course Assessment"} />

            {!loading && (
                <Button basic onClick={() => {navigate(`/lessons/courses/${courseId}`);}}>
                    <Icon name="arrow left" size="big" /> Back to Course
                </Button>
            )}

            {!loading && <h2>{courseName} Assessment</h2>}

            {!loading && <ExpandableSegment initialExpand={true} title="Instructions" content={
                <>
                <ul style={{fontSize: "20px", lineHeight:"1.2"}}>
                    <li>Click on each module section to expand or collapse</li>
                    <li>Answer YES or NO in the 'Initial Assessment' column</li>
                    <li>Click SUBMIT ANSWERS button to save your progress</li>
                    <li>Click 'Expand All' to print (if needed)</li>
                    <li>As you work on Tasks within each module, the 'Latest Assessment' column will auto-update</li>
                </ul>
                </>
                } />
            }

           <br/>
            {!loading &&  <ProgressBar bgColor={"#bd632f"} completed={ ((userResponses.filter((response) => response.ans !== undefined).length) / totalQuestions) * 100 || 0} />}

            {!loading && (
                <Button basic onClick={handleToggleAll} floated="right">
                    {allExpanded ? "Collapse All" : "Expand All"}
                </Button>
            )}

            <br /><br/>

            {!loading  && (user?.role ?? 1) === UserRole.Student && (
                <Button
                    primary
                    loading={submitting}
                    disabled={submitting || userResponses?.length === 0}
                    onClick={handleSubmit}
                    size="huge"
                >
                    Submit Answers
                </Button>
            )}
            <br/><br/>
            {Object.entries(assessmentJson).map(([moduleCode, assessments], moduleIndex) => (
                <Accordion
                    key={`accordion_${moduleCode}`}
                    styled
                    fluid
                    exclusive={true}
                >
                    <Accordion.Title
                        index={allExpanded ? undefined : activeIndex}
                        onClick={() => handleAccordionClick(moduleIndex)}>
                        <Icon name="dropdown" />
                        <b className="txt-primary" style={{fontSize:"20px", paddingBottom: "10px"}}>
                            {moduleCode} - {assessments[0].moduleName}
                        </b> - (Questions: {assessments.length})
                    </Accordion.Title>
                    <Accordion.Content active={allExpanded || activeIndex === moduleIndex}>
                        <Table stackable selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>Question</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Initial Assessment</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Latest Assessment</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {assessments.map((assessment, index) => (
                                    assessment?.question && (
                                        <Table.Row key={"assess_" + moduleIndex + "_" + index}>
                                            <Table.Cell textAlign="center">
                                                <Label circular size="large" color="black">{index + 1}</Label>
                                            </Table.Cell>
                                            <Table.Cell className="txt-large">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: `<b className="txt-primary">${assessment.taskName}</b> - ${assessment.question}`
                                                    }} />

                                            </Table.Cell>
                                            <Table.Cell textAlign="center" style={{ lineHeight: "2", backgroundColor: "#DCDCDC" }}>
                                                <center>Initial</center>
                                                {(assessment.ans === undefined) ? (
                                                    <>
                                                        <label style={{ paddingRight: "10px", fontSize: "18px" }}>
                                                        <input
                                                            type='radio'
                                                            name={`question_${moduleIndex}_${index}`}
                                                            checked={userResponses.some(response => response.taskName === assessment.taskName && response.ans === true)}
                                                            onChange={() => handleRadioChange(assessment.taskName, true, false)}
                                                        />
                                                        &nbsp;&nbsp;Yes
                                                        </label>
                                                        <br />
                                                        <label style={{ paddingRight: "10px", fontSize: "18px" }}>
                                                        <input
                                                            type='radio'
                                                            name={`question_${moduleIndex}_${index}`}
                                                            checked={userResponses.some(response => response.taskName === assessment.taskName && response.ans === false)}
                                                            onChange={() => handleRadioChange(assessment.taskName, false, false)}
                                                        />
                                                        &nbsp;&nbsp;No
                                                        </label>
                                                    </>
                                                    ) : (
                                                    assessment.ans === true ? "YES" : "No"
                                                    )}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" style={{ lineHeight: "2" }}>
                                                <center>Latest</center>
                                                {assessment.latestAns !== undefined && assessment.latestAns !== null && (assessment.latestAns ? "YES" : "NO")}
                                            </Table.Cell>
                                        </Table.Row>
                                    )))}
                            </Table.Body>
                        </Table>
                    </Accordion.Content>
                </Accordion>
            ))}
            <br /><br />
            {!loading  && (user?.role ?? 1) === UserRole.Student && (
                <Button
                    primary
                    loading={submitting}
                    disabled={submitting || userResponses?.length === 0}
                    onClick={handleSubmit}
                    size="huge"
                >
                    Submit Answers
                </Button>
            )}
        </div>
    );
}

export default LessonModuleAssessment;
