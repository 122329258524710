import { Formik } from "formik";
import * as yup from 'yup';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Dropdown, Form, Icon, Table } from "semantic-ui-react";
import ConfirmButton from "../../app/components/confirm_button";
import SelectInput from "../../app/components/selected_input";
import TextArea from "../../app/components/text_area";
import TextInput from "../../app/components/text_input";
import { Result } from "../../models/common/paged_result";
import { Course, CourseModuleDisplayOrderDto, CourseModuleDto, CourseSchema, CourseType, CourseViewDto } from "../../models/course/course";
import { api } from "../../shared/axios_wrapper";
import { DocumentTitle } from "../../app/components/document_title";
import { enumToKeyValueList } from "../../shared/utils";

type DisplayOrderValues = { [moduleId: string]: number };

function CourseEditPage() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState<Course>(new Course());
  const [existingModules, setExistingModules] = useState<CourseModuleDto[]>();
  const [modules, setModules] = useState<CourseModuleDto[]>();
  const [moduleValues, setModuleValues] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const courseIdInt = parseInt(courseId ?? '0');

  const initialDisplayOrderValues: DisplayOrderValues = {
    // Initialize your values here if needed
  };

  const [displayOrderValues, setDisplayOrderValues] = useState<DisplayOrderValues>(initialDisplayOrderValues);

  useEffect(() => {
    if (courseIdInt > 0) {
      setLoading(true);
      const url = `course/get/${courseIdInt}`;
      api.get<Result<CourseViewDto>>(url)
        .then((r) => {
          if (r.data?.result?.course) {
            const course = new Course(r.data.result.course);
            setCourse(course);
          }
          if (r.data?.result?.modules)
            setExistingModules(r.data.result.modules);

          const initialDisplayOrderValues: DisplayOrderValues = {};

          r.data.result.modules?.forEach((module, index) => {
            initialDisplayOrderValues[module.moduleId.toString()] = module.displayOrder ?? index;
          });

          setDisplayOrderValues(initialDisplayOrderValues);
        })
        .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [courseId, courseIdInt]);

  useEffect(() => {
    if (courseIdInt > 0) {
      setLoading(true);
      api.get<Result<CourseModuleDto[]>>('module/drop-down-list')
        .then((r) => {
          setModules(r.data.result);
          const transformedList = r.data.result.map(item => ({
            key: item.moduleCode,
            value: item.moduleName,
            text: `${item.moduleName} - ${item.moduleCode}`,
          }));
          setModuleValues(transformedList);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [courseId, courseIdInt]);

  useEffect(() => {
    if (existingModules) {
      // Create an initial displayOrderValues object
      const initialDisplayOrderValues: DisplayOrderValues = {};
      existingModules.forEach((module, index) => {
        initialDisplayOrderValues[module.moduleId] = module.displayOrder || index + 1;
      });

      // Set the initial displayOrderValues
      setDisplayOrderValues(initialDisplayOrderValues);
    }
  }, [existingModules]);

  const saveCourse = async (input: Course) => {
    setSubmitting(true);
    const url = 'course/save'
    api.post(url, input ?? {})
      .then((r) => {
        toast.success("Course saved successfully.", { theme: "colored" });
        navigate("/admin/courses");
      })
      .catch((e) => {
        console.log(e);
        //toast.error("Error", { theme: "colored" })
      })
      .finally(() => setSubmitting(false));
  }

  const addCourseModule = async (moduleNames: string[] | undefined) => {
    if (!moduleNames) return;
    for (const moduleName of moduleNames) {
      const selectedModule = modules?.filter(x => x.moduleName === moduleName)[0];
      if (!selectedModule) continue;
      setSubmitting(true);
      const url = 'course/course-module-insert'
      api.post(url, { courseId: courseId, moduleId: selectedModule?.moduleId })
        .then((r) => {
          if (existingModules) {
            setExistingModules(existingModules => [...existingModules ?? [], selectedModule]);
          }
          toast.success("Module added successfully.", { theme: "colored" });
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => { setSubmitting(false); setSelectedCourses([]); });
    }
  }

  const removeCourseModule = async (moduleId: number) => {
    const selectedModule = existingModules?.filter(x => x.moduleId === moduleId)[0];
    if (!selectedModule) return;
    setSubmitting(true);
    const url = `course/${courseId}/course-module-delete/${moduleId}`
    api.del(url)
      .then((r) => {
        if (existingModules) {
          setExistingModules(existingModules => existingModules?.filter(item => item.moduleId !== moduleId));
        }
        toast.success("Module removed successfully.", { theme: "colored" });
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setSubmitting(false));
  }

  const changeDisplayOrder = async (displayOrderValues: DisplayOrderValues) => {
    const displayOrderUpdates: CourseModuleDisplayOrderDto[] = [];
    const displayOrderMap: { [moduleId: string]: number } = {};

    existingModules?.forEach((module, index) => {
      const moduleId = module.moduleId;
      const displayOrder = displayOrderValues[moduleId];

      if (displayOrder !== undefined && displayOrder !== null) {
        if (
          displayOrder <= 0 ||
          displayOrder > (existingModules.length || 0) ||
          displayOrderMap[moduleId] !== undefined
        ) {
          toast.error("Invalid display order values.", { theme: "colored" });
          return;
        }

        displayOrderMap[moduleId.toString()] = displayOrder;

        const displayOrderUpdate: CourseModuleDisplayOrderDto = {
          courseId: courseIdInt,
          moduleId: moduleId,
          displayOrder: displayOrder,
        };

        displayOrderUpdates.push(displayOrderUpdate);
      }
    });
    if (displayOrderUpdates.length > 0) {
      setSubmitting(true);
      const url = 'course/change-module-display-order';

      try {
        await api.post(url, displayOrderUpdates);
        toast.success("Module display order changed successfully.", { theme: "colored" });
        window.location.reload();
      } catch (e) {
        toast.error("Error", { theme: "colored" });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const CourseForm = () => {
    return (
      <Formik
        initialValues={course}
        validationSchema={CourseSchema}
        onSubmit={(values) => { saveCourse(values) }}>
        {({ dirty, isValid, handleSubmit }) => (
          <Form id="courseForm" className="ui form" onSubmit={handleSubmit} size="big">
            <Form.Group widths="equal">
              <SelectInput
                      options={[
                      { key: 'yes', text: 'Yes', value: true },
                      { key: 'no', text: 'No', value: false }
                      ]}
                      placeholder="Active?" name="active" />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Id (read only)" name="id" readOnly />
              <TextInput placeholder="Course Name" name="name" showRequired />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectInput
                options={enumToKeyValueList(CourseType)}
                placeholder="course type" name="courseType" />
              <TextInput placeholder="ICON" name="courseIcon"  />
            </Form.Group>
            <Form.Group widths="equal">
              <TextArea placeholder="Short Desc" name="shortDescription" rows={2} />
            </Form.Group>
            <Form.Group widths="equal">
              <TextArea placeholder="Desc" name="description" rows={3} />
            </Form.Group>
            <Form.Group widths="equal">
              <TextArea placeholder="Promo JSON" name="promoJson" rows={5} />
            </Form.Group>
            <Button primary floated="right" type="submit" size="large"
              loading={submitting}
              disabled={!isValid}
              icon='save outline'
              content="Save Course" />
            <Button floated="right" type="button" content="Cancel" size="large"
              onClick={() => { navigate('/admin/courses/') }} />
          </Form>
        )}
      </Formik>);
  };

  const displayOrderValidationSchema = yup.object().shape({
    items: yup.array()
      .of(yup.number())
      .min(1)
      .test({
        message: 'Cannot exceed the number of existing modules',
        test: function (value) {
          const existingModulesLength: number = this.resolve(yup.ref('existingModules.length'));
          return !existingModulesLength || (value?.length ?? 0) <= existingModulesLength;
        },
      })
      .required('Required'),
  });

  const AssociateModules = () => {
    return (
      <>
        <br /><br />
        <Divider horizontal>Associated Learning Modules</Divider>

        <h4>Currently Associated Modules ({existingModules?.length})</h4>
        <Formik
          initialValues={{ items: displayOrderValues }}
          validationSchema={displayOrderValidationSchema}
          onSubmit={(values) => {
            setSubmitting(false);
          }}
        >
          {({ dirty, values, handleSubmit }) => (
            <Form id="frmDisplayOrder" onSubmit={(e) => {
              e.preventDefault();
              changeDisplayOrder(values.items);
            }} >
              <Table celled striped selectable stackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Display Order</Table.HeaderCell>
                    <Table.HeaderCell>Module</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {existingModules?.map((m, index) => (
                    <Table.Row key={"displayOrder_" + index}>
                      <Table.Cell textAlign="center"> {index + 1}.</Table.Cell>
                      <Table.Cell textAlign="center">
                        <TextInput placeholder=""
                          type="number"
                          name={"items['" + m.moduleId + "']"}
                          style={{ width: "100px" }}
                          size="huge"
                          maxLength={2}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <Icon name="play" size="large" />
                        {m.moduleName} - {m.moduleCode}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <ConfirmButton
                          id={m.moduleId}
                          value={""}
                          confirmButtonText={"Delete"}
                          color={"grey"}
                          content={"Remove " + m.moduleName + "?"}
                          iconName="delete"
                          floated="right"
                          handleConfirm={removeCourseModule}
                        />
                        <br/>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              {existingModules && existingModules?.length > 0 && (
                <Button
                  loading={submitting}
                  primary
                  icon='ordered list'
                  type="submit"
                  size="large"
                  disabled={submitting}
                  content="Set Display Orders"
                />
              )}
            </Form>
          )}
        </Formik>

        <h4>Add New Module Associations</h4>
        <Dropdown
          placeholder='Module'
          style={{ fontSize: "18px" }}
          fluid
          multiple
          search
          selection
          options={moduleValues.filter((item1) => !existingModules?.some((item2) => item2.moduleCode === item1.key))}
          value={selectedCourses}
          onChange={(e, data) => {
            setSelectedCourses(data.value as string[]);
          }}
        /> <br />
        <Button primary icon="add" size="large" content="Add" floated="right" onClick={() => { addCourseModule(selectedCourses); }} />
        <br /><br /><br />
      </>
    );
  };

  return (<>
    <DocumentTitle title={"Course Edit"} />
    <Button basic icon="arrow left" size="large" content="Back to Courses" onClick={() => { navigate('/admin/courses/') }} />
    {!loading && course && <h2><Icon name='bookmark outline' /> Course </h2>}
    {!loading && <CourseForm />}
    {!loading && course.id > 0 && existingModules && <AssociateModules />}
  </>);
}

export default CourseEditPage;
