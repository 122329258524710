import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button,  Icon, Form} from "semantic-ui-react";
import { api } from "../../shared/axios_wrapper";
import { Result } from "../../models/common/paged_result";
import { toast } from "react-toastify";
import LabelOutput from "../../app/components/label_output";
import { Account } from "../../models/account/account";
import { LocationListView } from "./component/location_list_component";
import { Location } from "../../models/account/location";
import { DocumentTitle } from "../../app/components/document_title";
import { formatDate } from "../../shared/utils";

function AccountViewPage() {
    const navigate = useNavigate();
    const { accountId } = useParams();
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState<Account>(new Account());
    const [locations, setLocations] = useState<Location[]>([]);
    const accountIdInt = parseInt(accountId ?? '0');

    useEffect(() => {
      if (accountIdInt > 0){
        setLoading(true);
        const url = `account/get/${accountIdInt}`;
        api.get<Result<any>>(url)
          .then((r) => {
            //console.log(r.data.result.account);
            setAccount(r.data.result.account);
            setLocations(r.data.result.locations);
          })
          .catch((e) => { toast.error("Error", { theme: "colored" }) })
          .finally(() => setLoading(false));
      }
    },[accountId, accountIdInt]);

    const AccountView = () => {
        return(
          <Form id="accountForm" className="ui form" size="big">
             <Form.Group widths="equal">
             <LabelOutput placeholder="Created Date" content={formatDate(account?.createdDate, true)} />
             <LabelOutput placeholder="Updated Date" content={formatDate(account?.updatedDate, true)} />
            </Form.Group>
              <Form.Group widths="equal">
              <Form.Checkbox  label="Active?" style={{fontSize:"18px"}} checked={account.active}
             disabled/>
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Id" content={account.id} />
              <LabelOutput placeholder="Name" content={account.name} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Location Admin Registration Code" content={account.locationAdminRegistrationCode} />
              <LabelOutput placeholder="Student Registration Code" content={account.registrationCode} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Max Location Count" content={account.maxLocationCount} />
              <LabelOutput placeholder="Max User Count" content={account.maxUserCount} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Created Date" content="" />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Description" content={account.description} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Video CDN" content={account.videoCdn} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Data (Files) CDN" content={account.dataCdn} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Single Sign-On (SSO) URL" content={account.ssoUrl} />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox  label="Enable PIN to reset Password?" style={{fontSize:"18px"}} checked={account.enablePinToResetPwd}
             disabled/>
            </Form.Group>
            <Button floated="right" type="button" content="Close" size="large"
            onClick={() => {navigate('/accounts')}}/>
          </Form>
          )}

return(<>
      <DocumentTitle title={"Account View"} />
      <Button basic icon="arrow left" size="big" content="Back to Accounts" onClick={() => {navigate('/accounts')}}/>
      {!loading && <h2><Icon name='at'/> Account </h2>}
      {!loading && <AccountView/>}
      <br/><br/>
      {!loading && <LocationListView locationList={locations} />}

    </>);
}

export default AccountViewPage