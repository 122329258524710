import * as yup from 'yup';
//client account
export class Account {
    id: number; //primary key - auto-increment
    name: string; //unique - not null - string(128)
    title?: string;   //nullable - string(128)
    description?: string; //nullable - string(1024)
    shortDescription?: string; //nullable - string(255)
    active: boolean; //not null - boolean - default - true
    logo?: string; //nullable - string(64)
    subDomain?: string; //nullable - string(64)
    registrationCode?: string; //nullable - string(15)
    locationAdminRegistrationCode?: string; //nullable - string(15)
    certificationTemplate?: string; //nullable - string(max)
    themeFontColor?: string; //nullable - string(32)
    themeBgColor?: string; //nullable - string(32)
    maxLocationCount?: number;
    maxUserCount?: number;
    timelines?: string; //nullable - string(512) - comment: pre-release, first 72-hours, W 1-2, W 3-4, W 5-6, W 7-8+
    videoCdn?: string;  //nullable - string(255)
    dataCdn?: string;  //nullable - string(255)
    enablePinToResetPwd: boolean; //not null - defaults to false
    ssoUrl?: string;
    createdDate?: Date; //nullable - defaults to today
    createdBy?: string; //nullable -string(64) to store login, defaults to system
    updatedDate?: Date; //nullable - defaults to today
    updatedBy?: string; //nullable -string(64) to store login, defaults to system

    constructor() {
        this.id = 0;
        this.name = "";
        this.active = true;
        this.title = "";
        this.enablePinToResetPwd = false;
    }
}

export const AccountSchema = yup.object().shape({
    id: yup.number().min(0),
    name: yup.string().min(2).max(128).required()
    .test('name-no-special-chars', 'Name cannot contain special characters or numbers', (value) => {
      return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
      }),
    title: yup.string().max(128).nullable(),
    description: yup.string().min(0).max(1024),
    shortDescription: yup.string().min(0).max(255),
    active: yup.boolean(),
    logo: yup.string().min(0).max(64).nullable(),
    subDomain: yup.string().min(0).max(64).nullable(),
    registrationCode: yup.string().min(0).max(15).nullable(),
    locationAdminRegistrationCode: yup.string().max(15).nullable(),
    //themeFontColor: yup.string().min(0).max(32).nullable(),
    //themeBgColor: yup.string().min(0).max(32).nullable(),
    maxLocationCount: yup.number().min(0).nullable(),
    maxUserCount: yup.number().min(0).nullable(),
    timelines: yup.string().min(0).max(512).nullable(),
    videoCdn: yup.string().min(0).max(255).nullable(),
    dataCdn: yup.string().min(0).max(255).nullable(),
    enablePinToResetPwd: yup.boolean(),
    ssoUrl: yup.string().min(0).max(255).nullable()
  });

  export interface AccountListView extends Account {
    activeLocationCount: number;
    activeUserCount: number;
 }

 export interface AccountCourseDisplayOrderDto
{
    accountId: number;
    courseId: number;
    displayOrder: number;
}

export interface FileData {
  folder: string;
  file: string;
  fileType: string;
  expiryS3Url: string;
}


export interface AccountListView extends Account {
  activeLocationCount: number;
  activeUserCount: number;
  defaultLocationId: number;
  defaultLocationName: string;
}