import { Icon, List } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";

function CertificateReportPage() {

    return(<>
         <DocumentTitle title="Progress Reports" />
        <h3><Icon name='chart bar outline'/> Progress Reports</h3>
        <List divided relaxed>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='chart area' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/location/reports/student-progress">Students Progress Report</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
            <List.Item style={{padding:"20px"}}>
                <List.Icon name='pie chart' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header><a href="/location/reports/course-progress">Courses Progress Report</a></List.Header>
                    <List.Description></List.Description>
                </List.Content>
            </List.Item>
        </List>
    </>);
}
export default CertificateReportPage;