import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import TextArea from "../../app/components/text_area";
import TextInput from "../../app/components/text_input";
import { Account, AccountSchema } from "../../models/account/account";
import { Location } from "../../models/account/location";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { LocationListView } from "./component/location_list_component";
import { DocumentTitle } from "../../app/components/document_title";
import SelectInput from "../../app/components/selected_input";
import LabelOutput from "../../app/components/label_output";
import { formatDate } from "../../shared/utils";

function AccountEditPage() {
    const navigate = useNavigate();
    const { accountId } = useParams();
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState<Account>(new Account());
    const [locations, setLocations] = useState<Location[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const accountIdInt = parseInt(accountId ?? '0');

    useEffect(() => {
        if (accountIdInt > 0) {
            setLoading(true);
            const url = `account/get/${accountIdInt}`;
            api.get<Result<any>>(url)
                .then((r) => {
                    var account = r.data.result.account as Account;
                    if (account){
                        account.maxUserCount = account.maxUserCount ?? 0;
                        account.maxLocationCount = account.maxLocationCount ?? 0;
                        account.registrationCode = account.registrationCode ?? "";
                        account.locationAdminRegistrationCode = account.locationAdminRegistrationCode ?? "";
                        account.shortDescription = account.shortDescription ?? "";
                        account.description = account.description ?? "";
                        account.videoCdn = account.videoCdn ?? "";
                        account.dataCdn = account.dataCdn ?? "";
                        account.title = account.title ?? "";
                        account.ssoUrl = account.ssoUrl ?? "";
                        account.enablePinToResetPwd = account.enablePinToResetPwd ?? false;
                    }
                   // console.log(account);
                    setAccount(account);
                    setLocations(r.data.result.locations);
                })
                .catch((e) => { toast.error("Error", { theme: "colored" }) })
                .finally(() => setLoading(false));
        }
    }, [accountId, accountIdInt]);

    const saveAccount = async (input: Account) => {
        setSubmitting(true);
        const url = 'account/save'
        //console.log(input);
        api.post(url, input ?? {})
            .then((r) => {
                toast.success("Account saved successfully.", { theme: "colored" });
                navigate("/accounts");
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setSubmitting(false));
    }

    const AccountView = () => {
        return (
            <Formik
                enableReinitialize
                initialValues={account}
                validationSchema={AccountSchema}
                onSubmit={(values) => {saveAccount(values); }}>
                {({ dirty, isValid, handleSubmit, errors }) => (
                    <Form id="accountForm" className="ui form"  onSubmit={handleSubmit} size="big">
                        <Form.Group widths="equal">
                            <LabelOutput placeholder="Created Date" content={formatDate(account?.createdDate, true)} />
                            <LabelOutput placeholder="Updated Date" content={formatDate(account?.updatedDate, true)} />
                        </Form.Group>
                        <Form.Group widths="equal">
                        <SelectInput
                            options={[
                            { key: 'yes', text: 'Yes', value: true },
                            { key: 'no', text: 'No', value: false }
                            ]}
                            placeholder="Active?" name="active" />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextInput placeholder="Id (read only)" name="id" readOnly showRequired />
                            <TextInput placeholder="Name" name="name" showRequired />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextInput placeholder="Location Admin Registration Code (unique) " name="locationAdminRegistrationCode" />
                            <TextInput placeholder="Student Registration Code (unique) " name="registrationCode" />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextInput placeholder="Title " name="title" />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextInput placeholder="Max Location Count" name="maxLocationCount" />
                            <TextInput placeholder="Max User Count" name="maxUserCount" />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextArea placeholder="Desc" name="description" rows={3} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextArea placeholder="Video CDN" name="videoCdn" rows={3} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextArea placeholder="Data (File) CDN" name="dataCdn" rows={3} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <TextArea placeholder="Single Sign-On (SSO) URL" name="ssoUrl" rows={3} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Checkbox label="Enable PIN to reset Password?" style={{ fontSize: "18px" }}
                            checked={account.enablePinToResetPwd}
                            onChange={(e, data) => { setAccount({ ...account, enablePinToResetPwd: data.checked ?? false }) }}/>
                        </Form.Group>
                        <Button primary floated="right" type="submit" size="large"
                            loading={submitting}
                            icon='save outline'
                            content="Save Account" />
                        <Button floated="right" type="button" content="Close" size="large"
                            onClick={() => { navigate('/accounts') }} />
                    </Form>
                )}
            </Formik>);
    }

    return (<>
        <DocumentTitle title={"Account Edit"} />
        <Button basic icon="arrow left" size="big" content="Back to Accounts" onClick={() => { navigate('/accounts') }} />
        {!loading && <h2><Icon name='at' /> Account </h2>}
        {!loading && <AccountView />}
        <br/><br/>
        {!loading && <LocationListView locationList={locations} />}

    </>);
}

export default AccountEditPage