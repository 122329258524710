import { Button, Divider, Icon, Label, List } from "semantic-ui-react";
import { CourseModuleViewDto, Question } from "../../../models/course/learning_module";
import { useAuth } from "../../../app/context/auth_provider";
import { UserRole } from "../../../models/user/user";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../../shared/axios_wrapper";
import { UserQuizAns } from "../../../models/user/user_lesson_action";
import { Result } from "../../../models/common/paged_result";
import ExpandableSegment from "../../../app/components/expandable_segment";
import { useBlocker } from "react-router-dom";
import { formatDate } from "../../../shared/utils";

interface props {
  module: CourseModuleViewDto | undefined,
  //setModule: (module:CourseModuleViewDto) => void;
  showQuiz: boolean;
  setShowQuiz: (show: boolean) => void;
  completedFlag?: boolean;
  completedDate?: Date | undefined;
  lastQuizScore?: number;
  lastQuizAttemptedDate?: Date | undefined;
}

export const QuizForm = ({ module, showQuiz, setShowQuiz, completedFlag, completedDate, lastQuizScore, lastQuizAttemptedDate }: props) => {
  const { user } = useAuth();
  const [shuffledQuestions, setShuffledQuestions] = useState<Question[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [lastQuizMarks, setLastQuizMarks] = useState(lastQuizScore ?? 0);
  const [userAnswers, setUserAnswers] = useState<Record<number, number>>({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Assuming quiz.qs is an array of questions
    const questions = module?.quiz?.qs || [];

    // Shuffle choices for each question
    const shuffledQuestions = questions.map((question) => {
      const choices = [
        { label: question.c1, choiceId:1, selected: question.c1_sel, displayOrder: 1 },
        { label: question.c2, choiceId:2, selected: question.c2_sel, displayOrder: 2 },
        { label: question.c3, choiceId:3, selected: question.c3_sel, displayOrder: 3 },
        { label: question.c4, choiceId:4, selected: question.c4_sel, displayOrder: 4 },
        { label: question.c5, choiceId:5, selected: question.c5_sel, displayOrder: 5 },
      ];

      const nonEmptyChoices = choices.filter((choice) => choice.label && choice.label?.trim() !== '');

      // Shuffle non-empty choices array
      const shuffledChoices = [...nonEmptyChoices].sort(() => Math.random() - 0.5);

      return {
        ...question,
        choices: shuffledChoices,
      };
    });

    setShuffledQuestions(shuffledQuestions);
  }, [module?.quiz]);

  /*
  useEffect(() => {
    console.log(userAnswers);
  }, [userAnswers]);
  */

  useBlocker(({ currentLocation, nextLocation }) => {
    //if ((user?.role ?? 1) !== UserRole.Student) return false;
    const shouldBlock = hasChanges && currentLocation.pathname !== nextLocation.pathname;
    if (shouldBlock) {
      console.log('Navigation blocked:', currentLocation.pathname, '->', nextLocation.pathname);
      saveUserQuizResponse(false);
      return false;
    }
    return shouldBlock;
  });

  if (!module?.quiz || !module?.quiz?.qs) return (<></>);

  const handleRadioChange = (questionId: number, choice: string) => {
    //console.log('question id: ' + questionId + ' choice: ' + choice);
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: parseInt(choice, 10) }));
    setHasChanges(true);
  };

  const validateQuizAnswers = (quizAnswers: UserQuizAns[]): boolean => {
    for (const answer of quizAnswers) {
      if (answer.ans === 0) {
        return false;
      }
    }
    return true;
  }

  const saveUserQuizResponse = async (showError: boolean) => {
    if ((user?.role ?? 1) !== UserRole.Student &&
    (user?.role ?? 1) !== UserRole.LocationAdmin) return false;

    const quizAnswers: UserQuizAns[] = module?.quiz?.qs.map((q) => ({
      questionId: q.id,
      ans: userAnswers[q.id] || 0,
    })) || [];

    if (!module || module?.courseId <= 0 || module?.moduleId <= 0 || !validateQuizAnswers(quizAnswers)) {
      if (showError) toast.error("Please answer all quiz questions before submitting.", { theme: "colored" });
      return;
    }
    setSubmitting(true);
    setLastQuizMarks(0);
    const url = 'lesson/save-user-quiz-results';

    api.post<Result<number>>(url, {
      accountId: user?.accountId,
      locationId: user?.locationId,
      userId: user?.id,
      courseId: module?.courseId ?? 0,
      moduleId: module?.moduleId ?? 0,
      moduleCode: module?.code ?? '',
      quizAnswers: quizAnswers,
    })
      .then((r) => {
        if (r.data?.result) {
          setLastQuizMarks(r.data?.result);
        }
        toast.success("Submitted! Score at end of quiz.", { theme: "colored" });
      })
      .catch(() => { toast.error("Error", { theme: "colored" }) })
      .finally(() => { setSubmitting(false); });
  }

  return (
    <>
      <Divider horizontal>Quiz</Divider>

      <ExpandableSegment title="Help with the quiz?" content={
        <ol>
          <li>
            Watch all video segments before taking the quiz.
          </li>
          <li>
            Click the 'Show Quiz & Hide Video' button to reveal the questions.
          </li>
          <li>
            Make selections and click 'Submit Quiz'. Your score will appear at the bottom of the quiz.
          </li>
          <li>
            You can switch back to videos using "Hide Quiz & Show Video" button.
          </li>
        </ol>
      } />

      <Button onClick={(e, data) => { setShowQuiz(!showQuiz) }} primary>
        <Icon name="tasks" />
        {showQuiz ? "Hide Quiz & Show Video" : "Show Quiz & Hide Video"}</Button>
      {(user?.role === UserRole.Student || user?.role === UserRole.LocationAdmin) && lastQuizAttemptedDate && <>
        <br /><br />
        <label><Icon name="hand point right outline" />
          Quiz, last taken on: {formatDate(lastQuizAttemptedDate, true)} and score was: {lastQuizMarks} of {module?.quiz?.qs.length}.
        </label>
      </>}

      {completedFlag &&
        <span>
          <br />
          <Icon name="checkmark" color="green" />
          Your Quiz was successfully completed on:&nbsp;
          {formatDate(completedDate, true)}
        </span>
      }

      <br /><br />
      {showQuiz && <div>
        <h3>Take the Quiz</h3>
        <p className="txt-content">Please try to select the correct choice (option) from the list of options for each question.
        </p>
        <p className="txt-content">
          Once you have completed all the quiz questions,
          click the 'Submit Quiz' button to submit all your quiz answers.</p>
        <List divided relaxed selection size="large">
          {shuffledQuestions?.map((q, questionIndex) => {
           return (
              <List.Item key={"q_" + q.id}>
                <List.Content>
                  <br />
                  <List.Header style={{ fontSize: "20px", lineHeight: "1.5" }}>Q {questionIndex + 1}: {q.q}</List.Header>
                  <List.Description>
                    <List relaxed size="large">
                      {q.choices.map((choice, choiceIndex) => (
                        <List.Item key={`${q.id}_c${choice.choiceId}`} style={{ color: "black" }}>
                          <label>
                          {((user?.role ?? 1) === UserRole.Student || (user?.role ?? 1) === UserRole.LocationAdmin) &&
                            <input
                              type="radio"
                              value={`c${choice.choiceId}`}
                              name={`q${q.id}`}
                              checked={userAnswers[q.id] === choice.choiceId}
                              onChange={() => handleRadioChange(q.id, `${choice.choiceId}`)}
                              style={{ fontSize: q[`c${choice.choiceId}_sel`] ? "20px" : "18px" }}
                            />}
                            &nbsp;&nbsp;&nbsp;&nbsp;{choice.label}
                          </label>
                        </List.Item>
                      ))}
                    </List>
                    <br />
                  </List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
        <br /><br />
        {lastQuizMarks > 0 &&
          <>
            <Label size="large" style={{ lineHeight: "1.5" }}>Your latest Score for the module Quiz is <b>{lastQuizMarks}&nbsp;
              out of {module?.quiz?.qs.length}</b>. <br />
              You may retake if desired.
            </Label> <br /><br />
          </>
        }
        {module?.quiz && ((user?.role ?? 1) === UserRole.Student || (user?.role ?? 1) === UserRole.LocationAdmin) &&
          <Button primary size="huge"
            loading={submitting}
            disabled={submitting}
            onClick={(e) => {
              e.preventDefault();
              saveUserQuizResponse(true);
            }
            }>
            Submit Quiz</Button>}
        {module?.quiz && ((user?.role ?? 1) === UserRole.Student || (user?.role ?? 1) === UserRole.LocationAdmin) &&
          <Button size="huge"
            onClick={(e, data) => { setLastQuizMarks(0); setShowQuiz(!showQuiz) }}>Back to Videos</Button>}
      </div>}
    </>);
};