import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import LabelOutput from "../../app/components/label_output";
import { useAuth } from "../../app/context/auth_provider";
import { Location } from "../../models/account/location";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { DocumentTitle } from "../../app/components/document_title";
import { formatDate } from "../../shared/utils";

function LocationViewPage() {
    const navigate = useNavigate();
    const {user} = useAuth();
    const { locationId } = useParams();
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState<Location>(new Location(user?.accountId));
    //const [users, setUsers] = useState<UserInfo[]>([]);
    const locationIdInt = parseInt(locationId ?? '0');

    useEffect(() => {
      if (locationIdInt > 0){
        setLoading(true);
        const url = `account/${user?.accountId}/location/get/${locationIdInt}`;
        api.get<Result<any>>(url)
          .then((r) => {
            setLocation(r.data.result.location);
            //setUsers(r.data.result.users);
          })
          .catch((e) => { toast.error("Error", { theme: "colored" }) })
          .finally(() => setLoading(false));
      }
    },[locationId, locationIdInt, user?.accountId]);

    const LocationView = () => {
        return(
          <Form id="locationForm" className="ui form" size="big">
              <Form.Group widths="equal">
                <LabelOutput placeholder="Created Date" content={formatDate(location?.createdDate, true)} />
                <LabelOutput placeholder="Updated Date" content={formatDate(location?.updatedDate, true)} />
              </Form.Group>
              <Form.Group widths="equal">
              <Form.Checkbox  label="Active?" style={{fontSize:"18px"}} checked={location.active}
             disabled/>
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Account ID (read only)" content={user?.accountId} />
              <LabelOutput placeholder="Account Name (read only)" content={user?.accountName} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Id" content={location.id} />
              <LabelOutput placeholder="Name" content={location.name} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Title" content={location.title} />
              <LabelOutput placeholder="Postal Code" content={location.postalCode} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Max User Count" content={location.maxUserCount} />
              <LabelOutput placeholder="Created Date" content="" />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Description" content={location.description} />
            </Form.Group>

            <Button floated="right" type="button" content="Close" size="large"
            onClick={() => {navigate('/locations')}}/>
          </Form>
          )}

return(<>
      <DocumentTitle title={"Location View"} />
      <Button basic icon="arrow left" size="big" content="Back to Locations" onClick={() => {navigate('/locations')}}/>
      {!loading && <h2><Icon name='map pin'/> Location </h2>}
      {!loading && <LocationView/>}
    </>);
}

export default LocationViewPage