export const promo_list = [
    {
        title: "Re-Entry Help",
        link: "https://www.reentryhelp.com",
        text: "Free resources for you and your supporters!",
        bgColor: "#77838A",
        txtColor: "#FFFFFF",
        img: 'reentry.png'
    },
    {
        title: "Release Help",
        link: "https://www.releasehelp.uk",
        text: "Free resources for you and your supporters!",
        bgColor: "#77838A",
        txtColor: "#FFFFFF",
        img: 'release.png'
    },
    {
        title: "Open to Venture",
        link: "https://www.opentoventure.com",
        text: "Free resources to help start your business!",
        bgColor: "#273E47",
        txtColor: "#FFFFFF",
        img: 'open.png'
    },
    {
        title: "Responsive to Women",
        link: "https://www.responsivetowomen.com",
        text: "Free resources to help you and your supporters!",
        bgColor: "#EFE7DC",
        txtColor: "#593A39",
        img: 'responsive.png'
    },
    {
        title: "Prep to Parent",
        link: "https://www.preptoparent.com",
        text: "Free resources for you and your child's caregiver!",
        bgColor: "#645441",
        txtColor: "#FFFFFF",
        img: 'parent.png'
    }
]