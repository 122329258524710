import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Dropdown, Label } from 'semantic-ui-react';
import ConfirmButton from '../../app/components/confirm_button';
import { DocumentTitle } from '../../app/components/document_title';
import { useAuth } from '../../app/context/auth_provider';
import { Result } from '../../models/common/paged_result';
import { Course, CourseListOutputDto, CourseType } from '../../models/course/course';
import { api } from '../../shared/axios_wrapper';
import { getEnumText } from '../../shared/utils';
import AccountLocationSelectionPanel from '../common/account_location_sel';
import { LocationCourseDisplayOrderDto } from '../../models/account/location';

function LocationCourseListPage() {
  const { user } = useAuth();
  //const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CourseListOutputDto[]>([] as CourseListOutputDto[]);
  //const [totalRecords, setTotalRecords] = useState(0);
  const [newRecords, setNewRecords] = useState(0);
  const [locationId, setLocationId] = useState(0);
  const [courses, setCourses] = useState<Course[]>();
  const [courseValues, setCourseValues] = useState<any[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const handleLocationSelect = useCallback((locationId: number) => {
    setLocationId(locationId);
  }, []);

  useEffect(() => {
    if (locationId > 0) {
      const url = `account/${user?.accountId}/location/${locationId}/location-course-list`;
      api.get<Result<CourseListOutputDto[]>>(url)
        .then((r) => {
          setData(r.data.result);
          //setTotalRecords(r.data.result.length);
          //console.log(r.data.result);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [locationId, newRecords, user?.accountId]);

  useEffect(() => {
    if (user) {
      api.get<Result<Course[]>>('course/drop-down-list')
        .then((r) => {
          setCourses(r.data.result);
          const transformedList = (r.data.result.map(item => ({
            key: item.id,
            value: item.id,
            text: item.name
          })));
          setCourseValues(transformedList);
        })
        .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [user]);

  const includeLocationCourse = async (courseIds: number[] | undefined) => {
    if (!courseIds) return;
    for (const courseId of courseIds) {
      const selectedCourse = courses?.filter(x => x.id === courseId)[0];
      if (!selectedCourse) continue;
      setSubmitting(true);
      const url = `account/${user?.accountId}/location/${locationId}/location-course-insert-or-update`;
      api.post(url, { courseId: selectedCourse.id, includeFlag: true, excludeFlag: false })
        .then((r) => {
          if (data) {
            setNewRecords(newRecords + 1);
          }
          toast.success("Course updated successfully.", { theme: "colored" });
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => { setSubmitting(false); setSelectedCourses([]); });
    }
  }

  const excludeLocationCourse = async (courseId: number) => {
    if (!courseId) return;
    const selectedCourse = data?.filter(x => x.id === courseId)[0];
    if (!selectedCourse) return;
    setSubmitting(true);
    const url = `account/${user?.accountId}/location/${locationId}/location-course-insert-or-update`;
    api.post(url, { courseId: selectedCourse.id, includeFlag: false, excludeFlag: true })
      .then((r) => {
        if (data) {
          setNewRecords(newRecords + 1);
        }
        toast.success("Course updated successfully.", { theme: "colored" });
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setSubmitting(false));
  }

  const deleteLocationCourse = async (courseId: number) => {
    const selectedCourse = data?.filter(x => x.id === courseId)[0];
    if (!selectedCourse) return;
    setSubmitting(true);
    const url = `account/${user?.accountId}/location/${locationId}/location-course-delete/${courseId}`;
    api.del(url)
      .then((r) => {
        if (data) {
          setNewRecords(newRecords + 1);
        }
        toast.success("Course updated successfully.", { theme: "colored" });
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setSubmitting(false));
  }

  const changeDisplayOrder = async () => {
    const displayOrderUpdates: LocationCourseDisplayOrderDto[] = [];
    const displayOrderMap: { [courseId: string]: number } = {};

    for (const course of data) {
      const courseId = course.id;
      const displayOrder = course.displayOrder;
      if (displayOrder !== undefined && displayOrder !== null) {
        if (
          //displayOrder <= 0 ||
          //displayOrder > data.length ||
          displayOrderMap[courseId] !== undefined
        ) {
          toast.error("Invalid display order values.", { theme: "colored" });
          return;
        }

        displayOrderMap[courseId.toString()] = displayOrder;

        const displayOrderUpdate: LocationCourseDisplayOrderDto = {
          locationId: locationId ?? 0,
          courseId: courseId,
          displayOrder: displayOrder,
        };

        displayOrderUpdates.push(displayOrderUpdate);
      }
    }

    if (displayOrderUpdates.length > 0) {
      setSubmitting(true);
      const url = 'location/change-course-display-order';

      try {
        await api.post(url, displayOrderUpdates);
        toast.success("Course display order changed successfully.", { theme: "colored" });
        window.location.reload();
      } catch (e) {
        toast.error("Error", { theme: "colored" });
      } finally {
        setSubmitting(false);
      }
    }
  }

  const handleDisplayOrderChange = (e: any, courseId: number) => {
    const newDisplayOrder = parseInt(e.target.value, 10) || 0;
    const newData = data.map((course) => ({
      ...course,
      displayOrder: course.id === courseId ? newDisplayOrder : course.displayOrder,
    }));

    setData(newData);
  };

  return (
    <>
      <DocumentTitle title="Location Courses" />
      <AccountLocationSelectionPanel locationId={locationId} onLocationSelect={handleLocationSelect} />

      <h2>Location Courses {data?.length > 0 ? `(${data?.filter(x => x?.excludeFlag === null || x?.excludeFlag === false).length})` : ''}</h2>
      {!loading && <Card.Group size="large">
        {data?.map((c, index) => (
          <Card key={"ac_" + c.id}>
            {c.includeFlag === true && <Label color='blue' corner='right' icon='plus' />}
            {c.excludeFlag === true && <Label color='red' corner='right' icon='minus' />}
            <Card.Content>
              <Card.Header><a href={"/courses/" + c.id}>{c.courseName}</a>
              </Card.Header>
              <Card.Meta>
                {getEnumText(CourseType, c.courseType)} | Modules: {c.moduleCount}
              </Card.Meta>
              <Card.Description>
                {c.shortDescription}
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="right">
            {c.includeFlag === true &&
            <input type="number" maxLength={2}
                style={{ width: "70px", fontSize: "24px", marginRight: "4px"}}
                value={data.find((course) => course.id === c.id)?.displayOrder || ''}
                onChange={(e) => handleDisplayOrderChange(e, c.id)}
                disabled={c.locationCourse === 0}
              />}

              {(c.includeFlag === null || c.includeFlag === false)&& (c.excludeFlag === null || c.excludeFlag === false) &&
              <ConfirmButton id={c.id} value={"Exclude"} confirmButtonText={"Exclude"} color={"red"}
                content={"Change Location Course - " + c.courseName + "?"} iconName="minus" floated="right"
                handleConfirm={excludeLocationCourse} />}

              {c.includeFlag === true &&
              <ConfirmButton id={c.id} value={"Remove"} confirmButtonText={"Remove"} color={"red"}
                content={"Change Location Course - " + c.courseName + "?"} iconName="delete" floated="right"
                handleConfirm={deleteLocationCourse} />}

               {c.excludeFlag === true && <ConfirmButton id={c.id} value={"Include"} confirmButtonText={"Include"} color={"blue"}
                content={"Change Location Course - " + c.courseName + "?"} iconName="add" floated="right"
                handleConfirm={deleteLocationCourse} />}
            </Card.Content>
            <Card.Content>
            {c.includeFlag === true && <span>This course is added at location level. <br/>This is not present at account level</span>}
            {c.excludeFlag === true && <span>This course is excluded at location level. <br/>This is present at account level</span>}
            {(c.excludeFlag === null || c.excludeFlag === false) && (c.includeFlag === null || c.includeFlag === false) && <span>This course is derived from account. <br/>This can be excluded at location level</span>}

            </Card.Content>
          </Card>
        ))}
      </Card.Group> }

      <br />
      {!loading && data?.length > 0 && <Button
        loading={submitting}
        primary
        icon='ordered list'
        type="submit"
        size="large"
        disabled={submitting}
        content="Set Location Course Display Orders"
        onClick={(e) => changeDisplayOrder()}
      />}

      {locationId > 0 &&
        <>
          <h4>Include New Course to Location</h4>
          <Dropdown
            placeholder='Course'
            fluid
            multiple
            search
            selection
            options={courseValues.filter(item1 => !data?.some(item2 => item2.id === item1.key))}
            value={selectedCourses}
            onChange={(e, data) => { setSelectedCourses(data.value as number[]); }}
          /> <br />
          <Button primary icon="add" size="large" content="Add"
            floated="right"
            disabled={submitting}
            onClick={() => { includeLocationCourse(selectedCourses); }} />
          <br /><br /><br />
        </>
      }
    </>
  );

}
export default LocationCourseListPage;
