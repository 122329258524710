import React, { useState, useEffect } from "react";
import { useAuth } from "../../app/context/auth_provider";
import { FileData } from "../../models/account/account";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { toast } from "react-toastify";
import { List, Table, Segment, Icon, Grid } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";

interface FolderData {
  name: string;
  subFolders: string[];
}

function DataFileExplorerPage() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [dataFolders, setDataFolders] = useState<FolderData[]>([]);
  const [dataFiles, setDataFiles] = useState<FileData[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [selectedSubFolder, setSelectedSubFolder] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId ?? 0}/s3_folder-list`;
    api
      .get<Result<string[]>>(url)
      .then((r) => {
        if (r.data?.result && Array.isArray(r.data.result)) {
          const topLevelFolders: FolderData[] = [];
          const secondLevelFolders: { [key: string]: string[] } = {};

          r.data.result.forEach(folder => {
            const [topLevel, secondLevel] = folder.split("/");
            if (secondLevel) {
              if (!secondLevelFolders[topLevel]) {
                secondLevelFolders[topLevel] = [];
              }
              secondLevelFolders[topLevel].push(secondLevel);
            } else {
              topLevelFolders.push({ name: topLevel, subFolders: [] });
            }
          });

          topLevelFolders.forEach(folder => {
            if (secondLevelFolders[folder.name]) {
              folder.subFolders = secondLevelFolders[folder.name];
            }
          });

          setDataFolders(topLevelFolders);
        } else {
          toast.error("Error: Unexpected data format", { theme: "colored" });
        }
      })
      .catch((e) => {
        toast.error("Error", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  }, [user]);


  useEffect(() => {
    setDataFiles([]);
    if (!selectedFolder) return;

    setLoading(true);
    const url = `account/${user?.accountId ?? 0}/${selectedFolder}/${selectedSubFolder}/s3_file-list`;
    api
      .get<Result<FileData[]>>(url)
      .then((r) => {
        if (r.data?.result) {
          setDataFiles(r.data.result);
        }
      })
      .catch((e) => {
        toast.error("Error", { theme: "colored" });
      })
      .finally(() => setLoading(false));
  }, [user, selectedFolder, selectedSubFolder]);

  const handleFolderClick = (folder: string) => {
    setSelectedFolder(folder);
    setSelectedSubFolder(null);
  };

  const handleSubFolderClick = (subFolder: string) => {
    setSelectedSubFolder(subFolder);
  };

  return (
    <>
      <DocumentTitle title={"Connect Resources"} />
      {!loading && <h2><Icon name='archive'/>Connect Resources</h2>}

      <div style={{ fontSize: "20px", lineHeight: "1.5" }}>
        If you'd like to contribute, please email suggestions (with desired category) to&nbsp;
        <a href="mailto:resources@acceleronlearning.com?subject=Resources">resources@acceleronlearning.com</a>.
        In addition to files, you may also provide hyperlinks to Google Docs, Youtube Videos etc.
      </div>
      <br /><br />
      <Grid columns={2} stackable>
        <Grid.Row>
        <Grid.Column width={6} verticalAlign="bottom">
        <div style={{ fontSize: "18px"}}><b>Browse Categories</b></div>
        </Grid.Column>
        <Grid.Column width={10}>
        {!loading && <div style={{ fontSize: "20px", lineHeight: "1.5", color: "#bd632f" }}>
            <b>{selectedFolder}/{selectedSubFolder} ({dataFiles?.length}).</b>
            <br/>
            Click an item to open / download.
            </div>
          }
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column width={6}>

          <Segment loading={loading}>
            <List divided relaxed style={{ fontSize: "18px", lineHeight: "1.5" }}>
              {dataFolders.map((folderData, index) => (
                <List.Item key={index}>
                  <List.Icon
                    name={selectedFolder === folderData.name ? "folder open" : "folder"}
                    onClick={() => handleFolderClick(folderData.name)}
                    style={{ cursor: "pointer" }}
                  />
                  <List.Content>
                    <List.Header
                      onClick={() => handleFolderClick(folderData.name)}
                      style={{ cursor: "pointer", fontWeight: selectedFolder === folderData.name ? "bold" : "normal" }}
                    >
                      {folderData.name}
                    </List.Header>
                    {selectedFolder === folderData.name && (
                     <List.List>
                     {folderData?.subFolders?.map((subFolder, subIndex) => (
                       <List.Item
                         key={`${index}_${subIndex}`}
                         onClick={() => handleSubFolderClick(subFolder)}
                         style={{ cursor: "pointer", fontWeight: selectedSubFolder === subFolder ? "bold" : "normal" }}
                       >
                         <List.Icon
                          name={selectedSubFolder === subFolder ? "folder open" : "folder"}
                          onClick={() => handleSubFolderClick(subFolder)}
                          style={{ cursor: "pointer" }}
                        />
                         <List.Content>
                           <List.Header>{subFolder}</List.Header>
                         </List.Content>
                       </List.Item>
                     ))}
                   </List.List>
                    )}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Grid.Column>

        <Grid.Column width={10}>

          <Table  stackable celled selectable
          style={{ fontSize: "18px", lineHeight: "1.5" }}>
            <Table.Header>
              <Table.Row>
              <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
                <Table.HeaderCell>File</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {dataFiles.map((file, index) => (
                <Table.Row key={file.file}>
                  <Table.Cell textAlign="center">{index +1 }</Table.Cell>
                  <Table.Cell
                  style={{
                    overflowWrap: "break-word",
                  wordWrap:"break-all",
                  maxWidth: "200 !important",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap"
                  }}>
                    <a href={file.expiryS3Url} target='file'>{file.file ? file.file.replace(/_/g, ' ') : ''}</a>
                  </Table.Cell>
                  <Table.Cell textAlign="center">{file.fileType}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default DataFileExplorerPage;
