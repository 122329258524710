import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Icon, Input, Select, Table } from 'semantic-ui-react';
import { DocumentTitle } from '../../app/components/document_title';
import GridFooter from '../../app/components/grid_footer';
import { useDebounce } from '../../app/components/use_debounce';
import { PageParams, PagedResult } from '../../models/common/paged_result';
import { LearningModule, categoryListJson } from '../../models/course/learning_module';
import { api } from '../../shared/axios_wrapper';
import { getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../shared/utils';

function ModuleListPage() {
  const navigate = useNavigate();
  const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<LearningModule[]>([] as LearningModule[]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [category, setCategory] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const debounceCode = useDebounce(code, 500);
  const [name, setName] = useState<string>('');
  const debounceName = useDebounce(name, 500);
  const columnCount = 6;

  interface LearningModuleListDto extends PageParams {
    category?: string,
    code?: string,
    name?: string,
    download?:boolean
  }

  const initialState: LearningModuleListDto = {
    sortIndex: 'code',
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: parseInt(process.env.REACT_APP_API_PAGE_SIZE ?? "10")
  };

  const [params, setParams] = useState(initialState);

  const downloadParams = {
    category,
    code: '',
    name: '',
    sortIndex: initialState.sortIndex,
    sortOrder: initialState.sortOrder,
    pageIndex: 0,
    pageSize: 10000,
    download: true
  };

  useEffect(() => {
    params.category = category;
    params.code = debounceCode;
    params.name = debounceName;
    setParams(params);
    setLoading(true);
    const url = `module/list`;
    api.getWithInput<PagedResult<LearningModule[]>>(url, params)
      .then((r) => {
        setData(r.data.result);
        setTotalRecords(r.data.total);
        //console.log(data);
        setLoading(false);
      })
      .catch((e) => { toast.error("Error", {theme : "colored"}) })
      .finally(() => setLoading(false));
  }, [params, debounceCode, debounceName, category]);

  const handleSort = (clickedColumn: string) => {
    if (data.length === 0) return;
    setParams(handleGridSort(params, clickedColumn));
  }

  const gridChange = (updatedParams: PageParams) => {
    setParams(handleGridNavigation(params, updatedParams));
  }

  const isSorted = (params: PageParams, columnName: string, data: any) => {
    return params.sortIndex === columnName && data.length > 0 ? params.sortOrder : undefined
  }

  const handleSubmit = () => { handleSort("name");};

  return (
    <>
      <DocumentTitle title="Modules" />
      <h2>Modules
        <Button floated='right' icon="add" content="Add Module" onClick={() => navigate("/admin/modules/edit/0")}/>
      </h2>
      <Form onSubmit={handleSubmit}>
        <Grid stackable columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Select fluid
                defaultValue="All"
                options={categoryListJson}
                size="huge"
                closeOnChange
                onChange={(e, data) => {  setParams({ ...params, pageIndex: 0 }); setCategory(data?.value as string) }} />
            </Grid.Column>
            <Grid.Column>
              <Input name="Code"
                placeholder='Code'
                fluid
                maxLength={15}
                size='large'
                onChange={(e, data) => {  setParams({ ...params, pageIndex: 0 }); setCode(data.value); }} />
            </Grid.Column>
            <Grid.Column>
              <Input name="name"
                placeholder='Name '
                fluid
                maxLength={50}
                size='large'
                onChange={(e, data) => {  setParams({ ...params, pageIndex: 0 }); setName(data.value); }} />
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Button type="submit" primary
                disabled={loading}
                size='large'>
                Search
                {loading && <label> <Icon name='spinner' /></label>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Table stackable selectable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell style={{ width: "50px" }}></Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: 'pointer' }}
              onClick={() => handleSort("code")}
              sorted={isSorted(params, "name", data)}>Code</Table.HeaderCell>
            <Table.HeaderCell textAlign='left' style={{ cursor: 'pointer' }}
              onClick={() => handleSort("name")}
              sorted={isSorted(params, "name", data)}>Learning Module Name</Table.HeaderCell>
            <Table.HeaderCell style={{ cursor: 'pointer' }}
              onClick={() => handleSort("category")}
              sorted={isSorted(params, "category", data)}>Category</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{ cursor: 'pointer' }}
              onClick={() => handleSort("active")}
              sorted={isSorted(params, "active", data)}>Active</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Edit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell className='row_num'>{(params.pageIndex * params.pageSize) + index + 1}</Table.Cell>
              <Table.Cell textAlign='center'><a href={'/admin/modules/' + item.code}>{item.code}</a></Table.Cell>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{item.category}</Table.Cell>
              <Table.Cell textAlign='center'>{item.active ? "Yes" : "No"}</Table.Cell>
              <Table.Cell textAlign='center'><a href={'/admin/modules/edit/' + item.code}><Icon name="edit"/></a></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {!loading && data.length > 0 &&
          <GridFooter colSpan={showFullSize ? columnCount : 1}
            params={params}
            totalRecords={totalRecords}
            onChange={gridChange}
            downloadApiUrl='module/list'
            downloadFileName='learning_modules.csv'
            downloadParams={downloadParams}
          />
        }
      </Table>
    </>
  );

}
export default ModuleListPage;
