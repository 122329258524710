import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Container, Segment, Sidebar } from "semantic-ui-react";
import SessionTImeoutModal from "../components/session_timeout_modal";
import { useAuth } from "../context/auth_provider";
import NavBar from "./nav_bar/navbar";
import SideBar from "./nav_bar/sidebar";
import { WarningMessageBlock } from "../components/warning_msg";

export default function NavBarLayout() {

  const { logout } = useAuth();

  const getDefaultSidebarOn = () => {
    var json = localStorage.getItem('sidebarOn');
    if (json === null) {
      return true;
    }
    return JSON.parse(json)['sidebarOn'];;
  }
  const [sidebarOn, setSidebarOn] = useState(getDefaultSidebarOn());

  const getDefaultIconsOnly = () => {
    var json = localStorage.getItem('iconsOnly');
    if (json === null) {
      return true;
    }
    return JSON.parse(json)['iconsOnly'];;
  }
  const [iconsOnly, setIconsOnly] = useState(getDefaultIconsOnly());

  const showSidebar = () => {
    var updatedSidebarOn = !sidebarOn;
    setSidebarOn(updatedSidebarOn);
    localStorage.setItem('sidebarOn', JSON.stringify({ sidebarOn: updatedSidebarOn }));
  }

  const showExpandedSidebar = () => {
    var updatedIconsOnly = !iconsOnly;
    setIconsOnly(updatedIconsOnly);
    localStorage.setItem('iconsOnly', JSON.stringify({ iconsOnly: updatedIconsOnly }));
  }
  const logoutHandler = () => {
    logout();
  }

  //if (user && user!["user_id"] === 0) navigate(`/register`);

  return (
    <>
      <SessionTImeoutModal/>
      <NavBar sidebarOn={sidebarOn} showSidebar={showSidebar} logoutHandler={logoutHandler} />
      <Sidebar.Pushable style={{ transform: "none" }}>
        <SideBar sidebarOn={sidebarOn}
          iconsOnly={iconsOnly} showExpandedSidebar={showExpandedSidebar}
          logoutHandler={logoutHandler} />
        <Sidebar.Pusher style={{
          marginLeft: sidebarOn && iconsOnly ? "-15px"
            : sidebarOn && !iconsOnly ? "0px"
              : "0px",
          width: sidebarOn && iconsOnly ? "calc(100% - 45px)"
            : sidebarOn && !iconsOnly ? "calc(100% - 150px)"
              : "100%"
        }}>
          <Segment className="main-content-segment">
            <Container>
              <WarningMessageBlock/>
              <Outlet />
            </Container>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  )
}
