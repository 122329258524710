import { SyntheticEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Icon, Pagination } from 'semantic-ui-react';
import { PageParams } from '../../models/common/paged_result';
import { getFullSizeWidth } from '../../shared/utils';

interface Props {
    params: PageParams,
    totalRecords: number,
    pageIndexChange?: (change: PageParams) => void;
}
function GridPagination({ params, totalRecords, pageIndexChange }: Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const handlePageChange = (e: SyntheticEvent, data: any) => {
        //Pagination component starts with index 1
        data.activePage--;
        if (params.pageIndex !== data.activePage) {
            const paging = { ...params };
            paging.pageIndex = data.activePage;
            if (pageIndexChange)
                pageIndexChange(paging);
        }
    }

    return (
        <Pagination onPageChange={handlePageChange}
            boundaryRange={0}
            activePage={params.pageIndex + 1}
            totalPages={Math.ceil(totalRecords / params.pageSize) || 1}
            ellipsisItem={null}
            firstItem={showFullSize ? { content: <Icon name='angle double left' color='red'/>, icon: true } : null}
            lastItem={showFullSize ? { content: <Icon name='angle double right' color='red'/>, icon: true } : null}
            prevItem={{ content: <Icon name='angle left' color='red'/>, icon: true }}
            nextItem={{ content: <Icon name='angle right' color='red'/>, icon: true }}
        />
    )
}

export default GridPagination