import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { Grid, Table } from 'semantic-ui-react'
import { getFullSizeWidth } from '../../shared/utils';
import { PageParams } from '../../models/common/paged_result';
import RecordsPerPage from './records_per_page';
import GridPagination from './grid_pagination';
import DownloadCSV from './download_report';

interface Props {
    colSpan: number,
    params: PageParams,
    totalRecords: number,
    onChange?: (change: PageParams) => void;
    downloadApiUrl?: string;
    downloadParams?: Record<string, any>;
    downloadFileName?: string;
}

function GridFooter({ colSpan, params, totalRecords, onChange, downloadApiUrl, downloadParams, downloadFileName } : Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const from = ((params.pageIndex * params.pageSize) + 1).toLocaleString();
    const to = ((params.pageIndex + 1) * params.pageSize).toLocaleString();
    const total = totalRecords?.toLocaleString();
    return (
        <Table.Footer fullWidth>
        <Table.Row>
            <Table.HeaderCell colSpan={colSpan}>
                <Grid stackable style={{border:"0px"}}>
                    <Grid.Row>
                        <Grid.Column textAlign='left' width={5} verticalAlign='middle'>
                            {totalRecords && totalRecords > params.pageSize &&
                            <label>Showing {from} to {to} of {total} records</label>}
                            {totalRecords && totalRecords <= params.pageSize &&
                            <label>Showing all ({total}) records</label>}
                        </Grid.Column>
                        <Grid.Column textAlign='right'  width={11}>
                            {totalRecords > 5 && <RecordsPerPage params={params} pageSizeChange={onChange} />}
                            {showFullSize ? <>&nbsp;</> : <><br /><br /></>}
                            {totalRecords > params.pageSize && <GridPagination pageIndexChange={onChange} params={params} totalRecords={totalRecords ?? 0} />}
                            &nbsp;
                            {downloadApiUrl && <DownloadCSV
                                    url= {downloadApiUrl}
                                    params = {downloadParams}
                                    fileName = {downloadFileName}
                                    />}
                        </Grid.Column>
                    </Grid.Row>
                 </Grid>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
    )
}

export default GridFooter