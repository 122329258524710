import { useNavigate } from "react-router-dom";
import { Button, Card, Icon } from "semantic-ui-react";
import { useAuth } from "../../../app/context/auth_provider";
import { Location } from "../../../models/account/location";
import { UserRole } from "../../../models/user/user";

/*
enum UserModuleStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2
}


interface ModuleDto{
  module: LearningModule;
  lastAccessedDate: Date;
  status: UserModuleStatus;
}*/

interface props{
    locationList: Location[]
}
export const LocationListView = ({locationList}: props) => {
   const {user, locationSwitch} = useAuth();
   const navigate = useNavigate();
    return(
      <>
       <h3><Icon name='map pin' /> Locations {locationList?.length > 0 ? `(${locationList?.length})` : ''}
       {user?.role === UserRole.SuperAdmin &&
       <Button floated='right' icon="add" content="Add Location" onClick={() => navigate("/admin/locations/edit/0")}/>}
       </h3>
       <br/><br/>

       <Card.Group size="large" stackable centered>
       {locationList?.map((item, index) => (
           <Card key={"lc_" + item.id}>
           <Card.Content>
             <Card.Header><a href={"/locations/" + item.id}>{item.name}</a></Card.Header>
             <Card.Meta>Active: {(item.active === true) ? "YES" : "NO"} | Post Code: {item.postalCode} |
             User Count: {item.userCount} </Card.Meta>
             <Card.Description>
               {item.description}
             </Card.Description>
           </Card.Content>
           <Card.Content extra textAlign="center">
              <Button basic primary icon='at' content='View' onClick={(e,data) => navigate('/locations/' + item.id)}/>
              {user?.role === UserRole.SuperAdmin &&
                <Button basic icon='edit' content='Edit' onClick={(e,data) => navigate('/admin/locations/edit/' + item.id)}/>}
           </Card.Content>
           <Card.Content>
           {(user?.role === UserRole.SuperAdmin || user?.role === UserRole.AccountAdmin) &&
                <Button icon='hand point right outline' content='&nbsp;Default Location'
                color={user?.locationId === item.id ? "blue" : "grey"}
                onClick={(e,data) =>  locationSwitch(item.id, item.name) }/>}
           </Card.Content>
         </Card>
          ))}
        </Card.Group>
     </>
    );
}
