import * as yup from 'yup';

export enum UserRole {
  Unknown = 0,
  Student = 1,
  LocationAdmin = 2,
  AccountAdmin = 3,
  SuperAdmin = 4
}

// Define the shape of the user object
export interface User {
  id: number;
  displayName: string;
  role: UserRole;
  accountId: number;
  accountName: string;
  locationId: number;
  locationName: string;
  dataCdn: string;
}

export class User implements User {

};

//user can be at the account level or at the location level
export class UserInfo {
  id: number;
  accountId: number;
  accountName?: string;
  locationId?: number;
  locationName?: string;
  roleId: UserRole;
  firstName: string;
  lastName: string;
  login: string;
  screenName?: string;
  email?: string;
  affiliation?: string;
  active: boolean;
  lockedOut: boolean;
  resetPwd?: boolean;
  lastLoginDate?: Date;
  expDate?: Date;
  notes?: string;
  pin?: string;
  createdDate?: Date;
  createdBy?: string;
  updatedDate?: Date;
  updatedBy?: string;

  constructor(input?: UserInfo) {
    if (input) {
      this.id = input.id;
      this.accountId = input.accountId;
      this.accountName = input.accountName;
      this.locationId = input.locationId;
      this.locationName = input.locationName;
      this.email = input.email ?? '';
      this.affiliation = input.affiliation ?? '';
      this.active = input.active;
      this.roleId = input.roleId;
      this.firstName = input.firstName ?? '';
      this.lastName = input.lastName ?? '';
      this.login = input.login ?? '';
      this.createdDate = input.createdDate;
      this.updatedDate = input.updatedDate;
      this.notes = input.notes ?? '';
      this.lastLoginDate = input.lastLoginDate ?? undefined;
      this.expDate = input.expDate ?? undefined;
      this.affiliation = input.affiliation ?? '';
      this.notes = input.notes ?? '';
      this.pin = input.pin ?? "";
      this.lockedOut = input.lockedOut;
    }
    else {
      this.id = 0;
      this.active = true;
      this.lockedOut = false;
      this.accountId = 0;
      this.roleId = UserRole.Student;
      this.firstName = '';
      this.lastName = '';
      this.login = '';
      this.notes = '';
      this.affiliation = '';
      this.pin = '';
    }
  }
}

export const UserInfoSchema = yup.object().shape({
  id: yup.number().min(0),
  accountId: yup.number().min(1).required(),
  locationId: yup.number().min(1).required(),
  firstName: yup.string().min(2).max(64).required()
    .test('first-name-no-special-chars', 'First Name cannot contain special characters or numbers', (value) => {
      return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
    }),
  lastName: yup.string().min(2).max(64).required()
    .test('last-name-no-special-chars', 'Last Name cannot contain special characters or numbers', (value) => {
      return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
    }),
  login: yup.string().min(6).max(64).required()
    .test('login-no-special-chars', 'Login may ONLY contain upper/lower case, @, hyphen, underscore, or period', (value) => {
      return /^[A-Za-z0-9@_.-]+$/.test(value || '');
    }),
  screenName: yup.string().min(2).max(64),
  email: yup.string().min(0).max(124),
  roleId: yup.number().min(1).max(4).required(),
  notes: yup.string().min(0).max(1024),
  active: yup.boolean().required(),
  lockedOut: yup.boolean().required(),
  affiliation: yup.string().min(0).max(124),
  resetPwd: yup.boolean(),
  expDate: yup.date().required(),
  lastLoginDate: yup.date(),
  pin: yup.number().min(1000).max(9999).integer().optional().nullable().default(null)
});


export interface UserDTO extends UserInfo {
  enablePinToResetPwd: boolean;
}


export interface UserListOutDTO {
  accountId?: number;
  accountName: string;
  locationId?: number;
  locationName?: string;
  postalCode?: string;
  userId: number;
  userName: string;
  login: string;
  active: boolean;
  roleId: number;
  userRole: UserRole;
  resetPwd: boolean;
  lastLoginDate: Date;
  expDate: Date;
  affiliation?: string;
}

export const roleListJson = [{
  key: "1",
  value: UserRole.Student,
  text: "Student"
},
{
  key: "2",
  value: UserRole.LocationAdmin,
  text: "Location Admin"
},
{
  key: "3",
  value: UserRole.AccountAdmin,
  text: "Account Admin"
},
{
  key: "4",
  value: UserRole.SuperAdmin,
  text: "Super Admin"
},];