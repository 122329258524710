import { Button, List } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useNavigate } from "react-router-dom";
import AccountLocationSelectionPanel from "../common/account_location_sel";
import { useCallback, useState } from "react";
import { api } from "../../shared/axios_wrapper";
import { useAuth } from "../../app/context/auth_provider";
import { toast } from "react-toastify";
import ConfirmButton from "../../app/components/confirm_button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, formatDate } from "../../shared/utils";

export const UserBulkActionPage = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [locationId, setLocationId] = useState<number>(0);
    const [locationName, setLocationName] = useState<string>("");

    const [submitting, setSubmitting] = useState(false);
    const [expDate, setExpDate] = useState<Date>(new Date());
    const [isActive, setIsActive] = useState(true);

    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (e: any) => {
        setIsOpen(!isOpen);
        setExpDate(e);
    };
    const handleClick = (e:any) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleLocationSelect = useCallback((locationId: number, locationName?: string) => {
        setLocationId(locationId);
        console.log(locationId);
        console.log(locationName);
        setLocationName(locationName ?? "");
    }, []);

    const saveBulkUserExpDate = async (id: number) => {
        setSubmitting(true);
        const url = `account/${user?.accountId}/location/${locationId}/bulk-update-user-exp-date`;
        api.post(url, { expDate: expDate })
            .then((r) => {
                toast.success("Bulk Action Exp Date is updated successfully.", { theme: "colored" });
                navigate("/location/users");
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setSubmitting(false));
    }

    const saveBulkUserActive = async (id: number) => {
         setSubmitting(true);
        const url = `account/${user?.accountId}/location/${locationId}/bulk-update-user-active`;
        api.post(url, { active: isActive })
            .then((r) => {
                toast.success("Bulk Action Active is updated successfully.", { theme: "colored" });
                navigate("/location/users");
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setSubmitting(false));
    }

    return (<>
        <DocumentTitle title="Bulk User Action" />
        <Button basic icon="arrow left" size="big" content="Back to Users" onClick={() => { navigate('/location/users') }} />

        <h2>Bulk User Action</h2>

        <AccountLocationSelectionPanel locationId={locationId} onLocationSelect={handleLocationSelect} />
        <br/><br/>
        <p className="txt-content">
        <b>Note</b>: Bulk Actions - Changes made to the expiry date or user status (active/inactive) will be applied to
        all users within the selected location. Please exercise caution when
        applying these changes due to their significant impact.
        </p>
        <List divided relaxed>
            <List.Item>
                <br />
                <List.Icon name='users' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header>Bulk User Action - Update Exp. Date (mm-dd-yyyy)</List.Header>
                    <List.Description>
                        <br />
                        <Button color='blue' onClick={handleClick} size="large">
                            {formatDate(expDate)}
                        </Button> <br/> (Click to select date)
                        {isOpen && (
                            <DatePicker selected={expDate}
                            minDate={addDays(new Date(),0)}
                            maxDate={addDays(new Date(), 365 * 5)}
                            onChange={handleDateChange} inline />
                        )}
                        <br /><br />
                        <ConfirmButton
                            disabled={locationId === 0}
                            id={0} value={" Update User Exp Date"} confirmButtonText={"Update All Users Exp Date"} color={"red"}
                            content={`Do you want to apply exp date (${expDate.toLocaleDateString()})
                            to all the users of the selected location (${locationName})?`}
                            iconName="users"
                            handleConfirm={saveBulkUserExpDate} /> <br /><br />
                    </List.Description>
                </List.Content>
                <br />
            </List.Item>
            <List.Item>
                <br />
                <List.Icon name='users' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header>Bulk User Action - User Status</List.Header>
                    <List.Description>
                        <br />
                        <label className="txt-content">
                            <input type='checkbox'
                            checked={isActive}
                            style={{ zoom: "1.5" }}
                            onChange={(event) => setIsActive(event.target.checked)} />&nbsp;&nbsp; Active
                        </label>
                        <br /><br />
                        <ConfirmButton
                            disabled={locationId === 0 || submitting}
                            id={0} value={" User Status Update"} confirmButtonText={"Update All Users Status"} color={"red"}
                            content={`Do you want to apply status (active: ${isActive}) to all the
                            users of the selected location (${locationName})?`} iconName="users"
                            handleConfirm={saveBulkUserActive} />
                    </List.Description>
                </List.Content>
                <br />
            </List.Item>
        </List>
    </>);
}