import React from 'react';
import { Divider, Icon, List } from 'semantic-ui-react';
import { CourseModuleViewDto, VideoData } from '../../../models/course/learning_module';

interface Props {
  module: CourseModuleViewDto | undefined;
}

export const VideoList: React.FC<Props> = ({ module }) => {
  if (!module?.videos) return <></>;

  const resolutionCount = Object.values(module.videos).reduce(
    (count, video) => count + video.resolutions.length,
    0
  );

  return (
    <>
      <Divider horizontal>Video List {resolutionCount > 0 ? "(" + resolutionCount + ")" : ""}</Divider>
      <List selection relaxed size="large">
        {Object.values(module.videos)?.map((video: VideoData, index) => (
          video.resolutions.map((resolution, resolutionIndex) => (
            <List.Item key={`video_${index}_${resolutionIndex}`} style={{ color: 'black' }}>
              <br />
              <Icon name="file video" />
              <List.Content>
                {index + 1}. {resolution.name} ({resolution.resolution})
              </List.Content>
              <br />
            </List.Item>
          ))
        ))}
      </List>
    </>
  );
};
