import { Form, Segment } from "semantic-ui-react";

interface Props {
    placeholder: string;
    width?: number;
    icon?: any;
    iconPosition?: "left" | undefined;
    content?: any;
    hideTitle?: boolean;
    className?: any;
    fluid? : boolean;
    size?: "mini" | "small" | "large" | "big" | "huge" | undefined
}

function LabelOutput(props: Props) {
    const {hideTitle} = props;
     return (
        <Form.Field>
            {!hideTitle && <label>{props.placeholder}</label>}
        <Segment style={{backgroundColor: "lightgrey"}}>{props.content} &nbsp;</Segment>
        </Form.Field>
    )
}
export default LabelOutput;