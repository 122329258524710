import { useEffect } from "react";

interface DocumentTitleProps {
    title: string;
}

export function DocumentTitle({title}: DocumentTitleProps){
    useEffect(() => {
        document.title = title + " - " + process.env.REACT_APP_TITLE
      }, [title]);

      return null;
}