import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../app/components/loading';
import { useAuth } from '../../app/context/auth_provider';

function LogoutPage() {
  const {logout} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {logout(); navigate('login');}, [logout, navigate]);

  return (<Loading content=''/>);
};

export default LogoutPage;
