import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import SelectInput from "../../app/components/selected_input";
import TextArea from "../../app/components/text_area";
import TextInput from "../../app/components/text_input";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { CourseModuleViewDto, LearningModule, LearningModuleSchema, VideoPlayerType, categoryListJson, videoPlayerTypeJson } from "../../models/course/learning_module";
import { UserRole } from "../../models/user/user";
import { api } from "../../shared/axios_wrapper";
import { QuizForm } from "./components/quiz_form";
import { ReadingMaterial } from "./components/reading_materials";
import { VideoList } from "./components/video_list";
import { DocumentTitle } from "../../app/components/document_title";

function ModuleEditPage() {
  const navigate = useNavigate();
  const {user} = useAuth();
  const { moduleCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [module, setModule] = useState<CourseModuleViewDto>();
  const [editModule, setEditModule] = useState<LearningModule>(new LearningModule());
  const [submitting, setSubmitting] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);

  useEffect(() => {
    if (moduleCode && moduleCode.trim() !== ''){
      setLoading(true);
      const params = { moduleCode: moduleCode.trim() };
      const url = 'module/get';
      api.getWithInput<Result<CourseModuleViewDto>>(url, params)
        .then((r) => {
          //console.log(r.data);
          if (r.data?.result)
            setModule(r.data.result);
        })
        .catch((e) => {console.log(e); toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [moduleCode]);

  useEffect(() => {
    try {
      if (module) {
        var editModule = new LearningModule();
        editModule.id = module.moduleId;
        editModule.code = module.code;
        editModule.name = module.name;
        editModule.shortDescription = module.shortDescription ?? '';
        editModule.description = module.description ?? '';
        editModule.active = module.active;
        editModule.category = module.category;
        editModule.assessmentQuestion = module.assessmentQuestion ?? '';
        editModule.mediaOneCode = module.mediaOneCode ?? '';
        editModule.mediaOneDuration = module.mediaOneDuration ?? '';
        editModule.mediaTwoCode = module.mediaTwoCode ?? '';
        editModule.mediaTwoDuration = module.mediaTwoDuration ?? '';
        editModule.videoPlayerType = module.videoPlayerType  ?? VideoPlayerType.JWPlayer;
        editModule.videoUrl = module.videoUrl ?? '';
        editModule.quizFlag = module.quizFlag;

        setEditModule(editModule);
      }
  }
  catch(e) {console.log(e);}
  }, [module]);

  const saveModule= async (input: LearningModule) => {
      setSubmitting(true);
      const url = 'module/save'
      api.post(url, input ?? {})
      .then((r) => {
        toast.success("Module saved successfully.",  { theme: "colored" });
        navigate("/admin/modules");
      })
      .catch((e) => {
        console.log(e);
        ///toast.error("Error", { theme: "colored" })
      })
      .finally(() => setSubmitting(false));
  }

  const ModuleForm = () => {

    return(
      <Formik
      initialValues={editModule}
      validationSchema={LearningModuleSchema}
      onSubmit={(values) => {saveModule(values)}}>
      {({ dirty, isValid, handleSubmit, errors,setFieldValue  }) => (
        <Form id="moduleForm" className="ui form" onSubmit={() => {handleSubmit();}} size="big">
           <Form.Group widths="equal">
            <SelectInput
                options={[
                  { key: 'yes', text: 'Yes', value: true },
                  { key: 'no', text: 'No', value: false }
                ]}
                placeholder="Active?" name="active" />
          </Form.Group>
          <Form.Group widths="equal">
            <TextInput placeholder="Id (read only)" name="id" readOnly/>
            <TextInput placeholder="Code" name="code" showRequired/>
          </Form.Group>
          <Form.Group widths="equal">
            <TextInput placeholder="Module Name" name="name" showRequired/>
            <SelectInput
                options={categoryListJson.filter(x => x.key !== "0")} placeholder="category" name="category" showRequired
                />
          </Form.Group>
          <Form.Group widths="equal">
            <TextInput placeholder="Media One Code" name="mediaOneCode"/>
            <TextInput placeholder="Media One Duration (Min:Sec)" name="mediaOneDuration"/>
          </Form.Group>
          <Form.Group widths="equal">
          <TextInput placeholder="Media Two Code" name="mediaTwoCode"/>
            <TextInput placeholder="Media Two Duration (Min:Sec)" name="mediaTwoDuration"/>
          </Form.Group>
          <Form.Group widths="equal">
            <TextArea placeholder="Short Desc" name="shortDescription" rows={2}/>
          </Form.Group>
          <Form.Group widths="equal">
            <TextArea placeholder="Desc" name="description" rows={3}/>
          </Form.Group>
          <Form.Group widths="equal">
          <SelectInput
                    options={videoPlayerTypeJson}
                    placeholder="Video Player Type" name="videoPlayerType" />
          </Form.Group>
          <Form.Group widths="equal">
          <TextArea placeholder="Vimeo / YouTube Video URL" name="videoUrl" rows={3}/>
          </Form.Group>
          <Form.Group widths="equal">
            <SelectInput
                    options={[
                      { key: 'yes', text: 'Yes', value: true },
                      { key: 'no', text: 'No', value: false }
                    ]}
                    placeholder="Has Quiz?" name="quizFlag" />
          </Form.Group>
          <Button primary floated="right" type="submit" size="large"
          loading={submitting}
          icon='save outline'
          content="Save Module"/>
          <Button floated="right" type="button" content="Cancel" size="large"
          onClick={() => {navigate('/admin/modules')}}/>
        </Form>
      )}
    </Formik>);
};

  return (
    <>
        <DocumentTitle title={"Module Edit"} />
       <Button basic onClick={(e,data) => {
        if (user?.role === UserRole.SuperAdmin) navigate('/admin/modules')}}>
          <Icon name="arrow left" size="big"/> Back</Button>

      {!loading && editModule && <h2><Icon name='video'/> Module: {module?.name}</h2>}

      {!loading && editModule && <ModuleForm/>}
      <br/><br/>
      {!loading && module && !showQuiz && <VideoList module={module} />}

      {!loading && !showQuiz && <ReadingMaterial module={module}/>}

      {!loading && <QuizForm module={module} showQuiz={showQuiz} setShowQuiz={setShowQuiz} />}

    </>
  );
}
export default ModuleEditPage;