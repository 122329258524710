import { Button, Image, Modal } from 'semantic-ui-react'
import { useAuth } from '../../../app/context/auth_provider';
import { formatDate } from '../../../shared/utils';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { CertificateData } from '../../../models/report/report';
import jsPDF from 'jspdf';
//import PDF from 'react-to-pdf';
//import { useRef } from 'react';

interface CertModalProps {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  certificateData: CertificateData | undefined;
}
function CertificateModal({ showModal, setShowModal, certificateData }: CertModalProps) {
  const { user } = useAuth();
  //const targetRef = useRef();

  //console.log(certificateData);

  /*
  const onCapture = (id: string, certName: string) => {
    if (!id) return;
    const element = document.getElementById(id);

    if (element) {
      htmlToImage.toPng(element)
        .then(function (dataUrl) {
          saveAs(dataUrl, certName + '.png');
        });
    }
  } */


  const onCapture = (id: string, certName: string, format: 'png' | 'pdf') => {
    if (!id) return;
    const element = document.getElementById(id);

    if (element) {
      if (format === 'png') {
        htmlToImage.toPng(element)
          .then(function (dataUrl) {
            saveAs(dataUrl, certName + '.png');
          });
      } else if (format === 'pdf') {
        htmlToImage.toPng(element)
          .then(function (dataUrl) {
            const img = new window.Image();
            img.src = dataUrl;
            img.onload = function () {
              //const pdf = new jsPDF();
              const pdf = new jsPDF('l', 'mm','a4')
              const imgWidth = 280; // A4 width in mm
              const pageHeight = img.height * imgWidth / img.width;
              //let position = 0;

              //pdf.addImage(dataUrl, 'PNG', 0, position, imgWidth, pageHeight, '', 'FAST');
              pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, pageHeight, '', 'FAST');
              pdf.save(certName + '.pdf');
            };
          });
      }
    }
  };

  if (!certificateData) return (<></>);
  return (
    <Modal
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
      size='large'
    >
      <Modal.Header>Acceleron Course Completion Certificate</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <div id='divCert' style={{
            backgroundImage: "url('/assets/courses/cert_bg.png')",
            backgroundSize: "cover",
            height: "712px",
            width: "950px",
            paddingLeft: "100px"
          }}>
            <Image size='small' circular bordered
              src={'/assets/courses/p/' + certificateData.courseIcon} />
            <h1>{certificateData.courseName}</h1>
            <h2>Certificate of Completion</h2>
            <h1>{certificateData?.studentName}</h1>
            By: <h3>{user?.accountName}</h3> <br />
            At: <h3>{user?.locationName}</h3> <br />
            Certified Date: <h3>{formatDate(certificateData.certifiedOn)}</h3>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>
          Close
        </Button>

         {/*
        <Button
          content="Download"
          labelPosition='right'
          icon='print'
          onClick={() => {
            onCapture('divCert',
              `${certificateData?.studentName}_${certificateData.courseName}`,
            );
            setShowModal(false);
          }}
          className='primary'
        />*/}


        <Button
          content="Download Pdf"
          labelPosition='right'
          icon='file pdf outline'
          onClick={() => {
            onCapture('divCert',
              `${certificateData?.studentName}_${certificateData.courseName}`,
            'pdf');
            setShowModal(false);
          }}
          className='primary'
        />
      </Modal.Actions>
    </Modal>
  )
}

export default CertificateModal;