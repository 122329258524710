import { useAuth } from "../../app/context/auth_provider";

export const SimpleTermsPage = () => {
    const { user } = useAuth();

    return (<>
        <h2>The Acceleron Group, Inc.</h2>
        <br/>
        {!user && <a href='/login' style={{paddingLeft: "10px",fontSize: "24px"}}>Back to Log In</a>}
        <br/>
        <h3>Terms of Use (Plain English Version)</h3>
        <p>This version of our Terms of Service (“TOS”) agreement is in plain English.  You should also read the full TOS agreement written in legal phrases our lawyer makes us use, but this version of our agreement will help you understand exactly what you’re agreeing to.</p>
        <p>You are responsible for reviewing the complete Terms of Service that may be <span style={{textDecoration: "underline"}}>
        {!user &&<strong><a href="/terms">found here</a></strong>}
        {user &&<strong><a href="/terms-of-use">found here</a></strong>}
        </span>.
        </p>
        <p>Reading this plain English version is not a substitute for reading the full TOS, but is meant to be a supplemental reading.</p>
        <p>You are responsible for abiding by the entire TOS. The full TOS applies to you whether or not you read it, if you continue to use this site.  If you do not understand this abbreviated TOS or the full TOS, seek the advice of a lawyer and do not use this site until you understand this agreement.</p>
        <p>This is a legally binding agreement between you, the user (person reading this), and us, The Acceleron Group INC (“TAGINC”). “Legally binding” means that if you don’t follow this agreement, we can pursue legal action against you and you can be held to the terms of this agreement.</p>
        <p>We (“TAGINC”) own this website and agree to let you use it if you follow the following rules and and agree to the following conditions.</p>
        <ol>
            <li>The license you are granted when you sign up for our services is not transferrable to anybody else unless we agree in a separate written agreement signed by both parties.</li>
            <li>You are not granted any commercial rights to this license unless we agree in a separate written agreement signed by both parties.</li>
            <li>Don’t use our website pretending to be someone else. We expect that when you register for our services, you are using truthful and accurate information about yourself, including your age and payment information.</li>
            <li>If you’re a child under the age of 13, you need your parent’s permission to use our website. If we don’t have evidence of your parent’s permission, we will cancel your account.</li>
            <li>If you are a parent who believes their child under the age of 13 has signed up for our services and you do not agree or consent, please email us immediately at <a href="mailto:info@acceleronlearning.com">info@acceleronlearning.com</a> and we will terminate your child’s account and delete any personal information they may have provided.</li>
            <li>Don’t use our websites, acceleronlearning.com and acceleronlearning.net for any illegal or pornographic activity.</li>
            <li>Do not attempt to hack or reverse engineer our websites to get to personal and private subscriber information.</li>
            <li>Do not use the information you find here to start a website that will compete with ours, or charge a fee to other users to access the information we present on our websites.</li>
            <li>Keep your password secure and confidential and let us know if you lose your password, or think that your password has been hacked, stolen, or compromised. We’ll get you a new on as soon as possible.</li>
            <li>Don’t steal our intellectual property, or intellectual property of a third party presented on our site.</li>
            <li>If you think we’ve stolen YOUR intellectual property, let us know as soon as possible what you think we’ve stolen and we will investigate.</li>
            <li>You can link to our websites as long as your link doesn’t interfere with our website operations and you’re not linking to us on your illegal or pornographic website.</li>
            <li>If you’re a user or sponsor administrator, you may only give out accounts to users who are covered under your licensing agreement with us.</li>
            <li>You agree that if we link to a third party from our website, we make no promises or endorsements that the information the third party provides is correct. You are bound by the third party’s TOS if you navigate to their website or use their services.</li>
            <li>By using our site, you are agreeing to participate in a legally binding arbitration process if a dispute between you and us arises. This arbitration process will take place in Denton County and will be subject to the laws of Texas. You agree that you will pay half of the arbitration fees.</li>
            <li>You agree to pay for our services and to use a form of payment that is legal and that will be honored by your bank or credit card company.</li>
            <li>You agree that you will not pursue us for loss, liability, claim, or expense that you bring upon yourself by violating the terms of this agreement or by using our website(s).</li>
            <li>You agree that we are not responsible for how you choose to use the information we provide. You agree that you will seek out your own independent career, financial, legal or medical advice as necessary.</li>
            <li>You agree that our websites are not guaranteed to be error free but that we do our best to ensure that accurate information is presented. It is your responsibility to confirm independently any information you act on before you act on it.</li>
            <li>If you are suspected of violating our agreement or using our services to violate the law, we will investigate and we will report any illegal activity to the appropriate authorities.</li>
        </ol>
        <p>If you violate these terms and conditions, we reserve the right to cancel your account at any time and without notice to you. We may terminate or modify this agreement at any time, with or without notice to you.</p>
        <p>How we use your information:</p>
        <ol>
            <li>The information you provide to us enables us to set up your account. We have made our best efforts to follow industry privacy standards to keep your information safe.</li>
            <li>We do not retain your credit card payment information. Your payment information is processed through a third-party vendor. You are bound by that vendor’s TOS when you agree to have your payment processed using their services.</li>
            <li>We do retain your payment information if you pay by check, certified check or money order, until your payment is accepted by our financial institution.</li>
            <li>We don’t sell your private information to third parties. The only instance where your information might be sold is if we sell our business to a new owner. They would get your contact information as a part of the sale.</li>
            <li>If you communicate an idea for our services to us, whether verbally or in writing, we own that idea and may use it without further compensation to you.</li>
        </ol>
        <p>This is not the full and complete Terms of Service. You are responsible for reviewing the complete Terms of Service that may be
            <span style={{textDecoration: "underline"}}>
            {!user &&<strong><a href="/terms">found here</a></strong>}
            {user &&<strong><a href="/terms-of-use">found here</a></strong>}
        </span>.</p>
        <br/><br/>
        {!user && <a href='/login' style={{paddingLeft: "10px",fontSize: "24px"}}>Back to Log In</a>}
        <br/><br/>

    </>);
}