import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon, Input } from 'semantic-ui-react';
import { DocumentTitle } from '../../app/components/document_title';
import { useAuth } from '../../app/context/auth_provider';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async () => {
    setIsSubmitting(true);
    const result = await auth.login(username, password);
    if (result.isValid) {
      const redirectUrl = new URLSearchParams(location.search).get('redirect');
      if (redirectUrl && redirectUrl.trim().toLowerCase() !== '/user/reset-pwd') {
        navigate(redirectUrl);
      } else {
        navigate('/home');
      }
    } else {
      console.log(result);
      toast.error("Login Failed", { theme: "colored" })
    }
    setIsSubmitting(false);
  };

  const isButtonDisabled = !username || !password || isSubmitting;

  return (
        <>
        <DocumentTitle title="Login" />

        <Form onSubmit={handleLogin} autoComplete='Off' >
          <center>
          <Grid centered className='auth-card' stackable>
          <Grid.Row>
              <Grid.Column textAlign='center'>
                <img src='/assets/logo_orange.png' alt="Logo" className="auth-logo" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" color="black" textAlign="center">
                  Acceleron Learning <br /> Log In
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Input name="login" fluid
                  placeholder='Login '
                  icon='user' iconPosition='left' maxLength={50}
                  size='huge' autoComplete='off'
                  onChange={(e) => setUsername(e.target.value)} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={13} style={{ paddingRight: "0px", marginRight: "0px" }}>
                      <Input name="pwd" fluid
                        placeholder='Password'
                        type={showPassword ? 'text' : 'password'}
                        icon='lock' iconPosition='left' maxLength={25}
                        size='huge' autoComplete='off'
                        onChange={(e) => setPassword(e.target.value)} />
                    </Grid.Column>
                    <Grid.Column width={2} style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                      <Button type='button' size='huge'
                        className='bg-white'
                        icon={showPassword ? 'eye slash' : 'eye'}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button type="submit" primary
                  disabled={isButtonDisabled}
                  size='huge' fluid>
                  Log In
                  {isSubmitting && <label> <Icon name='spinner' /></label>}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                Forgot login (username)? <br />
                Ask an admin at your location to retrieve it.
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                Forgot Password? <br />
                <Button
                  onClick={(e, data) => navigate('/user/reset-pwd')}>Reset My Password
                  {isSubmitting && <label> <Icon name='spinner' /></label>}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                Have a Registration Code? <br />
                <Button
                  onClick={(e, data) => navigate('/self-register')}>Register with Code
                  {isSubmitting && <label> <Icon name='spinner' /></label>}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                If you have any issues logging in,
                please contact <a href='mailto:support@acceleronlearning.com?subject=Login issues with Acceleron Learning'>support@acceleronlearning.com</a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <a href='/terms-plain'>Terms and Conditions</a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </center>
        </Form>
        </>
  );
};

export default LoginPage;
