import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { UserRole } from '../../../models/user/user';
import { useAuth } from '../../context/auth_provider';
import { MenuItems } from './menu_items';
import { useNavigate } from 'react-router-dom';

interface Props {
    sidebarOn: boolean,
    iconsOnly: boolean,
    showExpandedSidebar: () => void,
    logoutHandler: () => void
}

function SideBar({ sidebarOn, iconsOnly, showExpandedSidebar, logoutHandler }: Props) {
    const { user } = useAuth();
    const navigate = useNavigate();
    let selectedMenu = window.location.pathname.toLowerCase();
    if (selectedMenu === '') selectedMenu = 'home';
    const menusByRole = MenuItems.filter(x => x ? x?.role <= (user?.role ?? UserRole.Unknown) : []);

    const RenderSidebar = () => {
        return (
            <Sidebar as={Menu} animation="uncover"
                fluid
                width={iconsOnly ? 'very thin' : 'thin'}
                className={iconsOnly ? 'sidebar-menu-thin' : 'sidebar-menu'}
                visible={sidebarOn}
                icon="labeled" vertical inverted>
                {menusByRole && menusByRole.map((item, index) => {
                    if (item.key === '/location/data-files' && user?.dataCdn && user?.dataCdn?.trim() !== ''){
                        return (
                            <Menu.Item key={index} title={item?.title}
                            onClick={(e, data) => navigate(item?.path)}
                                className={
                                    (iconsOnly ? 'sidebar-menu-item-thin' : '') +
                                    (selectedMenu.startsWith(item?.key)? ' sidebar-menu-item-sel' : '')
                                }>
                                <i className={"icon large " + item?.icon}></i>
                                {!iconsOnly && item?.title}
                            </Menu.Item>
                        );
                    }
                    else if (item.key !== '/location/data-files'){
                    return (
                            <Menu.Item key={index} title={item?.title}
                            onClick={(e, data) => navigate(item?.path)}
                                className={
                                    (iconsOnly ? 'sidebar-menu-item-thin' : '') +
                                    (selectedMenu.startsWith(item?.key)? ' sidebar-menu-item-sel' : '')
                                }>
                                <i className={"icon large " + item?.icon}></i>
                                {!iconsOnly && item?.title}
                            </Menu.Item>
                        );
                    }
                    else{
                        return("");
                    }
                })}

                {user && user.role === UserRole.SuperAdmin && <Menu.Item title="Switch Account"
                    onClick={(e, data) => navigate('/switch')}
                    className={
                        (iconsOnly ? 'sidebar-menu-item-thin' : '') +
                        (selectedMenu.includes('/switch') ? ' sidebar-menu-item-sel' : '')
                    }>
                    <Icon name='building outline' size='large' />
                    {!iconsOnly && "Switch Account"}
                </Menu.Item>}

                <Menu.Item title="Help"
                    onClick={(e, data) => navigate('/help')}
                    className={
                        (iconsOnly ? 'sidebar-menu-item-thin' : '') +
                        (selectedMenu.includes('/help') ? ' sidebar-menu-item-sel' : '')
                    }>
                    <Icon name='help' size='large'/>
                    {!iconsOnly && "Help"}
                </Menu.Item>

                <Menu.Item  title="Log Out"
                    onClick={logoutHandler}
                    className={iconsOnly ? 'sidebar-menu-item-thin' : ''}>
                    <Icon name='power' size='large'/>
                    {!iconsOnly && "Log Out"}
                </Menu.Item>

                <Menu.Item onClick={showExpandedSidebar}
                    className={iconsOnly ? 'sidebar-menu-item-thin' : 'sidebar-menu-item'}>
                    <Icon name={iconsOnly ? 'angle double right' : 'angle double left'} size='large' />
                </Menu.Item>
            </Sidebar>
        );
    };

    return (
        <RenderSidebar />
    );
}

export default SideBar