import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useAuth } from "../../app/context/auth_provider";
import { AccountListView } from "../../models/account/account";
import { Result } from "../../models/common/paged_result";
import { api } from "../../shared/axios_wrapper";
import { UserRole } from "../../models/user/user";

function AccountSwitchPage() {
    const navigate = useNavigate();
    const {user, accountSwitch, locationSwitch} = useAuth();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<AccountListView[]>([] as AccountListView[]);

    useEffect(() => {
        if (user && user.role === UserRole.SuperAdmin){
          setLoading(true);
            const url = `account/list`;
            api.get<Result<AccountListView[]>>(url)
            .then((r) => {
                setData(r.data.result);
            })
            .catch((e) => { toast.error("Error", { theme: "colored" }) })
            .finally(() => setLoading(false));
        }
    }, [user]);

    return(<>
        <DocumentTitle title="Switch Account" />
        <h2>Accounts {data?.length > 0 ? `(${data?.length})` : ''}</h2>
        {!loading && <Card.Group size="large">
          {data?.map((a, index) => (
           <Card key={"ac_" + a.id}>
           <Card.Content>
             <Card.Header><a href={"/accounts/" + a.id}>{a.name}</a></Card.Header>
             <Card.Meta>Active: {(a.active === true) ? "YES" : "NO"}</Card.Meta>
             <Card.Description>
               {a.shortDescription}
             </Card.Description>
           </Card.Content>
           <Card.Content extra textAlign="center">
              <Button basic primary icon='at' content='Select' onClick={(e,data) =>
                { accountSwitch(a.id, a.name);
                  locationSwitch(a.defaultLocationId, a.defaultLocationName);
                  toast.success("Please select a location to continue...");
                  navigate('/locations');
                }}/>
           </Card.Content>
         </Card>
          ))}
        </Card.Group>}
    </>);

}
export default AccountSwitchPage;