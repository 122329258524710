import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import LabelOutput from "../../app/components/label_output";
import SelectInput from "../../app/components/selected_input";
import TextArea from "../../app/components/text_area";
import TextInput from "../../app/components/text_input";
import { useAuth } from "../../app/context/auth_provider";
import { Location } from "../../models/account/location";
import { Result } from "../../models/common/paged_result";
import { UserDTO, UserInfo, UserInfoSchema, UserRole, roleListJson } from "../../models/user/user";
import { api } from "../../shared/axios_wrapper";
import { DorpDownData } from "../../models/common/key_value";
import { DocumentTitle } from "../../app/components/document_title";
import { formatDate } from "../../shared/utils";

function UserEditPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState<UserInfo>(new UserInfo(undefined));
  const [enablePinToResetPwd, setEnablePinToResetPwd] = useState<boolean>(false);
  const userIdInt = parseInt(userId ?? '0');
  const [submitting, setSubmitting] = useState(false);
  const [locationJson, setLocationJson] = useState<DorpDownData[]>([]);

  useEffect(() => {
    if (userIdInt > 0) {
      setLoading(true);
      const url = `account/${user?.accountId}/user/get/${userIdInt}`;
      api.get<Result<UserDTO>>(url)
        .then((r) => {
          if (r.data?.result)
            //console.log(r.data.result);
            setEditUser((prevEditUser) => new UserInfo(r.data.result));
            setEnablePinToResetPwd(r.data?.result?.enablePinToResetPwd);
        })
        .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    } else {
      setEditUser((prevEditUser) => ({ ...prevEditUser, accountId: user?.accountId ?? 0 }));
    }
  }, [userId, userIdInt, user?.accountId]);

  useEffect(() => {
    if (userIdInt <= 0 && (user?.accountId ?? 0) > 0){
      setLoading(true);
      const url = `account/get/${user?.accountId}`;
      api.get<Result<any>>(url)
        .then((r) => {
          setEnablePinToResetPwd(r.data?.result?.account.enablePinToResetPwd);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  },[userId, userIdInt, user?.accountId]);

  useEffect(() => {
    const locationUrl = `account/${user?.accountId}/location/drop-down-list`;
    api.get<Result<Location[]>>(locationUrl)
      .then((r) => {
        const locJson : DorpDownData[] = r.data.result.map((loc) => {
          return {
              key: loc.id.toString(),
              value: loc.id,
              text: loc.name,
          };
      });
        setLocationJson(locJson);
      })
      .catch((e) => {toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [user?.accountId]);

  const saveUser = async (input: UserInfo) => {
    if ((user?.role ?? 1) < input.roleId){
      toast.error("You cannot update a supervisor user.", { theme: "colored" });
      return;
    }

    if (enablePinToResetPwd === true &&
      (!input.pin || input.pin?.trim() === "" || input.pin?.trim().length < 4)){
        toast.error("Please enter valid PIN (4-digit) to continue as the PIN is required for this account.", { theme: "colored" });
        return;
    }

    if (input.pin?.toString().trim() === "" || input.pin?.toString().trim() === "0"){
      input.pin = undefined;
    }

    //console.log(input);

    setSubmitting(true);
    const url = 'user/save'
    api.post(url, input ?? {})
      .then((r) => {
        toast.success("User saved successfully.", { theme: "colored" });
        navigate("/location/users");
      })
      .catch((e) => {
        console.log(e);
        //toast.error(e.message, { theme: "colored" })
      })
      .finally(() => setSubmitting(false));
  }

  const isUserNotExpired = () => {
    if (!editUser){
      return false;
    }

    if (!editUser.expDate){
      return true;
    }

    const currentDate = new Date();
    const expDate = new Date(editUser.expDate);
    return expDate >= currentDate;
  }

  const UserView = () => {
    return (
      <Formik
        initialValues={editUser}
        validationSchema={UserInfoSchema}
        onSubmit={(values) => {saveUser(values)}}>
        {({ dirty, isValid, handleSubmit }) => (
          <Form id="userForm" className="ui form" onSubmit={handleSubmit} size="big">
            <Form.Group widths="equal">
             <LabelOutput placeholder="Created Date" content={formatDate(editUser?.createdDate, true)} />
             <LabelOutput placeholder="Updated Date" content={formatDate(editUser?.updatedDate, true)} />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectInput
                      options={[
                      { key: 'yes', text: 'Yes', value: true },
                      { key: 'no', text: 'No', value: false }
                      ]}
                      placeholder="Active?" name="active" />

              <SelectInput
                      options={[
                      { key: 'yes', text: 'Yes', value: true },
                      { key: 'no', text: 'No', value: false }
                      ]}
                      placeholder="Locked Out?" name="lockedOut" />
            </Form.Group>
            <Form.Group widths="equal">
            <TextInput placeholder="Account Id (read only)" name="accountId" readOnly/>
              <LabelOutput placeholder="Account Name (read only)" content={user?.accountName} />
              <SelectInput
                options={locationJson.filter(x => x.key !== "0")} placeholder="Location (type or select to filter)" name="locationId" showRequired />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Id (read only)" name="id" readOnly />
              <TextInput placeholder="First Name" name="firstName" showRequired />
              <TextInput placeholder="Last Name" name="lastName" showRequired />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectInput
                options={roleListJson.filter(x => x.key !== "0" && x.value <= (user?.role ?? UserRole.Student))}
                placeholder="Role" name="roleId" showRequired />
              <TextInput placeholder="Login" name="login" showRequired maxLength={64}/>
              <TextInput placeholder="Affiliation" name="affiliation" />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Exp Date (yyyy-mm-dd)" name="expDate" />
              <LabelOutput placeholder="Last Login Date (yyyy-mm-dd) (read only)" content={formatDate(editUser.lastLoginDate, true)} />
            </Form.Group>
            <Form.Group widths="equal">
              <TextInput placeholder="Pin (4 digit number)" name="pin" maxLength={4}/>
            </Form.Group>
            <Form.Group widths="equal">
              <TextArea placeholder="Notes" name="notes" rows={3} />
            </Form.Group>
            <Button primary floated="right" type="submit" size="large"
              loading={submitting}
              icon='save outline'
              content="Save User" />

            {editUser?.active && isUserNotExpired() &&
            <Button floated="right" type="button" content="Change Pwd" size="large" secondary
              onClick={() => { navigate(`/user/reset-pwd/${editUser.login}/${editUser.lastName}/${editUser.pin ?? ''}`) }} />
            }
            <Button floated="right" type="button" content="Close" size="large"
              onClick={() => { navigate('/location/users') }} />
          </Form>
        )}
      </Formik>
    )
  }

  return (<>
    <DocumentTitle title={"User Edit"} />
    <Button basic icon="arrow left" size="big" content="Back to Users" onClick={() => { navigate('/location/users') }} />
    {!loading && <h2><Icon name='user outline' /> User </h2>}
    {!loading && <UserView />}
  </>);
}

export default UserEditPage