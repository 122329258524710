import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Form, Icon } from "semantic-ui-react";
import LabelOutput from "../../app/components/label_output";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { CourseModuleViewDto, LearningModule } from "../../models/course/learning_module";
import { UserRole } from "../../models/user/user";
import { api } from "../../shared/axios_wrapper";
import { QuizForm } from "./components/quiz_form";
import { ReadingMaterial } from "./components/reading_materials";
import { VideoList } from "./components/video_list";
import { DocumentTitle } from "../../app/components/document_title";

function ModuleViewPage() {
  const navigate = useNavigate();
  const {user} = useAuth();
  const { moduleCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [module, setModule] = useState<CourseModuleViewDto>();
  const [viewModule, setViewModule] = useState<LearningModule>(new LearningModule());
  const [showQuiz, setShowQuiz] = useState(false);

  useEffect(() => {
    setLoading(true);
    const params = { moduleCode: moduleCode };
    const url = 'module/get';
    api.getWithInput<Result<CourseModuleViewDto>>(url, params)
      .then((r) => {
        if (r.data?.result)
          setModule(r.data.result);
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [moduleCode]);

  useEffect(() => {
    if (module) {
      var vwModule = new LearningModule();
      vwModule.id = module.moduleId;
      vwModule.code = module.code;
      vwModule.name = module.name;
      vwModule.shortDescription = module.shortDescription ?? '';
      vwModule.description = module.description ?? '';
      vwModule.active = module.active;
      vwModule.category = module.category;
      vwModule.assessmentQuestion = module.assessmentQuestion ?? '';
      vwModule.mediaOneCode = module.mediaOneCode ?? '';
      vwModule.mediaOneDuration = module.mediaOneDuration ?? '';
      vwModule.mediaTwoCode = module.mediaTwoCode ?? '';
      vwModule.mediaTwoDuration = module.mediaTwoDuration ?? '';
      vwModule.videoPlayerType = module.videoPlayerType ?? 1;
      vwModule.videoUrl = module.videoUrl ?? '';
      vwModule.quizFlag = module.quizFlag;

      setViewModule(vwModule);
    }
  }, [module]);

  const ModuleForm = () => {
    return(

        <Form id="moduleForm" className="ui form" size="big">
           <Form.Group widths="equal">
            <Form.Checkbox  label="Active?" style={{fontSize:"18px"}} checked={viewModule.active} readOnly/>
          </Form.Group>
          <Form.Group widths="equal">
            <LabelOutput placeholder="Id (read only)" content={viewModule.id}/>
            <LabelOutput placeholder="Code"  content={viewModule.code}/>
          </Form.Group>
          <Form.Group widths="equal">
            <LabelOutput placeholder="Module Name" content={viewModule.name}/>
            <LabelOutput placeholder="Category" content={viewModule.category}/>
          </Form.Group>
          <Form.Group widths="equal">
            <LabelOutput placeholder="Media One Code" content={viewModule.mediaOneCode}/>
            <LabelOutput placeholder="Media One Duration (Min:Sec)" content={viewModule.mediaOneDuration}/>
          </Form.Group>
          <Form.Group widths="equal">
            <LabelOutput placeholder="Media Two Code" content={viewModule.mediaTwoCode}/>
            <LabelOutput placeholder="Media Two Duration (Min:Sec)" content={viewModule.mediaTwoDuration}/>
          </Form.Group>
          <Form.Group widths="equal">
          <LabelOutput placeholder="Short Desc" content={viewModule.shortDescription}/>
          </Form.Group>
          <Form.Group widths="equal">
          <LabelOutput placeholder="Desc" content={viewModule.description}/>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Checkbox  label="Has Quiz?" style={{fontSize:"18px"}} checked={viewModule.quizFlag} readOnly/>
          </Form.Group>
          <Form.Group widths="equal">
            <LabelOutput placeholder="Vimeo / YouTube Video URL" content={viewModule.videoUrl}/>
          </Form.Group>

          <Button floated="right" type="button" content="Close" size="large"
          onClick={() => {navigate('/admin/modules')}}/>
        </Form>
    );
}

  return (
    <>
      <DocumentTitle title={"Module View"} />
       <Button basic onClick={(e,data) => {
        if (user?.role === UserRole.SuperAdmin) navigate('/admin/modules')}}>
          <Icon name="arrow left" size="big"/> Back</Button>

      {!loading && viewModule && <h2><Icon name='video'/> Module: {module?.name}</h2>}

      {!loading && viewModule && <ModuleForm/>}
      <br/><br/>
      {!loading && module && !showQuiz && <VideoList module={module} />}

      {!loading && !showQuiz && <ReadingMaterial module={module}/>}

      {!loading && <QuizForm module={module} showQuiz={showQuiz} setShowQuiz={setShowQuiz} />}

    </>
  );
}
export default ModuleViewPage;