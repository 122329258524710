import React, { useState } from 'react'
import { Button, Confirm } from 'semantic-ui-react';

interface Props {
    id: number;
    color?: any;
    value: string;
    content: string;
    handleConfirm: (id: number) => void;
    compact?: any;
    floated?: any;
    basic?: boolean;
    iconName?: string;
    confirmButtonText?: string;
    stopPropagation?: boolean;
    disabled?: boolean;
    style?: any;
}

function ConfirmButton({ id, color, value, content, handleConfirm, compact, floated, basic, iconName, confirmButtonText,stopPropagation, disabled}: Props) {
    const [state, setState] = useState({ open: false, id: 0 });
    const handleCancel = (e:any) => {if (stopPropagation) {e.stopPropagation();} setState({ open: false, id: 0 })}
    const confirmAction = (e:any) => {
        if (stopPropagation) e.stopPropagation();
        handleConfirm(id);
        setState({ open: false, id: 0 });
    }
    return (
        <>
            <Button type='button' color={color ? color : 'red'}
                disabled={disabled ? disabled : false}
                onClick={(e) => {e.stopPropagation(); setState({ open: true, id: id })}}
                compact={compact} floated={floated}
                basic={basic}
                icon={typeof iconName != 'undefined' && iconName ? true : false }
            >
                {typeof iconName != 'undefined' && iconName && <i aria-hidden="true" className={'icon ' + iconName}></i>}
                {value}
            </Button>
            <Confirm header='Confirm Action' content={content} open={state.open} onCancel={handleCancel} onConfirm={confirmAction} size='mini' confirmButton={confirmButtonText ?? value} />
        </>
    )
}

export default ConfirmButton