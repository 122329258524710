import { useEffect, useState } from "react";
import { api } from "../../shared/axios_wrapper";
import { toast } from "react-toastify";
import { Button, Card, Divider, Grid, Image } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom';
import { Result } from "../../models/common/paged_result";
import { useAuth } from "../../app/context/auth_provider";
import { UserCourseOutputDto, } from "../../models/course/course";
import ProgressBar from "../../app/components/progress_bar";
import { DocumentTitle } from "../../app/components/document_title";
import CertificateModal from "./components/certificate_modal";
import { CertificateData } from "../../models/report/report";
import { UserRole } from "../../models/user/user";

function LessonCourseList() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState<UserCourseOutputDto[]>();
  const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateData>();
  const [showCert, setShowCert] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/courses`;
    api.get<Result<UserCourseOutputDto[]>>(url)
      .then((r) => {
        setCourses(r.data.result);
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [user?.accountId, user?.locationId]);

  const activeCourses: UserCourseOutputDto[] | undefined = courses?.filter(x => x.modulesCompleted > 0 &&
    x.moduleCount !== x.modulesCompleted);

  const remainingCourses: UserCourseOutputDto[] | undefined = courses?.filter(
      (x) => !activeCourses?.includes(x)
    );

  interface courseProps {
    courses: UserCourseOutputDto[] | undefined;
  }
  const CourseList = ({ courses }: courseProps) => {

    const showViewCertificate = (c: UserCourseOutputDto) => {
      if (user?.role !== UserRole.Student &&
        user?.role !== UserRole.LocationAdmin) return false;

      if ((c.moduleCount ?? 0) === 0) return false;
      if ((c.moduleCount ?? 0) - (c.modulesCompleted ?? 0) > 0) return false;
      return true;
    }

    const handleViewCertificate = (c: UserCourseOutputDto) => {
      if ((c.moduleCount ?? 0) === 0) return;
      if ((c.moduleCount ?? 0) - (c.modulesCompleted ?? 0) > 0) return;

      setSelectedCertificateData({
        accountName: user?.accountName ?? "",
        locationName: user?.locationName ?? "",
        courseName: c?.courseName ?? "",
        courseDescription: c?.shortDescription ?? "",
        courseIcon: c?.courseIcon ?? "",
        studentName: user?.displayName ?? "",
        certifiedOn: c.lastQuizAttemptedDate
      });
      setShowCert(true);
    }

    return (
      <center>
        <br />
        <Card.Group size="large" centered stackable>
          {courses?.map((c, index) => (

            <Card key={index}>
              <a href={"/lessons/courses/" + c.courseId}>
                <Image src={'/assets/courses/p/' + c.courseIcon ?? "ACN.png"} />
              </a>
              <Card.Content>
                <Card.Header><a href={"/lessons/courses/" + c.courseId}>{c.courseName}</a></Card.Header>
                <Card.Meta style={{textAlign:'left'}}>Total Modules: {c.moduleCount} <br />
                  Completed: {c.modulesCompleted ?? 0} | Pending: {((c.moduleCount ?? 0)- (c.modulesCompleted ?? 0))} <br />
                  {((user?.role ?? 0) === UserRole.Student || (user?.role ?? 0) === UserRole.LocationAdmin) &&
                  (c.moduleCount ?? 0 - c.courseCompleted ?? 0) > 0 &&
                    <ProgressBar bgColor={"green"} completed={((c.modulesCompleted ?? 0) / (c.moduleCount ?? 0)) * 100} />
                  }
                </Card.Meta>
                <Card.Description>
                  {c.shortDescription}
                </Card.Description>
              </Card.Content>
              <Card.Content extra textAlign="center">
                <Grid columns={2}>
                  <Grid.Column>
                    <Button basic primary icon='video' content='View Modules'
                      onClick={(e, data) => navigate("/lessons/courses/" + c.courseId)} />
                  </Grid.Column>
                  <Grid.Column>
                    <Button basic color='blue' icon='star outline' content='View Certificate'
                      disabled={!showViewCertificate(c)}
                      onClick={() => handleViewCertificate(c)} />
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </center>);
  }

  return (
    <>
      <DocumentTitle title={"Lessons"} />
      <h3>My Courses {courses && courses?.length > 0 ? "(" + courses?.length + ")" : ""}</h3>
      {(activeCourses?.length ?? 0) > 0 && <Divider horizontal content="In progress" />}
      {!loading && <CourseList courses={activeCourses} />}

      {(activeCourses?.length ?? 0) > 0 && <Divider horizontal content="All My Courses" style={{paddingTop: "20px"}}/>}

      {!loading && <CourseList courses={remainingCourses} />}

      {showCert && <CertificateModal
        certificateData={selectedCertificateData}
        showModal={showCert}
        setShowModal={setShowCert} />}
    </>
  );
}
export default LessonCourseList;