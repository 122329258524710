import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Icon, Message, Segment, Step } from "semantic-ui-react";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { UserCourseModuleOutputDto } from "../../models/course/learning_module";
import { api } from "../../shared/axios_wrapper";
import { formatDate } from "../../shared/utils";
import { QuizForm } from "../course/components/quiz_form";
import { ReadingMaterial } from "../course/components/reading_materials";
//import { VideoTabs } from "../course/components/video_player";
import { DocumentTitle } from "../../app/components/document_title";
import { MediaTrackingEvents } from "../../models/user/user_lesson_action";
import VideoPlayer from "../course/components/video_player";
import { CourseType } from "../../models/course/course";
import { useMediaQuery } from "react-responsive";
import { UserRole } from "../../models/user/user";

function LessonModuleView() {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isSmartphoneView = useMediaQuery({ query: '(max-width: 767px)' });
  const { courseId, moduleCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [module, setModule] = useState<UserCourseModuleOutputDto>();
  const [moduleCompleted, setModuleCompleted] = useState(false);
  //const [userQuiz, setUserQuiz] = useState<UserCourseModuleOutputDto>();
  //const [submitting, setSubmitting] = useState(false);

  const [showQuizMsg, setShowQuizMsg] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  //const [showVideoTab, setShowVideoTab] = useState(true);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/course/${courseId}/module/${moduleCode}`;
    api.get<Result<UserCourseModuleOutputDto>>(url)
      .then((r) => {
        if (r.data?.result){
          //console.log(r.data);
          //console.log(r.data.result.userModuleQuiz);
         setModule(r.data.result);
          setModuleCompleted(r.data.result.userModuleQuiz?.completedFlag ?? false);
          if (!r.data?.result?.module?.quiz ||
            r.data?.result?.module?.quiz.qs.length === 0){
              setShowQuiz(false);
            }
        }
      })
      .catch((e) => {toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [user?.accountId, user?.locationId, courseId, moduleCode]);

  const trackLessonUserAction = async (mediaTrackingEvents: MediaTrackingEvents) => {
    if (!module || module.module?.courseId <= 0 || module.module?.moduleId <= 0 ) return;
    if (user?.role !== UserRole.Student && user?.role !== UserRole.LocationAdmin) return;
    //setSubmitting(true);
    //console.log('here in trackLessonUserAction');
    //console.log(mediaTrackingEvents);
    const url = 'lesson/userAction'
    await api.post(url, {
                    accountId: user?.accountId,
                    locationId: user?.locationId,
                    userId: user?.id,
                    courseId: module.module.courseId,
                    moduleId: module.module?.moduleId,
                    mediaTrackingEvents: mediaTrackingEvents
              });
  }

  return (
    <>
      <DocumentTitle title={"Lesson Module View"} />
     {!loading && <Button basic onClick={(e,data) => {navigate(`/lessons/courses/${courseId}`)}}>
        <Icon name="arrow left" size="big"/> Back to Course</Button>}

      {!loading && module && <h2><Icon name='play circle outline'/> Module: {module.module?.name}</h2>}

      {!loading && <span>Course: {module?.module?.courseName}  &nbsp; | &nbsp;</span>}

      {!loading && <span>
        <Icon name="clock outline"/>
        Last accessed on:&nbsp;
        {formatDate(module?.userModuleQuiz?.lastModuleAccessedDate, true)}</span>
      }

      {!loading && module?.userModuleQuiz?.completedFlag &&   <span>&nbsp;&nbsp;|&nbsp;
        <Icon name="checkmark" color="green"/>
        Completed on:&nbsp;
        {formatDate(module?.userModuleQuiz?.completedDate, true)}</span>
      }

      {!loading && showQuizMsg && (module?.userModuleQuiz?.lastQuizMarks ?? 0) === 0 &&
      <Message success
          onDismiss={(e) => setShowQuizMsg(false)}
          header='Complete Quiz!'
          content="Congratulations! you have completed watching the videos for this module. Please proceed to the bottom of the page to find the quiz. Completion of the quiz will mark this module as completed."
        />}

      {!loading && module && <Segment size="large" basic className="txt-content">{module?.module?.description}</Segment>}

      {!loading && module && module.module && (moduleCompleted === true || isSmartphoneView === true) &&
       module?.module.courseType !== CourseType.e_Learning  &&
        module?.module.code !== 'WV009' && module?.module.code !== 'PS067' &&
      <>
      <Button primary
      content='Work on Tasks' size='large' style={{fontWeight: "bold"}} floated="right"
      onClick={(e,data) => {navigate(`/lessons/courses/${courseId}/modules/tasks/${moduleCode}`)}}/>
      <br/><br/>
      </>
      }

      {!loading && moduleCompleted === false && isSmartphoneView === false &&
       <Step.Group ordered widths={4}>
       <Step completed={moduleCompleted ?? false}>
         <Step.Content>
           <Step.Title>Watch / Listen</Step.Title>
           <Step.Description>One or More Parts</Step.Description>
         </Step.Content>
       </Step>

       <Step>
         <Step.Content>
           <Step.Title>Read</Step.Title>
           <Step.Description>Additional Material</Step.Description>
         </Step.Content>
       </Step>

       <Step completed={moduleCompleted ?? false}>
         <Step.Content>
           <Step.Title>Take Quiz</Step.Title>
           <Step.Description>When Ready</Step.Description>
         </Step.Content>
       </Step>
       {module && module.module && module?.module.courseType !== CourseType.e_Learning  &&
       module?.module.code !== 'WV009' && module?.module.code !== 'PS067' &&
       <Step completed={moduleCompleted ?? false}>
         <Step.Content>
           <Step.Title></Step.Title>
           <Step.Description>
            <Button primary
           content='Work on Tasks' size='large' style={{fontWeight: "bold"}}
           onClick={(e,data) => {navigate(`/lessons/courses/${courseId}/modules/tasks/${moduleCode}`)}}/>
           </Step.Description>
         </Step.Content>
       </Step>}
     </Step.Group>
      }

      {!loading && module && !showQuiz &&
      <VideoPlayer
        module={module}
        userRole={user?.role ?? 0}
        trackLessonUserAction={trackLessonUserAction}/>}

      {!loading && !moduleCompleted && (module?.module.quiz?.qs.length ?? 0) > 0 &&
        <Segment
        className="txt-content"
        content='After finishing the module videos, you are ready to proceed to the Quiz!'/>}

      <br/><br/>
      {!loading && !showQuiz &&
        <ReadingMaterial
          module={module?.module}
          trackLessonUserAction={trackLessonUserAction}/>}

      {!loading && module &&
        module?.module.quiz && module?.module?.quiz?.qs?.length > 0 &&
        <QuizForm
          module={module?.module}
          showQuiz={showQuiz}
          setShowQuiz={setShowQuiz}
          completedFlag={module?.userModuleQuiz?.completedFlag ?? false}
          completedDate={module?.userModuleQuiz?.completedDate}
          lastQuizScore={module?.userModuleQuiz?.lastQuizMarks}
          lastQuizAttemptedDate={module?.userModuleQuiz?.lastQuizAttemptedDate}/>
      }

      <br/><br/>
      {!loading && <Button basic onClick={(e,data) => {navigate(`/lessons/courses/${courseId}`)}}>
        <Icon name="arrow left" size="big"/> Back to Course</Button>}
     </>
  );
}
export default LessonModuleView;