import React from "react";
import { Icon, Label } from "semantic-ui-react";
import JWPlayer from "@jwplayer/jwplayer-react";
import AcceleronVideoPlayer from "./vimeo_player";
import { VideoPlayerType } from "../../../models/course/learning_module";
import ExpandableSegment from "../../../app/components/expandable_segment";
import { UserRole } from "../../../models/user/user";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };

    this.players = {};
    this.onBeforePlay = this.onBeforePlay.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.playerMountedCallback = this.playerMountedCallback.bind(this);
    this.playerUnmountingCallback = this.playerUnmountingCallback.bind(this);
    //this.loadPlayerLibrary();

    this.state = {
      player1: {},
      player2: {},
      isMobile: false,
      play1FirstTime: true,
      play2FirstTime: true,
      intervalIdPlayer1: null,
      intervalIdPlayer2: null,
      intervalInMin: 1,
      player1Msg: "",
      player2Msg: "",

      trackingEventJson: (props.module?.userModuleQuiz?.mediaTrackingEvents !== undefined &&
        props.module?.userModuleQuiz?.mediaTrackingEvents?.toString() !== "{}")
        ? JSON.parse(props.module?.userModuleQuiz?.mediaTrackingEvents) :  {
          transcriptViewedDate: null,
          v1TotalViewTime: 0,
          v1SeekedToEnd: false,
          v1LastSeekPos: 0,
          v1CompletedDate: null,
          v2TotalViewTime: 0,
          v2SeekedToEnd: false,
          v2LastSeekPos: 0,
          v2CompletedDate: null,
        },
      //totalVideoTime1: this.state.trackingEventJson?.v1TotalViewTime ?? 0,
      //totalVideoTime2: this.state.trackingEventJson?.v2TotalViewTime ?? 0,

      configDefaults: { key: process.env.REACT_APP_JWPLAYER_KEY, playbackRateControls: true },
      jwLibrary: "/assets/jwplayer/jwplayer-8.7.3/jwplayer.js"
    };

    this.setContainerStyle = this.setContainerStyle.bind(this);
    this.handleResize = this.handleResize.bind(this);

    //console.clear();

    /*
    if (this.shouldTrackEvents()) {
      //console.log('media tracking events 2:');
      console.log(this.state.trackingEventJson);
    }*/
  }

  componentDidMount() {
    this.handleResize(); // Call it once to set the initial state
    window.addEventListener("resize", this.handleResize);
    //console.log('in the componentDidMount event');
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.state.intervalIdPlayer1);
    clearInterval(this.state.intervalIdPlayer2);

    this.setState({ player1: null});
    this.setState({ player2: null });

    //console.log('in the componentWillUnmount event');
  }

  shouldTrackEvents() {
    if ((this.props.userRole === UserRole.Student || this.props.userRole === UserRole.LocationAdmin) &&
      this.props.module?.module?.quiz &&
      this.props.module?.module?.quiz?.qs?.length > 0 &&
      this.props.module?.module?.videoPlayerType === VideoPlayerType.JWPlayer) {
      //console.log('tracking is enabled');
      return true;
    }
    //console.log('no tracking needed');
    return false;
  }

  setContainerStyle(player, isPlaying, borderStyle) {
    if (!player) return;
    const container = player.getContainer();
    const mobileWidth = this.state.isMobile ? '100%' : '';
    const desktopWidth = this.state.isMobile ? '100%' : (isPlaying ? '100%' : '');

    container.style.border = borderStyle;
    container.style.width = this.state.isMobile ? mobileWidth : desktopWidth;

    if (isPlaying) {
      player.resize(680, 370);
    } else {
      player.resize(480, 270);
    }

    if (this.state.isMobile) {
      if (isPlaying) {
        player.resize(280, 170);
      } else {
        player.resize(280, 170);
      }
    }
  }

  handleResize() {
    var isPlaying1 = false;
    var isPlaying2 = false;

    if (this.state.player1 && this.state.player1.player) {
      isPlaying1 = this.state.player1.player.getState() === "playing";
    }
    if (this.state.player2 && this.state.player2.player) {
      isPlaying2 = this.state.player2.player.getState() === "playing";
    }

    const borderStyle = isPlaying1 || isPlaying2 ? "5px solid #8a1d1b" : "";

    const defaultIsMobile = window.innerWidth <= 768;
    this.setState({ isMobile: defaultIsMobile });

    // Move setContainerStyle inside the useEffect and pass necessary values as arguments
    this.setContainerStyle(this.state.player1?.player, isPlaying1, borderStyle);
    this.setContainerStyle(this.state.player2?.player, isPlaying2, borderStyle);
  }


  playerMountedCallback({ player, id }) {
    //console.log('mount id: ' + id);

    const newPlayer = { player };

    if (id === 'jwplayer-0') {
      this.setState({ player1: newPlayer });
    } else {
      this.setState({ player2: newPlayer });
    }
  }

  playerUnmountingCallback({ id }) {
    //console.log('unmount id: ' + id);
    if (id === 'jwplayer-0') {
      this.setState({ player1: null });
    } else {
      this.setState({ player2: null });
    }
  }

  onBeforePlay(event, index) {
    //console.log('inside before play');
    let otherPlayer = null;
    if (index === 0){
      otherPlayer = this.state.player2.player;
    }
    else{
      otherPlayer = this.state.player1.player;
    }

    if (otherPlayer) {
      const isPlaying = otherPlayer.getState() === "playing";
      if (isPlaying) {
        otherPlayer.pause();
      }
    }
  }

  setFirstTimeSeek(index) {
    //console.log('inside first time seek');
    //console.log(this.state);
    if (index === 0 &&
      this.state.player1 && this.state.player1.player &&
      this.state.play1FirstTime === true &&
      this.state.trackingEventJson?.v1LastSeekPos > 0) {
      //console.log('here inside....setFirstTimeSeek...video 1');
      this.state.player1?.player?.seek(this.state.trackingEventJson?.v1LastSeekPos);
      this.setState({play1FirstTime: false});
    }
    else if (index === 1 &&
      this.state.player2 && this.state.player2.player &&
      this.state.play2FirstTime === true &&
      this.state.trackingEventJson?.v2LastSeekPos > 0) {
      //console.log('here inside....setFirstTimeSeek...video 2');
      this.state.player2?.player?.seek(this.state.trackingEventJson?.v2LastSeekPos);
      this.setState({play2FirstTime: false});
    }
  }

  onPlay(event, index) {
    //console.log('here on play');
    let player = null;
    let otherPlayer = null;
    //let intervalId = 0;
    let otherIntervalId = 0;

    if (index === 0) {
      player = this.state.player1.player;
      otherPlayer = this.state.player2?.player;
      if (this.shouldTrackEvents() && player) {

        this.setFirstTimeSeek(index);

        const id = setInterval(() => {

          const newTotalVideoTime = this.state.trackingEventJson?.v1TotalViewTime + (this.state.intervalInMin * 60);

          // Assuming trackingEventJson is another state property
          const eventsJson = { ...this.state.trackingEventJson };
          eventsJson.v1TotalViewTime = newTotalVideoTime;
          eventsJson.v1LastSeekPos = player.getPosition();

          // Assuming trackLessonUserAction is a function passed through props
          this.props.trackLessonUserAction(eventsJson).then(() => console.log('Tracking API call for player 1 is done'));

          this.setState({trackingEventJson: eventsJson});
          //console.log(eventsJson);

        }, this.state.intervalInMin * 60 * 1000);

        this.setState({intervalIdPlayer1: id});
        //intervalId = id;
        otherIntervalId = this.state.intervalIdPlayer2;
      }
    }
    else {
      player = this.state.player2.player;
      otherPlayer = this.state.player1?.player;
      if (this.shouldTrackEvents() && player) {

        this.setFirstTimeSeek(index);

        const id = setInterval(() => {
          const newTotalVideoTime = this.state.trackingEventJson?.v2TotalViewTime + (this.state.intervalInMin * 60);

          // Assuming trackingEventJson is another state property
          const eventsJson = { ...this.state.trackingEventJson };
          eventsJson.v2TotalViewTime = newTotalVideoTime;
          eventsJson.v2LastSeekPos = player.getPosition();

          // Assuming trackLessonUserAction is a function passed through props
          this.props.trackLessonUserAction(eventsJson).then(() => console.log('Tracking API call for player 2 is done'));

          this.setState({trackingEventJson: eventsJson});

        }, this.state.intervalInMin * 60 * 1000);

        this.setState({intervalIdPlayer2: id});
        //intervalId = id;
        otherIntervalId = this.state.intervalIdPlayer1;
      }
    }

    if (!player) return;

    const container = player?.getContainer();
    const container2 = otherPlayer?.getContainer();

    if (player) {
      player.resize(480, 270);
    }

    if (otherPlayer) {
      otherPlayer.resize(480, 270);
      otherPlayer.pause();
      clearInterval(otherIntervalId);
    }

    if (player.getState() === "playing") {
      container.style.border = "5px solid #bd632f";
      if (this.state.isMobile) container.style.width = "100%";
      else player.resize(680, 370);

      if (otherPlayer && container2) {
        container2.style.border = "";
        if (this.state.isMobile) container2.style.width = "100%";
        else otherPlayer.resize(480, 270);
      }
    } else {
      container.style.border = "";
      if (this.state.isMobile) container.style.width = "100%";
      else player.resize(480, 270);

      if (otherPlayer && container2) {
        container2.style.border = "";
        if (this.state.isMobile) container2.style.width = "100%";
        else otherPlayer.resize(480, 270);
      }
    }
  }

  onPause(event, index) {
    let player = null;
    if (index === 0) {
      player = this.state.player1.player;
      clearInterval(this.state.intervalIdPlayer1);
    }
    else {
      player = this.state.player2.player;
      clearInterval(this.state.intervalIdPlayer2);
    }

    if (!player) return;

    const container = player.getContainer();
    if (player.getState() !== "playing") {
      if (this.state.isMobile) container.style.width = "100%";
      else player.resize(480, 270);
    }
  }

  convertDurationToSeconds(duration) {
    const [minutes, seconds] = duration?.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  onComplete(event, index) {
    //console.log('on Complete');
    this.setState({player1Msg: ''});
    this.setState({player2Msg: ''});

    if (!this.shouldTrackEvents() ||
      this.state.trackingEventJson[`v${index + 1}CompletedDate`] === null) {
        return;
    }

    try {
      const currentPlayer = index === 0 ? this.state.player1 :this.state.player2;

      const updatePlayer = (player) => {
        if (player && player.player) {
          const container = player.player.getContainer();
          container.style.border = "";
          if (this.state.isMobile) container.style.width = "100%";
          else player.player.resize(480, 270);
        }
      };

      updatePlayer(currentPlayer);

      if (this.shouldTrackEvents() === false) {
        return;
      }

      const key = `v${index + 1}`;

      if (this.state.trackingEventJson[`${key}CompletedDate`] !== null) {
        //console.log(trackingEventJson[`${key}CompletedDate`] + ' is done');
        return;
      }

      const mediaOneDurationInSeconds = this.convertDurationToSeconds(this.props.module?.module?.mediaOneDuration);
      const mediaTwoDurationInSeconds = this.convertDurationToSeconds(this.props.module?.module?.mediaTwoDuration);
      const completionThreshold = index === 0 ? 0.8 * mediaOneDurationInSeconds : 0.8 * mediaTwoDurationInSeconds;

      //console.log('completion threshold: ' + completionThreshold + ' v1 time: ' + totalVideoTime1 + ' v2 time: ' + totalVideoTime2);

      const isCompleted = this.state.trackingEventJson[`${key}CompletedDate`] === null &&
        (index === 0 ? this.state.trackingEventJson.v1TotalViewTime : this.state.trackingEventJson.v2TotalViewTime) >= completionThreshold;

      if (isCompleted) {
        const updateTrackingEventJson = () => {
          const updatedTrackingEventJson = { ...this.state.trackingEventJson };
          updatedTrackingEventJson[`${key}SeekedToEnd`] = true;
          updatedTrackingEventJson[`${key}CompletedDate`] = new Date().toLocaleDateString();
          //console.log(updatedTrackingEventJson[`${key}CompletedDate`]);

          //console.log('making the last API update call before reload');
          this.props.trackLessonUserAction(updatedTrackingEventJson).then(() => console.log('tracking API call for completion is done'));
          //trackingEventJsonRef.current = updatedTrackingEventJson;
          return updatedTrackingEventJson;
        };

        this.setState({trackingEventJson : updateTrackingEventJson()});
      }
      else {
        const message = `You need to complete at least 80% of the video and reach the end to mark it as complete.`;
        //console.log(message);
        index === 0 ?  this.setState({player1Msg: message}): this.setState({player2Msg: message});
      }
    } catch (e) {
      //console.log('from onComplete');
      console.error(e);
    }
  }


  getPlaylistItem(videoKey) {
    const video = this.props.module.module.videos[videoKey];

    const sources = video.resolutions.map((resolution) => ({
      file: resolution.videoUrl,
      label: resolution.resolution,
      default: resolution.resolution === "1280" ? true : false,
      type: "MP4"
    }));

    // Sort sources array based on resolution in ascending order
    sources.sort((a, b) => parseInt(a.label) - parseInt(b.label));

    const playlistItem = {
      title: videoKey,
      description: "",
      //image: "myImage.png",
      //mediaid: videoKey,
      id: videoKey,
      sources: sources,
    };

    console.log(playlistItem);
    return [playlistItem];
  }


  getResolutions(videoKey) {
    //console.log(this.props.module.module.videos[videoKey].resolutions);
    //console.log(this.props.module.module.videos[videoKey]);
    const smilUrl = this.props.module.module.videos[videoKey].smilUrl;
    const resolutions = this.props.module.module.videos[videoKey].resolutions.map((resolution) => {
      const parts = resolution.name.split('_');
      const label = parts.length > 1 ? parts[1] : resolution.name;

      return {
        file: resolution.videoUrl,
        label: label,
        default: resolution.resolution === "720" ? true : false
      };
    });

    resolutions.sort((a, b) => parseInt(a.label) - parseInt(b.label));

    const smil = {
      //label: `SMIL - ${smilUrl}`,
      file: smilUrl,
      type: "rtmp"
    };

    //console.log([smil, ...resolutions]);
    //console.log(smil);
    return  [smil, ...resolutions];
  }

  getImage(index) {
    const mediaTwoCode = this.props.module?.module?.mediaTwoCode;

    return mediaTwoCode && mediaTwoCode.toString().trim() !== ""
      ? `/assets/courses/v${index + 1}_cover.png`
      : "/assets/courses/v_cover.png";
  }

  formatSecondsToHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedTime = `${hours} Hrs:${String(minutes).padStart(2, '0')} Mins`;
    return formattedTime;
  }

  getWatchTime(index) {
    var watchTime = 0;
    //console.log(this.state.trackingEventJson);
    if (!this.state.trackingEventJson) return "";
    if (index === 0) {
      watchTime = this.state.trackingEventJson.v1TotalViewTime;
    } else {
      watchTime = this.state.trackingEventJson.v2TotalViewTime;
    }
    if (watchTime > 0){
      return `Completed watch/listen duration: ${this.formatSecondsToHMM(watchTime)}`;
    }

    return "";
  }

  getStatus(index) {
    var status = "not started yet";
    //console.log(this.props.module?.userModuleQuiz);
    if (this.props.module?.userModuleQuiz?.completedFlag){
      return "completed";
    }

    if (!this.state.trackingEventJson) return status;
    if (index === 0) {
      if (this.state.trackingEventJson?.v1CompletedDate) {
        status = "completed";
      } else if (this.state.trackingEventJson.v1TotalViewTime > 0) {
        status = `in progress`;
      }
    } else {
      if (this.state.trackingEventJson.v2CompletedDate) {
        status = "completed";
      } else if (this.state.trackingEventJson.v2TotalViewTime > 0) {
        status = "in progress";
      }
    }
    return `${status}`;
  }


  formatDuration(duration) {
    const [minutes, seconds] = duration.split(":");
    const formattedDuration = `${parseInt(minutes, 10)} Min : ${parseInt(seconds, 10)} Sec`;
    return formattedDuration;
  }

  render() {
    return(<>
      {/* this.shouldTrackEvents() && <Header as='div'>
        v1 Total View Time (in sec): {this.state.trackingEventJson?.v1TotalViewTime}<br />
        v1 Seeked To End (true/false):  {this.state.trackingEventJson?.v1SeekedToEnd}  <br />
        v1 Last Seek Pos  (number):  {this.state.trackingEventJson?.v1LastSeekPos} <br />
        v1 Completed Date (date):  {this.state.trackingEventJson?.v1CompletedDate} <br /> <br />
        v2 Total View Time  (in sec):  {this.state.trackingEventJson?.v2TotalViewTime} <br />
        v2 Seeked To End (true/false):  {this.state.trackingEventJson?.v2SeekedToEnd}  <br />
        v2 Last Seek Pos (number):  {this.state.trackingEventJson?.v2LastSeekPos}  <br />
        v2 Completed Date (date):  {this.state.trackingEventJson?.v2CompletedDate}  <br />
    </Header> */}

      {!this.props.module.module?.isAudio && (
        <ExpandableSegment title="How to play a video?" content={
          <ol>
            <li>
              <b>Play Videos:</b> Click on a video to start playback.
            </li>
            <li>
              <b>Pause Control:</b> Pause a video by clicking the pause icon or anywhere on the video.
            </li>
            <li>
              <b>Seek Video:</b> Drag the progress bar to ...
            </li>
            <li>
              <b>Full Screen:</b> Double-click to enter full-screen mode, and double-click again to exit.
            </li>
          </ol>
        } />
      )}
      <div className="players-container">
        {Object.keys(this.props.module.module?.videos || {}).map((videoKey, index) => (
          <div key={`div-${index}`}>
            <br/><br/>
            <h4>
              {this.props.module.module?.isAudio && (
                <span>
                  Audio <Icon name="headphones" />
                </span>
              )}
              {!this.props.module.module?.isAudio && "Video " + (index + 1)} - &nbsp;
              {index === 0 && this.props.module.module?.mediaOneDuration && (
                <span>
                  <Icon name="clock" />
                  {this.formatDuration(this.props.module?.module?.mediaOneDuration)} |&nbsp;
                  {this.props.module.module?.mediaOneCode} &nbsp;&nbsp;&nbsp;
                  {this.shouldTrackEvents() && <Label className="primary" tag>{this.getStatus(index)}</Label>}
                </span>
              )}
              {index === 0 && (this.state.trackingEventJson?.v1TotalViewTime ??  0) > 0 && (
                <div style={{paddingTop: "4px", paddingBottom: "4px"}}>
                  <Icon name="star" color='green'/>&nbsp; {this.getWatchTime(index)}
                </div>
              )}
              {index === 1 && this.props.module.module?.mediaTwoDuration && (
                <span>
                  {this.formatDuration(this.props.module?.module?.mediaTwoDuration)} | &nbsp;
                  {this.props.module.module?.mediaTwoCode} &nbsp;&nbsp;&nbsp;
                  {this.shouldTrackEvents() && <Label className="primary" tag>{this.getStatus(index)}</Label>}
                </span>
              )}
               {index === 1 && (this.state?.trackingEventJson?.v2TotalViewTime ?? 0) > 0 && (
                <div style={{paddingTop: "4px", paddingBottom: "4px"}}>
                  <Icon name="star" color='green'/>&nbsp; {this.getWatchTime(index)}
                </div>
              )}
              {this.props.module.module?.isAudio && (
                <div className="lesson-course">
                  <br />
                  This is an audio-only module. Press play to listen.
                </div>
              )}
            </h4>
            {this.props.module.module?.videoPlayerType === VideoPlayerType.JWPlayer && (
              <>
                {(index === 0) && (
                  // Render the JWPlayer for video 1 if video 1 is not completed
                  <>
                    <p>
                    {/*JSON.stringify(this.getResolutions(videoKey))*/}
                    </p>
                    <JWPlayer
                      key={`jwplayer-${index}`}
                      style={{ maxWidth: "100% !important" }}
                      aspectratio="15:9"
                      image={this.getImage(index)}
                      config={this.state.configDefaults}
                      onBeforePlay={(event) => this.onBeforePlay(event, index)}
                      onPlay={(event) =>  this.onPlay(event, index)}
                      onPause={(event) => this.onPause(event, index)}
                      onComplete={(event) =>  this.onComplete(event, index)}
                      didMountCallback={this.playerMountedCallback}
                      willUnmountCallback={this.playerUnmountingCallback}
                      sources={this.getResolutions(videoKey)}
                      library={this.state.jwLibrary}
                    />

                    {this.state.player1Msg && <div style={{ color: "red", paddingTop: "10px" }}>{this.state.player1Msg}</div>}
                  </>
                )}

                {(index === 1) && (
                  // Render the JWPlayer for video 2 if:
                  // - Video 1 is completed (v1CompletedDate) OR
                  // - Video 2 is completed (index === 1 and v2CompletedDate)
                  <>
                    <JWPlayer
                      key={`jwplayer-${index}`}
                      style={{ maxWidth: "100% !important" }}
                      aspectratio="15:9"
                      image={this.getImage(index)}
                      config={this.state.configDefaults}
                      onBeforePlay={(event) => this.onBeforePlay(event, index)}
                      onPlay={(event) =>  this.onPlay(event, index)}
                      onPause={(event) => this.onPause(event, index)}
                      onComplete={(event) => this.onComplete(event, index)}
                      didMountCallback={this.playerMountedCallback}
                      willUnmountCallback={this.playerUnmountingCallback}
                      sources={this.getResolutions(videoKey)}
                      library={this.state.jwLibrary}
                    />
                    {this.state.player2Msg && <div style={{ color: "red", paddingTop: "10px" }}>{this.state.player2Msg}</div>}
                  </>
                )}


              </>
            )}
          </div>
        ))}
      </div>
      <div>
        {(this.props.module?.module?.videoPlayerType === VideoPlayerType.Vimeo ||
          this.props.module.module?.videoPlayerType === VideoPlayerType.YouTube) &&
          this.props.module?.module?.videoUrl && (
            <>
              <div style={{
                textAlign: "left",
                width: this.state.isMobile ? "480px" : "680px"
              }}>
                <AcceleronVideoPlayer
                  url={this.props.module?.module?.videoUrl}
                />
              </div>
            </>
          )}
      </div>
      <br /><br />
    </>
    );
  }
}

export default VideoPlayer;
