import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Icon, Table, Image, Label, Segment, Grid, Step } from "semantic-ui-react";
import ProgressBar from "../../app/components/progress_bar";
import { Result } from "../../models/common/paged_result";
import { CourseType, ModuleDto, UserCourseOutputDto, UserModuleStatus } from "../../models/course/course";
import { api } from "../../shared/axios_wrapper";
import { formatDate } from "../../shared/utils";
import { promo_list } from "../common/promo_items";
import { DocumentTitle } from "../../app/components/document_title";
import CertificateModal from "./components/certificate_modal";
import { CertificateData } from "../../models/report/report";
import { useAuth } from "../../app/context/auth_provider";
import { UserRole } from "../../models/user/user";
import { useMediaQuery } from "react-responsive";

function LessonCourseView() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { user } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState<UserCourseOutputDto>();
  const [showAll, setShowAll] = useState(true);
  const [showDesc, setShowDesc] = useState(false);
  const [modules, setModules] = useState<ModuleDto[]>();
  const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateData>();
  const [showCert, setShowCert] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = `account/${user?.accountId}/location/${user?.locationId}/lesson/course/${courseId}`;
    api.get<Result<UserCourseOutputDto>>(url)
      .then((r) => {
        //console.log(r.data?.result);
        if (r.data?.result)
        {
          setCourse(r.data.result);
          setModules(r.data.result?.modules);
        }
      })
      .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
      .finally(() => setLoading(false));
  }, [user?.accountId, user?.locationId, courseId]);

  interface moduleProps {
    modules: ModuleDto[] | undefined;
  }

  const showViewCertificate = (c: UserCourseOutputDto | undefined) => {
    if ((c?.moduleCount ?? 0) === 0) return false;
    if ((c?.moduleCount ?? 0) - (c?.modulesCompleted ?? 0) > 0) return false;
  }

  const handleViewCertificate = (c: UserCourseOutputDto | undefined) => {
    if (user?.role !== UserRole.Student &&
      user?.role !== UserRole.LocationAdmin) return false;

    if ((c?.moduleCount ?? 0) === 0) return;
    if ((c?.moduleCount ?? 0) - (c?.modulesCompleted ?? 0) > 0) return;

    setSelectedCertificateData({
      accountName: user?.accountName ?? "",
      locationName: user?.locationName ?? "",
      courseName: c?.courseName ?? "",
      courseDescription: c?.shortDescription ?? "",
      courseIcon:  c?.courseIcon ?? "",
      studentName: user?.displayName ?? "",
      certifiedOn:  c?.courseCompletedDate
    });

    setShowCert(true);
  }

  const getStatusColor = (status : UserModuleStatus) => {
    if (status === UserModuleStatus.Completed) return "green";
    if (status === UserModuleStatus.InProgress) return "orange";
    if (status === UserModuleStatus.NotStarted) return "black";

    return "#bd632f";
  }

  const ModuleTable = ({ modules }: moduleProps) => {
    return (
      <>
        <Segment color='blue' className="txt-content">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8} style={{ lineHeight: "1.5" }}>
              Click module title or play icon to view.
              </Grid.Column>
              <Grid.Column width={8} textAlign="right" verticalAlign="middle">
              <label htmlFor='toggle_all_pending'>
              {showAll
                  ? `Showing All Modules (${course?.moduleCount ?? 0})`
                  : `Showing Pending Modules (${(course?.moduleCount ?? 0) - (course?.modulesCompleted ?? 0)})`}
                &nbsp;&nbsp;&nbsp;
                <input
                  type="checkbox"
                  id="toggle_all_pending"
                  name="toggle_all_pending"
                  checked={showAll}
                  onChange={(e) => {
                    setShowAll(e.target.checked);
                    if (e.target.checked) {
                      setModules(course?.modules);
                    } else {
                      setModules(course?.modules?.filter(x => x.status !== UserModuleStatus.Completed));
                    }
                  }}
                />
              </label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Table selectable columns={course?.courseType !== CourseType.e_Learning ? 5 : 4}
        stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
              <Table.HeaderCell textAlign="center"></Table.HeaderCell>
              <Table.HeaderCell>Module <br />
                <small>Show/Hide Description: &nbsp;&nbsp;&nbsp;
                <label htmlFor="toggle_show_desc">
                    <input
                      type="checkbox"
                      id="toggle_show_desc"
                      checked={showDesc}
                      onChange={() => setShowDesc(!showDesc)}
                    />
                  </label>
                </small>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Learn</Table.HeaderCell>
              {course?.courseType !== CourseType.e_Learning &&
              <Table.HeaderCell textAlign="center">Tasks</Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {modules?.map((m, index) => (
              <Table.Row key={"m_" + m.module?.id}>
                 <Table.Cell width={1} textAlign="center"><Label circular size="huge" color="black">{index + 1}</Label></Table.Cell>
                <Table.Cell width={1} textAlign="center">
                  {m.status === UserModuleStatus.NotStarted && <Icon name="hand point right outline" color='black' size='large'/>}
                  {m.status === UserModuleStatus.InProgress && <Icon name="clock outline" color='orange' size='large'/>}
                  {m.status === UserModuleStatus.Completed && <Icon name="check" color='green' size='large'/>}
                </Table.Cell>
                <Table.Cell width={7}>
                  <h4>
                    <a
                      style={{ textDecoration: "underline", fontSize: "18px" }}
                      href={'/lessons/courses/' + courseId?.trim() + '/modules/' + m.module.code}>{m.module.name}</a>
                  </h4>
                  {showDesc && <p className="txt-content">{m.module?.shortDescription}</p>}

                  {m.module.mediaOneDuration && <span><Icon name={m.module.isAudio ? "headphones": "video"}  /> #1 <Icon name="clock" /> {m.module.mediaOneDuration}&nbsp;|&nbsp;</span>}
                  {m.module.mediaTwoDuration && <span><Icon name="video"/> #2 <Icon name="clock" /> {m.module.mediaTwoDuration}&nbsp;|&nbsp;</span>}
                  {m.lastAccessedDate && <><span><Icon name="calendar" />Last Accessed On: {formatDate(m.lastAccessedDate, true)} </span>&nbsp;|&nbsp;</>}
                  {m.module.code}
                  {m.lastQuizAttemptedDate && <>
                    <div>
                        <Icon name="calendar" />Latest Quiz Taken On: {formatDate(m.lastQuizAttemptedDate, true)}&nbsp;|&nbsp;
                        Latest Grade: {m.lastQuizPercentage}%
                    </div></>}
                  {m.completedDate && <>
                    <div style={{backgroundColor: "yellow", display:"inline-block"}}>
                        <Icon name="calendar" />Quiz Passed On: {formatDate(m.completedDate, true)}&nbsp;|&nbsp;
                        Max Grade: {m.maxQuizPercentage}%&nbsp;|&nbsp;
                        Completed : {m.completedFlag ?? false ? "Yes" :"No" }
                    </div></>}

                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  <Icon name="play circle" size="huge"
                    style={{ cursor: "pointer", color: getStatusColor(m.status), fontSize: "32px" }}
                    onClick={() => navigate('/lessons/courses/' + courseId?.trim() + '/modules/' + m.module.code)}></Icon>
                  <br />
                  {m.status === UserModuleStatus.Completed && "Completed"}
                  {m.status === UserModuleStatus.NotStarted && "Not Started"}
                  {m.status === UserModuleStatus.InProgress && "In Progress"}
                </Table.Cell>
                {course?.courseType !== CourseType.e_Learning &&
                <Table.Cell textAlign="center" colSpan='3'>
                  {m.taskCount > 0 &&
                  <>
                  <Icon name="tasks" size="huge"
                    style={{ cursor: "pointer",
                            color: getStatusColor(m.status),
                            fontSize: "32px" }}
                    onClick={() => navigate('/lessons/courses/' + courseId?.trim() + '/modules/tasks/' + m.module.code)}></Icon>
                  <br />
                  {m.status === UserModuleStatus.Completed && "Completed"}
                  {m.status === UserModuleStatus.NotStarted && "Not Started"}
                  {m.status === UserModuleStatus.InProgress && "In Progress"}
                  </>
                  }
                </Table.Cell>
                }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>);
  }

  interface promoAdProps {
    promoJson?: string;
  }
  const PromoAd = ({ promoJson }: promoAdProps) => {
    if (!promoJson || promoJson.trim() === '') return (<></>);
    const promoItems = promo_list.filter(x => x ? x?.title === promoJson : []);
    if (!promoItems || promoItems.length === 0) return (<></>);
    const promoItem = promoItems[0];
    return (
      <Step.Group fluid>
        <Step>
          <Image src={'/assets/promo/' + promoItem.img} wrapped ui={false} />
          <Step.Content style={{ paddingLeft: "10px" }}>
            <span className="primary" style={{padding:"5px"}}>Helpful Resource</span>
            <br/><br/>
            <Step.Title>{promoItem.title}</Step.Title>
            <Step.Description className="txt-content">
            <br/>
              <div
                dangerouslySetInnerHTML={{
                  __html: promoItem.text.replace('{link}',
                    `the following website.`)
                }} />
                <br/>

                <Icon name='globe' />
                {promoItem.link}

            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  return (
    <>
      <DocumentTitle title={"Lesson View"} />
      {!loading && <Button basic onClick={(e, data) => { navigate(`/lessons/courses`) }}>
        <Icon name="arrow left" size="big" /> Back to All Courses</Button>}

      {!loading && <h2>Course: {course?.courseName}</h2>}

      Total Modules: {course?.moduleCount} <br />
      Completed: {course?.modulesCompleted ?? 0} | Pending: {((course?.moduleCount ?? 0) - (course?.modulesCompleted ?? 0))} <br />

      {((user?.role ?? 0) === UserRole.Student || (user?.role ?? 0) === UserRole.LocationAdmin) && (course?.moduleCount ?? 0 - (course?.modulesCompleted ?? 0)) > 0 &&
        <ProgressBar bgColor={"green"} completed={((course?.modulesCompleted ?? 0) / (course?.moduleCount ?? 0)) * 100} />}

      {!loading && (course?.description?.trim().length ?? 0) > 1 &&
      <p className="txt-content">{course?.description}</p>}

      {!loading && course && course?.courseType !== CourseType.e_Learning &&
        <Button basic color='blue'  icon='star outline' content='Certificate'
          disabled={!showViewCertificate(course)}
          onClick={() => handleViewCertificate(course)} />}

      {!loading && course && course?.courseType !== CourseType.e_Learning &&
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button color='blue'  icon='pencil alternate' content='Assessment'
        onClick={(e, data) => { navigate(`/lessons/courses/${courseId?.trim()}/modules/assessment`) }} />
        </>
      }
    {!loading  && course && course?.courseType !== CourseType.e_Learning &&
        <>
        {!loading && isMobile && <><br/><br/></>}
        <Button primary
        icon='calendar alternate outline'
        content='Timeline View' floated="right"
        onClick={(e, data) => { navigate(`/lessons/courses/${courseId}/timeline`) }} />
         {!loading && isMobile && <><br/><br/></>}
        </>
      }

      {showCert &&
      <CertificateModal
        certificateData={selectedCertificateData}
        showModal={showCert}
        setShowModal={setShowCert} />}

      {!loading && <ModuleTable modules={modules} />}

      {!loading && <PromoAd promoJson={course?.promoJson} />}

      {!loading && <Button basic onClick={(e, data) => { navigate(`/lessons/courses`) }}>
        <Icon name="arrow left" size="big" /> Back to All Courses</Button>}
    </>
  );
}
export default LessonCourseView;