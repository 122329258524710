import * as yup from 'yup';
//Location in an entity - can be a school, facility, etc. Account can have one or more locations
export class Location {
    id: number; //primary key - auto-increment
    accountId: number //not null foreign key account.id
    name: string;// //not null - unique account_id + name - string(128)
    code?: string; //not null - unique account_id + code - string(16)
    title?: string;   //nullable - string(128)
    description?: string; //nullable - string(1024)
    shortDescription?: string; //nullable - string(255)
    active: boolean; //not null - boolean - default - true
    maxUserCount?: number;
    userCount: number; //report column
    postalCode?: string; //nullable - string(15)
    createdDate?: Date; //nullable - defaults to today
    createdBy?: string; //nullable -string(64) to store login, defaults to system
    updatedDate?: Date; //nullable - defaults to today
    updatedBy?: string; //nullable -string(64) to store login, defaults to system

    constructor(accountId: number | undefined) {
        this.id = 0;
        this.accountId = accountId ?? 0;
        this.name = "";
        this.code = "";
        this.active = true;
        this.maxUserCount = 0;
        this.postalCode = '';
        this.title = '';
        this.shortDescription = '';
        this.description = '';
        this.userCount = 0;
    }
}

export const LocationSchema = yup.object().shape({
    id: yup.number().min(0),
    accountId: yup.number().min(1).required(),
    name: yup.string().min(2).max(128).required()
    .test('name-no-special-chars', 'Name cannot contain special characters or numbers', (value) => {
      return /^[A-Za-z0-9@_.\- ]+$/.test(value || '');
      }),
    code: yup.string().min(0).max(16).nullable(),
    title: yup.string().min(0).max(128),
    description: yup.string().min(0).max(1024),
    shortDescription: yup.string().min(0).max(255),
    active: yup.boolean(),
    maxUserCount: yup.number().min(0),
    postalCode: yup.string().min(0).max(15),
  });

export interface LocationCourseDisplayOrderDto
{
    locationId: number;
    courseId: number;
    displayOrder: number;
}