import { CourseModuleViewDto } from "../course/learning_module";

export interface MediaTrackingEvents {
    transcriptViewedDate?: Date;
    v1TotalViewTime?: number;
    v1SeekedToEnd?: boolean;
    v1LastSeekPos?: number;
    v1CompletedDate?: Date;
    v2TotalViewTime?: number;
    v2SeekedToEnd?: boolean;
    v2LastSeekPos?: number;
    v2CompletedDate?: Date;
}

export interface LessonUserActionEvent {
    accountId: number;
    locationId: number;
    userId: number;
    courseId: number;
    moduleId: string;
    mediaTrackingEvents: MediaTrackingEvents;
}

export interface UserQuizAns
{
    questionId: number;
    ans: number;
}
export interface UserQuizResponse{
    accountId: number;
    locationId: number;
    courseId: number;
    moduleId: number;
    moduleCode: string;
    userId: number;
    quizAnswers: UserQuizAns[]
}

export interface ModuleTaskAssessment
{
    moduleCode: string;
    moduleName: string;
    timelineGroup: string;
    taskName: string;
    difficultyLevel: string;
    order: number;
    timelineOrder: number;
    question: string;
    hint: string;
    ans: boolean;
    latestAns: boolean;
}

export interface ModuleTaskItem
{
    taskName: string;
    group: string;
    type: string,
    order: number;
    row: number;
    column: number;
    question: string;
    questionType: number;
    options: string[],
    hasHtml: boolean,
    ans: string;
    selectedOptions: string[];
}

export interface ModuleCheckListItem
{
    taskName: string;
    level: number;
    order: number;
    question: string;
    warning: string;
    hasHtml: boolean;
}

export interface ModuleTask
{
    assessment: ModuleTaskAssessment,
    checkList: ModuleCheckListItem[];
    tasks: ModuleTaskItem[];
}

export interface UserCourseModuleTaskOutputDto  {
    module: CourseModuleViewDto,
    moduleTasks: ModuleTask[]
}

export enum QuestionType{
    SingleLineText = 1,
    MultiLineText = 2,
    Check = 3,
    CheckList = 4,
    RadioList = 5,
    NumberText = 6
 }

 export interface ModuleTaskAssessmentOutput
 {
     moduleCode?: string;
     taskName?: string;
     order?: number;
     ans?: boolean;
 }

 export interface ModuleTaskItemOutput
 {
     taskName?: string;
     group?: string;
     type?: string,
     order?: number;
     row?: number;
     column?: number;
     ans?: string | number | boolean | undefined;
     selectedOptions?: number[] | undefined;
 }

 export interface ModuleTaskOutput
 {
     assessment: ModuleTaskAssessmentOutput,
     tasks: ModuleTaskItemOutput[];
 }