import React, { useState, useEffect, useRef } from 'react';
import { Message } from 'semantic-ui-react';

export const WarningMessageBlock = () => {
  const [visible, setVisible] = useState(false);
  const initialShowTimeoutRef = useRef<any>(null);
  const dismissTimeoutRef = useRef<any>(null);
  const showIntervalInMin = 10 * 60 * 1000;

  useEffect(() => {
    initialShowTimeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, showIntervalInMin);

    return () => {
      clearTimeout(initialShowTimeoutRef.current);
      clearTimeout(dismissTimeoutRef.current);
    };
  }, [showIntervalInMin]);

  const handleDismiss = () => {
    setVisible(false);
    clearTimeout(initialShowTimeoutRef.current);
    clearTimeout(dismissTimeoutRef.current);

    dismissTimeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, showIntervalInMin);
  };

  return visible ? (
    <Message
      warning
      onDismiss={handleDismiss}
      header='Is That You?'
      content="Check username in the upper right corner (or in the profile). If another user forgot to log out, please log back in with your credentials."
    />
  ) : null;
};
