import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Dropdown } from "semantic-ui-react";
import ConfirmButton from "../../app/components/confirm_button";
import { DocumentTitle } from "../../app/components/document_title";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { Course, CourseListOutputDto, CourseType } from "../../models/course/course";
import { api } from "../../shared/axios_wrapper";
import { getEnumText } from "../../shared/utils";
import { AccountCourseDisplayOrderDto } from "../../models/account/account";

function AccountCourseListPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  //const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CourseListOutputDto[]>([] as CourseListOutputDto[]);
  //const [totalRecords, setTotalRecords] = useState(0);
  const [newRecords, setNewRecords] = useState(0);
  const [courses, setCourses] = useState<Course[]>();
  const [courseValues, setCourseValues] = useState<any[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (user) {
      const url = `account/${user?.accountId}/account-course-list`;
      api.get<Result<CourseListOutputDto[]>>(url)
        .then((r) => {
          setData(r.data.result);
          //setTotalRecords(r.data.result.length);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [newRecords, user]);

  useEffect(() => {
    if (user) {
      api.get<Result<Course[]>>('course/drop-down-list')
        .then((r) => {
          setCourses(r.data.result);
          const transformedList = (r.data.result.map(item => ({
            key: item.id,
            value: item.id,
            text: item.name
          })));
          setCourseValues(transformedList);
        })
        .catch((e) => { console.log(e); toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [user]);

  const addAccountCourse = async (courseIds: number[] | undefined) => {
    if (!courseIds) return;
    for (const courseId of courseIds) {
      const selectedCourse = courses?.filter(x => x.id === courseId)[0];
      if (!selectedCourse) continue;
      setSubmitting(true);
      const url = `account/${user?.accountId}/account-course-insert`
      api.post(url, { courseId: selectedCourse.id, })
        .then((r) => {
          if (data) {
            setNewRecords(newRecords + 1);
          }
          toast.success("Course added successfully.", { theme: "colored" });
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => { setSubmitting(false); setSelectedCourses([]); });
    }
  }

  const removeAccountCourse = async (courseId: number) => {
    const selectedCourse = data?.filter(x => x.id === courseId)[0];
    if (!selectedCourse) return;
    setSubmitting(true);
    const url = `account/${user?.accountId}/account-course-delete/${courseId}`
    api.del(url)
      .then((r) => {
        if (data) {
          setNewRecords(newRecords + 1);
        }
        toast.success("Course removed successfully.", { theme: "colored" });
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setSubmitting(false));
  }

  const changeDisplayOrder = async () => {
    const displayOrderUpdates: AccountCourseDisplayOrderDto[] = [];
    const displayOrderMap: { [courseId: string]: number } = {};

    for (const course of data) {
      const courseId = course.id;
      const displayOrder = course.displayOrder;
      if (displayOrder !== undefined && displayOrder !== null) {
        if (
          displayOrder <= 0 ||
          displayOrder > data.length ||
          displayOrderMap[courseId] !== undefined
        ) {
          toast.error("Invalid display order values.", { theme: "colored" });
          return;
        }

        displayOrderMap[courseId.toString()] = displayOrder;

        const displayOrderUpdate: AccountCourseDisplayOrderDto = {
          accountId: user?.accountId ?? 0,
          courseId: courseId,
          displayOrder: displayOrder,
        };

        displayOrderUpdates.push(displayOrderUpdate);
      }
    }

    if (displayOrderUpdates.length > 0) {
      setSubmitting(true);
      const url = 'account/change-course-display-order';

      try {
        await api.post(url, displayOrderUpdates);
        toast.success("Course display order changed successfully.", { theme: "colored" });
        window.location.reload();
      } catch (e) {
        toast.error("Error", { theme: "colored" });
      } finally {
        setSubmitting(false);
      }
    }
  }

    const handleDisplayOrderChange = (e: any, courseId: number) => {
      const newDisplayOrder = parseInt(e.target.value, 10) || 0;
      const newData = data.map((course) => ({
        ...course,
        displayOrder: course.id === courseId ? newDisplayOrder : course.displayOrder,
      }));

      setData(newData);
    };

  return (<>
    <DocumentTitle title="Account Courses" />
    <h2>Account Courses {data?.length > 0 ? `(${data?.length})` : ''}</h2>
    <div className="flex-container">
      {!loading && <Card.Group size="large">
        {data?.map((c, index) => (
          <Card key={"ac_" + c.id} className="flex-item">
            <Card.Content>
              <Card.Header><a href={"/admin/courses/" + c.id}>{c.courseName}</a></Card.Header>
              <Card.Meta>{getEnumText(CourseType, c.courseType)} | Modules: {c.moduleCount}</Card.Meta>
              <Card.Description>
                {c.shortDescription}
              </Card.Description>
            </Card.Content>
            <Card.Content extra textAlign="right">
              <input type="number" maxLength={2}
                style={{ width: "70px", fontSize: "24px", marginRight: "4px" }}
                value={data.find((course) => course.id === c.id)?.displayOrder || ''}
                onChange={(e) => handleDisplayOrderChange(e, c.id)}
              />
              <Button basic primary icon='video' content='View' onClick={(e, data) => { navigate('/lessons/courses/' + c.id) }} />
              <ConfirmButton id={c.id} value={"Delete"} confirmButtonText={"Delete"} color={"grey"}
                content={"Remove " + c.courseName + "?"} iconName="delete" floated="right"
                handleConfirm={removeAccountCourse} />
            </Card.Content>
          </Card>
        ))}
      </Card.Group>}
      <br />
      {!loading && data?.length > 0 && <Button
        loading={submitting}
        primary
        icon='ordered list'
        type="submit"
        size="large"
        disabled={submitting}
        content="Set Course Display Orders"
        onClick={(e) => changeDisplayOrder()}
      />}
    </div>

    <h4>Add New Course to Account</h4>
    {!loading && <Dropdown
      placeholder='Course'
      fluid
      multiple
      search
      selection
      options={courseValues.filter(item1 => !data?.some(item2 => item2.id === item1.key))}
      value={selectedCourses}
      onChange={(e, data) => { setSelectedCourses(data.value as number[]); }}
    />}<br />
    {!loading && <Button primary icon="add" size="large" content="Add"
      floated="right"
      disabled={submitting}
      onClick={() => { addAccountCourse(selectedCourses); }} />}
    <br /><br /><br />
  </>);

}
export default AccountCourseListPage;