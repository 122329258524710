import { Formik } from "formik";
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import { DocumentTitle } from '../../app/components/document_title';
import TextInput from "../../app/components/text_input";
import { api } from '../../shared/axios_wrapper';
import { useAuth } from "../../app/context/auth_provider";
import { UserRole } from "../../models/user/user";

function ResetPasswordPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { initialLogin, initialLastName, initialPin } = useParams();
  const [showPin, setShowPin] = useState<number>(-1);

  const ResetPasswordSchema = Yup.object().shape({
    login: Yup.string().min(6).max(64).required('Login is required'),
    lastName: Yup.string().min(2).max(64).required('Last Name is required'),
    newPwd: Yup.string()
      .min(4, 'Password must be at least 4 characters')
      .required('New Password is required'),
    retypePwd: Yup.string()
      .oneOf([Yup.ref('newPwd'), undefined], 'Passwords must match')
      .required('Retype Password is required'),
    pin: Yup.string().test('conditional-validation', 'PIN is required', function (value) {
      if (showPin === 1) {
        return value ? true : this.createError({ message: 'PIN is required' });
      }
      return true;
    })
  });

  const fetchShowPin = async (input: any) => {
    if (input.login?.trim() === '' || input.lastName?.trim() === '') {
      return;
    }
    try {
      //setIsSubmitting(true);
      const response = await api.get<boolean>(`/user/get-account-info/${input.login}/${input.lastName}`);
      //console.log(response);
      setShowPin(response.data === true ? 1 : 0);
      return response.data === true ? 1 : 0;
    } catch (error) {
      toast.error("Error fetching account info", { theme: "colored" });
      return -1;
    } finally {
      //setIsSubmitting(false);
    }
  }

  const handleResetPwd = async (input: any) => {
    //console.log('show pin: ' + showPin);

    var needPin = await fetchShowPin(input);

    //console.log('pin: ' + showPin);

    if (needPin === 1 && input.pin?.trim() === "") {
      toast.error("Please enter PIN to continue", { theme: "colored" });
      return;
    }

    if (needPin === -1) {
      toast.error("Unable to get account info, please correct errors and retry", { theme: "colored" });
      return;
    }
    setIsSubmitting(true);
    const apiInput = {
      login: input.login,
      lastName: input.lastName,
      newPwd: input.newPwd,
      pin: input.pin
    };

    api.post<number>('/user/reset-pwd', apiInput)
      .then((r) => {
        //console.log(r.data);
        if (r.data > 0) {
          toast.success("Password reset successfully.", { theme: "colored" });
          if (user && user.role > UserRole.Student) {
            navigate('/location/users');
          }
          else {
            navigate("/login");
          }
        }
        else {
          toast.error("Password Reset failed. Please correct any errors and try again", { theme: "colored" });
        }
      })
      .catch((e) => { toast.error("Error", { theme: "colored" }) })
      .finally(() => setIsSubmitting(false));
  }

  return (
    <>
      <DocumentTitle title="Reset Password" />
      <center>
        <br /><br />
        <img src='/assets/logo_orange.png' alt="Logo" className="auth-logo" />
        <Header as="h2" color="black" textAlign="center">
          Password Reset
        </Header>
        <div>Back to login: <a href="/login">Click Here</a></div>
        <br />
        <Formik
          initialValues={{
            login: initialLogin || '',
            lastName: initialLastName || '',
            newPwd: '',
            retypePwd: '',
            pin: initialPin || ''
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleResetPwd}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ dirty, isValid, handleSubmit, values, errors }) => (
            <>
              <Form onSubmit={handleSubmit}
                autoComplete='Off'
                className="ui form"
                style={{ textAlign: "left", maxWidth: "600px", padding: "20px" }}
              >
                <Grid container stackable columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <TextInput
                        name="login"
                        placeholder='Login'
                        icon='at'
                        iconPosition='left'
                        maxLength={64}
                        size='huge'
                        autoComplete='off'
                        value={values.login}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <TextInput
                        name="lastName"
                        placeholder='Last Name'
                        icon='user'
                        iconPosition='left'
                        maxLength={50}
                        size='huge'
                        autoComplete='off'
                        value={values.lastName}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TextInput type='password' name="newPwd"
                        placeholder='New Password'
                        icon='lock' iconPosition='left' maxLength={15}
                        size='huge' autoComplete='off'
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <TextInput type='password' name="retypePwd"
                        placeholder='Retype New Password'
                        icon='lock' iconPosition='left' maxLength={15}
                        size='huge' autoComplete='off'
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {showPin === 1 &&
                    <>
                    <Grid.Row centered columns={2}>
                      <Grid.Column>
                        <TextInput name="pin"
                          placeholder='Pin (4-digit number)'
                          icon='slack' iconPosition='left' maxLength={4}
                          size='huge' autoComplete='off'
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} centered>
                      <Grid.Column stretched textAlign="center">
                      your account has a PIN setup during the registration, you will need to enter PIN to reset the password
                      </Grid.Column>
                    </Grid.Row>
                    </>
                  }
                  <Grid.Row columns={2} centered>
                    <Grid.Column>
                      <Button type="submit" primary size='huge'>
                        Reset Password
                        {isSubmitting && <label> <Icon name='spinner' /></label>}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1} centered>
                    <Grid.Column textAlign="center" stretched>
                    Note: Only students can reset their own password. Administrators should contact
                        higher-level admin about admin password resets.
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
        <br /><br />
      </center>
    </>
  );
};

export default ResetPasswordPage;
