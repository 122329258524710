import React, { SyntheticEvent } from 'react'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { PageParams } from '../../models/common/paged_result';

interface Props {
    params: PageParams;
    pageSizeChange?: (change: PageParams) => void;
}
function RecordsPerPage({ params, pageSizeChange }: Props) {
    const handleRecordsPerPage = (event: SyntheticEvent, data: any) => {
        const size = { ...params};
        size.pageIndex = 0;
        size.pageSize = data?.value ?? 0;
        if (pageSizeChange)
            pageSizeChange(size);
    }

    const limitOptions: DropdownItemProps[] = [
        { key: "0", value: "25", text: "25" },
        { key: "1", value: "50", text: "50" },
        { key: "2", value: "100", text: "100" },
        { key: "3", value: "250", text: "250" },
    ];
    return (
        <>
            Records per page:&nbsp;&nbsp;
            <Dropdown
                style={{width:"50px", textAlign: "center"}}
                inline={true}
                options={limitOptions}
                onChange={handleRecordsPerPage}
                value={params.pageSize > 0 ? params.pageSize.toString() : parseInt(process.env.REACT_APP_API_PAGE_SIZE ?? "15")}
            />
        </>
    )
}

export default RecordsPerPage