import React from 'react';

interface ProgressBarProps {
  bgColor: string;
  completed: number;
}

function ProgressBar({bgColor, completed}:ProgressBarProps)  {

  const containerStyles: React.CSSProperties = {
    height: 20,
    width: '100%',
    backgroundColor: "#e0e0de",
    margin: "3px 0px 3px 0px"
  }

  const fillerStyles: React.CSSProperties = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgColor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const labelStyles: React.CSSProperties = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${Math.floor(completed)}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
