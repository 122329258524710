import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../../shared/axios_wrapper';
import { toast } from 'react-toastify';
import TextInput from '../../app/components/text_input';
import SelectInput from '../../app/components/selected_input';
import { DocumentTitle } from '../../app/components/document_title';
import { CampaignUser } from '../../models/campaign/campaign';
import { Result } from '../../models/common/paged_result';
import LabelOutput from '../../app/components/label_output';

// Validation schema using Yup
const CampaignUserSchema = Yup.object().shape({
  firstName: Yup.string().min(2).max(50).required('First Name is required'),
  lastName: Yup.string().min(2).max(50).required('Last Name is required'),
  userPhone: Yup.string().min(12).max(12).required('Phone is required'),
  active: Yup.boolean().required('Active status is required'),
  mmsCapable: Yup.boolean().nullable()
});


const CampaignUserEdit = () => {
  const { campaignId, campaignUserId } = useParams<{ campaignId: string, campaignUserId: string }>();
  const campaignIdInt = parseInt(campaignId ?? '0');
  const campaignUserIdInt = parseInt(campaignUserId ?? '0');
  const navigate = useNavigate();
  const [campaignUser, setCampaignUser] = useState<CampaignUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Default values for a new user
const defaultCampaignUser: CampaignUser = {
  id: 0,
  campaignId: campaignIdInt,
  firstName: '',
  lastName: '',
  userPhone: '+1',
  mmsCapable: true,
  active: true,
};

const defaultCampaignUserRef = useRef(defaultCampaignUser);

  // Fetch campaign user data if editing an existing user
  useEffect(() => {
    if (campaignUserIdInt > 0) {
      setLoading(true);
      api.get<Result<CampaignUser>>(`/campaign/${campaignIdInt}/user/get/${campaignUserIdInt}`)
        .then(response => {
          setCampaignUser(response?.data?.result || defaultCampaignUserRef.current);
        })
        .catch(() => toast.error('Failed to load campaign user'))
        .finally(() => setLoading(false));
    } else {
      setCampaignUser(defaultCampaignUserRef.current);
    }
  }, [campaignIdInt, campaignUserIdInt]);

  // Handle form submission
  const handleSubmit = async (values: CampaignUser) => {
    setSubmitting(true);
    const url = '/campaign-user/save';
    api.post<CampaignUser>(url, values)
      .then(() => navigate(`/admin/campaign-users/${values.campaignId}`))
      .catch(() => toast.error('Failed to save campaign user'))
      .finally(() => setSubmitting(false));
  };

  const CampaignUserForm = () => (
    <Formik
      initialValues={campaignUser || defaultCampaignUser}
      validationSchema={CampaignUserSchema}
      enableReinitialize
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleSubmit, isValid, dirty }) => (
        <Form id="campaignUserForm" className="ui form" onSubmit={handleSubmit} size="big">
          <Form.Group widths="equal">
            <TextInput name="firstName" placeholder="First Name (Max. 50 characters)" maxLength={50} />
            <TextInput name="lastName" placeholder="Last Name (Max. 50 characters)" maxLength={50} />
          </Form.Group>

          <Form.Group widths="equal">
          <TextInput name="userPhone" placeholder="User Phone (12 characters)" maxLength={12} />
            <SelectInput
              name="active"
              placeholder="Active?"
              options={[
                { key: 'yes', text: 'Yes', value: true },
                { key: 'no', text: 'No', value: false },
              ]}
            />
            <SelectInput
              name="mmsCapable"
              placeholder="MMS Capable?"
              options={[
                { key: 'yes', text: 'Yes', value: true },
                { key: 'no', text: 'No', value: false },
              ]}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <LabelOutput content={campaignUser?.optInDate} placeholder="Opt-In Date (YYYY-MM-DD)" />
            <LabelOutput content={campaignUser?.optOutDate} placeholder="Opt-Out Date (YYYY-MM-DD)"  />
            <LabelOutput content={campaignUser?.completedDate} placeholder="Completed Date (YYYY-MM-DD)" />
          </Form.Group>

          <Button type="submit" primary disabled={submitting}>
            Save
          </Button>
          <Button onClick={() => navigate(`/admin/campaign-users/${campaignUser?.campaignId}`)}>
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <DocumentTitle title={campaignUserIdInt === 0 ? "Add Campaign User" : "Edit Campaign User"} />
      <div><a href={`/admin/campaign-users/${campaignIdInt}`}>&lt;&lt; Campaign Users</a></div>
      <br/>
      <div>
        <h1>{campaignUserIdInt === 0 ? 'Add Campaign User' : 'Edit Campaign User'}</h1>
        {!loading && <CampaignUserForm />}
      </div>
    </>
  );
};

export default CampaignUserEdit;
