import { Grid } from 'semantic-ui-react';
import { useAuth } from '../../context/auth_provider';
import './navbar.css';
import TopMenu from './top_menu';

interface Props {
  sidebarOn: boolean,
  showSidebar: () => void,
  logoutHandler: () => void,
}

function NavBar({ showSidebar, logoutHandler }: Props) {
  const { user } = useAuth();
  return (
    <>
      <Grid columns='equal' className='acc-loc-bar'>
        <Grid.Column textAlign='left' verticalAlign='middle' className='acc-loc-item'>
        Acceleron Learning
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' className='acc-loc-item'>
          {user?.locationName}
        </Grid.Column>
      </Grid>
      <TopMenu logoutHandler={logoutHandler} showSidebar={showSidebar} />
    </>
  )
}

export default NavBar