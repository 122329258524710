import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Form, Icon, List } from "semantic-ui-react";
import LabelOutput from "../../app/components/label_output";
import { Result } from "../../models/common/paged_result";
import { Course, CourseModuleDto, CourseViewDto } from "../../models/course/course";
import { api } from "../../shared/axios_wrapper";
import { DocumentTitle } from "../../app/components/document_title";

function CourseViewPage() {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState<Course>(new Course());
    const [existingModules, setExistingModules] = useState<CourseModuleDto[]>();
    const courseIdInt = parseInt(courseId ?? '0');

    useEffect(() => {
      if (courseIdInt > 0){
        setLoading(true);
        const url = `course/get/${courseIdInt}`;
        api.get<Result<CourseViewDto>>(url)
          .then((r) => {
            if (r?.data?.result?.course){
              const course = new Course(r.data.result.course);
              setCourse(course);
            }
            if (r?.data?.result?.modules) {
              setExistingModules(r.data.result.modules);
            }
          })
          .catch((e) => {toast.error("Error", { theme: "colored" }) })
          .finally(() => setLoading(false));
      }
    },[courseId, courseIdInt]);

    const CourseView = () => {
        return(
          <Form id="courseForm" className="ui form" size="big">
              <Form.Group widths="equal">
              <Form.Checkbox  label="Active?" style={{fontSize:"18px"}} checked={course.active}
             disabled/>
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="id" content={course.id} />
              <LabelOutput placeholder="Course Name" content={course.name} />
              <LabelOutput placeholder="Course Type" content={course.courseType} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Short Desc"content={course.shortDescription} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Desc" content={course.description} />
            </Form.Group>
            <Form.Group widths="equal">
              <LabelOutput placeholder="Promo JSON" content={course.promoJson} />
              <LabelOutput placeholder="ICON" content={course.courseIcon} />
            </Form.Group>
            <Button floated="right" type="button" content="Close" size="large"
            onClick={() => {navigate('/admin/courses')}}/>
          </Form>
          )}


    const AssociateModules = () => {
        return(
          <>
          <br/><br/>
          <Divider horizontal>Associated Learning Modules</Divider>

          <h4>Currently Associated Modules ({existingModules?.length})</h4>
          <List selection relaxed>
          {existingModules?.map((m, index) => (
            <List.Item key={"module_" + index} style={{color: "black"}}>
              <List.Content>

                {index + 1}. &nbsp; &nbsp;<a href={`/admin/modules/${m.moduleCode}`}
                style={{color: m.active === false ? "red" : ""}}
                >{m.moduleName} - {m.moduleCode} - {m.active.toString()}

                </a>
               </List.Content>
              <br />
            </List.Item>
          ))}
        </List>

        <br/><br/><br/>
        </>
        );
    };

    return(<>
      <DocumentTitle title={"Course View"} />
      <Button basic icon="arrow left" size="big" content="Back to Courses" onClick={() => {navigate('/admin/courses')}}/>
      {!loading && module && <h2><Icon name='bookmark outline'/> Course </h2>}
      {!loading && <CourseView/>}
      {!loading && course.id > 0  && <AssociateModules />}

    </>);
}

export default CourseViewPage