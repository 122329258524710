import * as yup from 'yup';
import { PageParams } from '../common/paged_result';
import { LearningModule } from './learning_module';
import { ModuleTaskAssessment } from '../user/user_lesson_action';

export enum CourseType {
  e_Learning = 1,
  ReEntry_Roadmap = 2,
  Release_Roadmap = 3,
  ReFocus_Roadmap = 4
}


//to store all the courses targeting for account locations.
//Each course will have one or more modules. Note that modules are shared among courses.
export class Course {
  id: number; //primary key - auto-increment
  name: string; //unique - not null - string(128)
  code: string;   //unique - not null - string(24)
  courseType: number; //not null - 1 = e-learning  2 = re-entry roadmap
  description?: string; //nullable - string(1024)
  shortDescription?: string; //nullable - string(255)
  active: boolean; //not null - boolean - default - true
  displayOrder?: number; //nullable
  promoJson?: string; //nullable - JSON object
  certificateAvgPassPercentage?: number; //nullable
  courseIcon?: string; //nullable - string(64)
  createdDate?: Date; //nullable - defaults to today
  createdBy?: string; //nullable -string(64) to store login, defaults to system
  updatedDate?: Date; //nullable - defaults to today
  updatedBy?: string; //nullable -string(64) to store login, defaults to system

  constructor(course?: Course) {
    this.id = course?.id ?? 0;
    this.name = course?.name ?? "";
    this.code = course?.code ?? "";
    this.courseType = course?.courseType ?? CourseType.e_Learning;
    this.active = course?.active ?? true;
    this.shortDescription = course?.shortDescription ?? "";
    this.description = course?.description ?? "";
    this.promoJson = course?.promoJson ?? "";
    this.courseIcon = course?.courseIcon ?? "";
  }
}

export const CourseSchema = yup.object().shape({
  id: yup.number().min(0),
  name: yup.string().min(2).max(128).required()
  .test('name-no-special-chars', 'Name cannot contain special characters or numbers, except for hyphens', (value) => {
    return /^[A-Za-z\s-]+$/.test(value || '');
  }),
  //code: yup.string().min(2).max(24).required(),
  courseType: yup.number().min(1).required(),
  description: yup.string().min(0).max(1024),
  shortDescription: yup.string().min(0).max(255),
  //displayOrder: yup.number().min(0),
  //courseIcon: yup.string().min(0).max(64),
  active: yup.boolean(),
  promoJson: yup.string().max(1024),
  courseIcon: yup.string().max(25)
});

export interface CourseListInputDto extends PageParams {
  accountId?: number;
  locationId?: number;
  courseType?: CourseType,
  name?: string;
  active?: boolean;
}

export const CourseListInputDtoSchema = {
  accountId: yup.number().positive(),
  locationId: yup.number().positive(),
  name: yup.string().max(50)
};

export interface CourseListOutputDto {
  accountId: number;
  accountName: string;
  locationId: number;
  locationName: string;
  id: number;
  courseName: string;
  courseType: CourseType;
  shortDescription: string;
  active: boolean;
  displayOrder: number;
  moduleCount: number;
  includeFlag: boolean;
  excludeFlag: boolean;
  locationCourse: number;
}

export interface CourseModuleDto {
  moduleId: number;
  moduleName: string;
  moduleCode: string;
  displayOrder: number;
  active: boolean;
  action: string;
}
export interface CourseViewDto {
  course: Course,
  modules: CourseModuleDto[]
}

export enum UserModuleStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2
}

export interface UserCourseOutputDto {
  courseId: number;
  courseType: CourseType;
  courseName: string;
  shortDescription: string;
  description: string;
  promoJson?: string;
  moduleCount: number;
  quizModuleCount: number;
  courseIcon: string;
  modulesCompleted: number;
  courseCompleted: number;
  courseCompletedDate: Date;
  lastModuleAccessedDate: Date;
  lastQuizAttemptedDate: Date;
  lastAccessedDate: Date;
  displayOrder: number;
  modules: ModuleDto[];
}

export interface ModuleDto {
  module: LearningModule;
  lastAccessedDate: Date;
  lastQuizAttemptedDate: Date,
  lastQuizMarks: number;
  lastQuizPercentage: number;
  completedDate: Date;
  maxQuizMarks: number;
  maxQuizPercentage: number;
  status: UserModuleStatus;
  completedFlag: boolean;
  taskCount: number;
}

export interface CourseModuleDisplayOrderDto
{
    courseId: number;
    moduleId: number;
    displayOrder: number;
}

export interface UserCourseTimelineOutputDto  extends UserCourseOutputDto {
  assessmentTimeline: ModuleTaskAssessment[]
}