import { Link } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

function UnAuthorized() {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='dont' />
        Sorry, you are not allowed to access this resource.
      </Header>
      <Segment.Inline>
        <Button as={Link} to='/' primary>
          Back Home
        </Button>
      </Segment.Inline>
    </Segment>
  )
}

export default UnAuthorized