import { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useAuth } from "../context/auth_provider";

const SessionTImeoutModal = () => {
    //const idleTimeoutInSec = parseInt(process.env.REACT_APP_SESSION_TIME_OUT_IN_MIN ?? '30') * 60;
    //const autoLogoutTimeoutInSec = 3 * 60;
    const [openModal, setOpenModal] = useState(false);
    const [autoLogoutTimer, setAutoLogoutTimer] = useState<NodeJS.Timeout | null>(null); // Specify the type here
    const { logout } = useAuth();

    /*
    const handleIdle = () => {
        setOpenModal(true);

        // Set up the auto logout timer
        const timer = setTimeout(() => {
            handleLogout();
        }, autoLogoutTimeoutInSec * 1000); // Convert to milliseconds
        setAutoLogoutTimer(timer);
    };
    */
    //const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTimeInSec: idleTimeoutInSec });

    const stay = () => {
        setOpenModal(false);
        if (autoLogoutTimer) {
            clearTimeout(autoLogoutTimer);
        }
        setAutoLogoutTimer(null); // Clear the autoLogoutTimer state
    };

    const handleLogout = () => {
        logout();
        setOpenModal(false);
    };

    return (
        <Modal open={openModal}
            size="mini"
            onClose={() => stay()} closeIcon>
            <Modal.Header>
                <Modal.Header>Your session is about to expire</Modal.Header>
            </Modal.Header>
            <Modal.Content>
                <p>
                    Your session is about to expire.
                    <br/>
                    You'll be automatically signed out.
                </p>
                <p>
                    Do you want to stay signed in?
                </p>

            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={handleLogout}>
                    Sign out now
                </Button>
                <Button primary onClick={stay}>
                    Stay signed in
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SessionTImeoutModal;
